import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavLink from "../routes/NavLink";
import { SubmitPost } from "../../../../../Store/Action/index";
import { TEMPLATE_UPLOAD } from "../../../../../Store/Action/Type";

function ImportTemplate() {
  const dispatch = useDispatch();

  const FetchApi = useCallback(async () => {
    dispatch(SubmitPost(TEMPLATE_UPLOAD));
  }, [dispatch]);

  useEffect(() => {
    FetchApi();
  }, []);

  const [file, setFile] = useState({});

  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const submitHandler = (e) => {
    const fd = new FormData();

    fd.append("file", e.target.files[0]);
    dispatch(SubmitPost(TEMPLATE_UPLOAD, fd));
    toast.success("HTML File Successfully Uploaded");
    return false;
  };

  return (
    <div>
      <div className="container">
        <div className="container-wrap mt-4">
          <div className="row justify-content-center align-items-stretch">
            <div className="col-md-8">
              <div className="step_forms-wrap py-5 h-100">
                <h3 className="title_1 text-center mb-4">Import Template</h3>
                <form
                  className=""
                  action=""
                  method=""
                  onChange={(e) => {
                    submitHandler(e);
                  }}
                >
                  <div className="form-group">
                    <div className="file-upload darkBordr">
                      <div className="image-upload-wrap text-center">
                        <input
                          className="file-upload"
                          type="file"
                          accept=".HTML"
                        />
                        <div className="drag-text">
                          <h3>
                            <span>Supported formats: HTML File</span>
                          </h3>
                          <img src={file.file} alt="checkfile" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <NavLink to={"/"}>
                  <button
                    type="button"
                    className="btn-primary me-2 mt-4 text-white"
                  >
                    Back{" "}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default ImportTemplate;
