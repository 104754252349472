import React, { useEffect, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { Country } from "country-state-city";
import { City } from "country-state-city";
import { State } from "country-state-city";
import axios from "axios";
import { useSelector } from "react-redux";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { makeSearchString } from "../../../../ApiWrapper";
import Select from "react-select";
const apiUrl = process.env.REACT_APP_GOOGLE_MAP_DATA_URL;

function FilterSearch({
  onApplyFilters,
  onHide,
  center,
  setCenter,
  setHideMap,
  setShow,
  createReq = false,
  handleCreateReq = () => {},
  handlecheckzip = () => {},
  setZipcodedata,
  zipcodedata,
  citydata,
  SetCityData,
}) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [zipCodes, setZipCodes] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [categoryCheckboxes, setCategoryCheckboxes] = useState({});
  const [checkedZipCodes, setCheckedZipCodes] = useState({});
  const [polygonCoordinatesdata, setpolygonCoordinatesdata] = useState();
  const [newcenter, Setnewcenter] = useState({ lat: 0, lng: 0 });
  const [message, Setmessage] = useState();
  const [selectedCities, setSelectedCities] = useState([]);
  const [activeKey, setActiveKey] = useState(null);

  console.log(categoryCheckboxes, "categoryCheckboxes");

  const SelectedDataforPolygon = async () => {
    const baseURL = `${process.env.REACT_APP_GOOGLE_MAP_DATA_URL}`;
    const dataQueryParam = {
      state: selectedState,
      // city: selectedCities,
      city: citydata,
      // zipcode: Object.keys(checkedZipCodes || {}).join(","),
      zipcode: Object.keys(zipcodedata || {}).join(","),
      pageNo: "1",
    };
    let url = `listMapData`;
    const searchString = makeSearchString(dataQueryParam);
    // console.log(searchString, "searchString", dataQueryParam);
    url = searchString ? `${url}?${searchString}` : url;
    const fullURL = `${baseURL}/${url}`;

    try {
      const response = await fetch(fullURL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to send data");
      }
      const responseData = await response.json();
      console.log(responseData, "responseDatabelwal");
      setpolygonCoordinatesdata(responseData?.response?.polygonCoordinates[0]);
    } catch (error) {
      console.error("There was an error:", error);
    }
  };

  useEffect(() => {
    SelectedDataforPolygon();
  }, [selectedState, citydata, zipcodedata]);

  const handleZipCodeChange = (zipCode) => {
    // SelectedDataforPolygon(zipCode);
    setCheckedZipCodes((prev) => {
      const newCheckedZipCodes = { ...prev, [zipCode]: !prev[zipCode] };
      // console.log("Checked Zip Codes:", newCheckedZipCodes);
      return newCheckedZipCodes;
    });
    setZipcodedata((prev) => {
      const currentData = prev || {};

      const newCheckedZipCodes = {
        ...currentData,
        [zipCode]: !currentData[zipCode],
      };
      // console.log("Checked Zip Codes:", newCheckedZipCodes);
      return newCheckedZipCodes;
    });
  };

  useEffect(() => {
    const countryData = Country.getAllCountries();
    setCountries(countryData);
  }, []);

  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    setSelectedCountry(countryCode);
    setSelectedCity("");
    setCities([]);
    setZipCodes([]);
    const stateData = State.getStatesOfCountry(countryCode);
    setStates(stateData);
  };

  const handleStateChange = (event) => {
    const stateCode = event.target.value;
    setSelectedState(stateCode);
    // SelectedDataforPolygon(stateCode);
    // setSelectedCity("");
    setZipCodes([]);
    const cityData = City.getCitiesOfState(selectedCountry, stateCode);
    setCities(cityData);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/getCategoryData`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (data && data.response) {
          console.log(data.response, "aaaaaalalalal");
          setCategoriesData(data.response);
        } else {
          console.error("Unexpected response format");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const newCategoryCheckboxes = {};
    categoriesData.forEach((category) => {
      newCategoryCheckboxes[category._id] = {
        checked: false,
        subCategories: category.SubCategories.map(() => false),
      };
    });
    setCategoryCheckboxes(newCategoryCheckboxes);
  }, [categoriesData]);

  const handleCategoryCheckboxChange = (categoryId, category) => {
    console.log(category, "categorydadadda");
    const updatedCategories = { ...categoryCheckboxes };

    const currentCategory = updatedCategories[categoryId];
    const newCheckedStatus = !currentCategory.checked;

    updatedCategories[categoryId] = {
      checked: newCheckedStatus,
      subCategories: currentCategory.subCategories.map(() => newCheckedStatus),
    };

    console.log(updatedCategories, "updatedCategoriesupdatedCategories");

    setCategoryCheckboxes(updatedCategories);
  };

  const handleSubCategoryCheckboxChange = (categoryId, subIndex) => {
    const updatedCategories = { ...categoryCheckboxes };
    const currentCategory = updatedCategories[categoryId];

    currentCategory.subCategories[subIndex] =
      !currentCategory.subCategories[subIndex];

    const areAllSubCategoriesChecked =
      currentCategory.subCategories.every(Boolean);
    currentCategory.checked = areAllSubCategoriesChecked;

    setCategoryCheckboxes(updatedCategories);
  };

  const getCheckedZipCodes = () => {
    // const checkedZips = Object.entries(zipcodedata)
    const checkedZips = Object.entries(checkedZipCodes)
      .filter(([, isChecked]) => isChecked)
      .map(([zipCode]) => zipCode);
    // console.log("Checked Zip Codes:", checkedZips);
    return checkedZips;
  };

  const handleShowListingsClick = () => {
    const appliedFilters = {
      zipCodes: getCheckedZipCodes(),
      categories: getCheckedCategories(),
      subCategories: getCheckedSubCategories(),
    };
    console.log("Applied Filters:", appliedFilters);
    onApplyFilters(
      appliedFilters.categories,
      appliedFilters.zipCodes,
      appliedFilters.subCategories
    );
    setHideMap(false);
    setShow(false);
  };

  const getCheckedCategories = () => {
    const checkedCats = [];
    for (const categoryId in categoryCheckboxes) {
      if (
        categoryCheckboxes[categoryId].checked ||
        categoryCheckboxes[categoryId].subCategories.includes(true)
      ) {
        const categoryName = categoriesData.find(
          (cat) => cat._id === categoryId
        ).BusinessCategory;
        checkedCats.push(categoryName);

        // checkedCats.push({ categoryId: categoryId,
        //   categoryName});
      }
    }
    console.log("Checked Categories by Name:", checkedCats);
    return checkedCats;
  };

  const getCheckedSubCategories = () => {
    const checkedSubCategories = [];
    for (const categoryId in categoryCheckboxes) {
      categoryCheckboxes[categoryId].subCategories.forEach((checked, index) => {
        if (checked) {
          const categoryObj = categoriesData.find(
            (category) => category._id === categoryId
          );
          if (categoryObj) {
            checkedSubCategories.push(categoryObj.SubCategories[index]);
          }
        }
      });
    }
    console.log("Checked SubCategories:", checkedSubCategories);
    return checkedSubCategories;
  };

  // const getcategorysubcategoryrequest = () => {
  //   const checkedSubCategories = [];

  //   for (const categoryId in categoryCheckboxes) {
  //     categoryCheckboxes[categoryId].subCategories.forEach((checked, index) => {
  //       if (checked) {
  //         const categoryObj = categoriesData.find(
  //           (category) => category._id === categoryId
  //         );

  //         if (categoryObj) {
  //           const categoryName = categoryObj.BusinessCategory;
  //           const subCategoryName = categoryObj.SubCategories[index];

  //           const existingCategoryIndex = checkedSubCategories.findIndex(item => item.category === categoryName);

  //           if (existingCategoryIndex !== -1) {
  //             checkedSubCategories[existingCategoryIndex].subCategoryName.push(subCategoryName);
  //           } else {
  //             checkedSubCategories.push({
  //               category: categoryName,
  //               subCategoryName: [subCategoryName]
  //             });
  //           }
  //         }
  //       }
  //     });
  //   }

  //   const output = {
  //     categorysubcategoryrequest: checkedSubCategories
  //   };

  //   // console.log("Output:", output);
  //   return output;
  // };

  const getcategorysubcategoryrequest = () => {
    const checkedSubCategories = [];

    for (const categoryId in categoryCheckboxes) {
      categoryCheckboxes[categoryId].subCategories.forEach((checked, index) => {
        if (checked) {
          const categoryObj = categoriesData.find(
            (category) => category._id === categoryId
          );

          if (categoryObj) {
            const categoryName = categoryObj.BusinessCategory;
            const subCategoryName = categoryObj.SubCategories[index];

            let categoryExists = false;
            for (const category of checkedSubCategories) {
              if (category.category === categoryName) {
                category.subCategoryName.push(subCategoryName);
                categoryExists = true;
                break;
              }
            }

            if (!categoryExists) {
              checkedSubCategories.push({
                category: categoryName,
                subCategoryName: [subCategoryName],
              });
            }
          }
        }
      });
    }

    const output = {
      categorysubcategoryrequest: checkedSubCategories,
    };

    console.log("Output:", output);
    return output;
  };

  const markerData = useSelector(
    (state) => state.apiDataSlice.data?.response?.mapData
  );

  console.log("markerData:", markerData);

  // useEffect(() => {
  //   if (polygonCoordinatesdata?.features[0].geometry.type === "Polygon") {
  //     let longitude =
  //       polygonCoordinatesdata?.features[0].geometry.coordinates?.[0]?.[0]?.[0];
  //     let latitude =
  //       polygonCoordinatesdata?.features[0].geometry.coordinates?.[0]?.[0]?.[1];
  //     Setnewcenter({ lat: latitude, lng: longitude });
  //   } else {
  //     let longitude =
  //       polygonCoordinatesdata?.features[0].geometry
  //         .coordinates?.[0]?.[0]?.[0]?.[0];
  //     let latitude =
  //       polygonCoordinatesdata?.features[0].geometry
  //         .coordinates?.[0]?.[0]?.[0]?.[1];
  //     Setnewcenter({ lat: latitude, lng: longitude });
  //   }
  // }, [polygonCoordinatesdata]);

  // let polygonCoordinates = [];
  // let poly = [];
  // console.log(polygonCoordinates, "polygonCoordinates");

  // if (polygonCoordinatesdata && polygonCoordinatesdata.features) {
  //   polygonCoordinatesdata?.features?.forEach((element) => {
  //     element?.geometry.coordinates?.forEach((ele) => {
  //       ele?.forEach((ele1) => {
  //         if (element?.geometry.type === "Polygon") {
  //           polygonCoordinates.push({
  //             lat: ele1[1],
  //             lng: ele1[0],
  //           });
  //         } else {
  //           ele1?.forEach((ele2) => {
  //             polygonCoordinates.push({
  //               lat: ele2[1],
  //               lng: ele2[0],
  //             });
  //           });
  //         }
  //       });
  //     });
  //     poly.push(polygonCoordinates);
  //     polygonCoordinates = [];
  //   });
  // }

  // useEffect(() => {
  //   const map = new window.google.maps.Map(
  //     document.getElementById("map"),
  //     {
  //       center: {
  //         lat: newcenter?.lat ?? center?.lat,
  //         lng: newcenter?.lng ?? center?.lng,
  //       },

  //       // center: { lat: newcenter?.lat, lng: newcenter?.lng },
  //       zoom: 10,
  //     },
  //     [polygonCoordinatesdata]
  //   );

  //   poly.forEach((polygonCoordinates) => {
  //     const polygon = new window.google.maps.Polygon({
  //       paths: polygonCoordinates,
  //       strokeColor: "blue",
  //       strokeOpacity: 0.8,
  //       strokeWeight: 2,
  //       fillColor: "lightblue",
  //       fillOpacity: 0.35,
  //     });

  //     polygon.setMap(map);
  //   });

  //   const markers = markerData?.map((location) => {
  //     return new window.google.maps.Marker({
  //       position: new window.google.maps.LatLng(
  //         parseFloat(location.latitude),
  //         parseFloat(location.longitude)
  //       ),
  //       label: location.Name,
  //     });
  //   });

  //   new MarkerClusterer(map, markers, {
  //     imagePath:
  //       "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  //   });
  // }, [polygonCoordinates]);

  const handleClickCreateReq = (e) => {
    e.preventDefault();
    let obj = {
      city: selectedCity,
      country: selectedCountry,
      state: selectedState,
      //zipCode: checkedZipCodes,
      zipcode: zipcodedata
        ? Object.keys(zipcodedata).map((zipCode) => zipCode)
        : [],
      message: message,
      categories: getCheckedCategories(),
      subCategories: getCheckedSubCategories(),
      categorysubcategoryrequest: getcategorysubcategoryrequest(),
    };

    handleCreateReq(obj);
  };

  const handleChange = (e) => {
    Setmessage(e.target.value);
  };

  const fetchZipCodes = (ele) => {
    setZipCodes([]);

    let cityName = citydata.length > 0 ? citydata[citydata.length - 1] : null;
    setSelectedCity(cityName);

    if (ele) {
      axios
        .post(`${apiUrl}/extract/zipcodes`, {
          city: ele,
        })
        .then((response) => {
          if (response.data.status) {
            console.log(response, "responsezipcode");
            const zipCodeArray = response.data.data.map((item) => item.zip);
            setZipCodes(zipCodeArray);
          } else {
            console.error("API status is false, check the response:", response);
            setZipCodes([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching zip codes:", error);
          setZipCodes([]);
        });
    } else {
      setZipCodes([]);
    }
  };

  useEffect(() => {
    if (citydata.length > 0) {
      fetchZipCodes();
    }
  }, [citydata]);

  const handleHeaderClick = (key, ele) => {
    setActiveKey(activeKey === key ? null : key);
    const index = parseInt(key, 10);

    switch (index) {
      case 0:
        fetchZipCodes(ele);
        break;
      case 1:
        fetchZipCodes(ele);
        break;
      case 2:
        fetchZipCodes(ele);
        break;
      case 3:
        fetchZipCodes(ele);
        break;
      case 4:
        fetchZipCodes(ele);
        break;
      case 5:
        fetchZipCodes(ele);
        break;
      case 6:
        fetchZipCodes(ele);
        break;
      case 7:
        fetchZipCodes(ele);
        break;
      case 8:
        fetchZipCodes(ele);
        break;
      case 9:
        fetchZipCodes(ele);
        break;
      case 10:
        fetchZipCodes(ele);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="searchForm">
        <form>
          <div className="d-flex">
            <div className="bgWhite">
              <div className="form-group mb-4">
                <label>Country</label>
                <select
                  className="form-select"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mb-4">
                <label>State</label>
                <select
                  className="form-select"
                  value={selectedState}
                  onChange={handleStateChange}
                  disabled={!selectedCountry}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group mb-4">
                <label>City</label>
                <Select
                  isMulti
                  options={cities.map((city) => ({
                    value: city.name,
                    label: city.name,
                  }))}
                  value={citydata?.map((cityName) => ({
                    value: cityName,
                    label: cityName,
                  }))}
                  onChange={(selectedOptions) => {
                    SetCityData(
                      selectedOptions?.map((option) => option?.value)
                    );
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isDisabled={!selectedState}
                />
              </div>
              {citydata && (
                <>
                  <div className="form-group mt-4">
                    <>
                      {/* {zipCodes.length > 0 && (
                        <label>Zip Codes Of Selected City {citydata}</label>
                      )} */}
                    </>

                    <div className="distance">
                      {/* {zipCodes.map((zipCode, index) => (
                        <div className="form-group mt-2" key={index}>
                          <label>
                            <input
                              className="selectAllCheck"
                              type="checkbox"
                              value={zipCode}
                              onChange={() => handleZipCodeChange(zipCode)}
                              checked={
                                // (checkedZipCodes && !!checkedZipCodes[zipCode])
                                //  ||
                                !!zipcodedata && zipcodedata[zipCode]
                              }
                            />{" "}
                            {zipCode}
                          </label>
                        </div>
                      ))} */}
                      {citydata.length > 0 && (
                        <label>Zip Code's Of Selected City </label>
                      )}
                      <Accordion activeKey={activeKey}>
                        {citydata.map((ele, index) => (
                          <Accordion.Item
                            // eventKey="0"
                            eventKey={index.toString()}
                            key={index}
                          >
                            <Accordion.Header
                              onClick={() =>
                                handleHeaderClick(index.toString(), ele)
                              }
                            >
                              {ele}
                            </Accordion.Header>
                            <Accordion.Body>
                              {zipCodes.map((zipCode, index) => (
                                <div className="form-group mt-2" key={index}>
                                  <label>
                                    <input
                                      className="selectAllCheck"
                                      type="checkbox"
                                      value={zipCode}
                                      onChange={() =>
                                        handleZipCodeChange(zipCode)
                                      }
                                      checked={
                                        !!zipcodedata && zipcodedata[zipCode]
                                      }
                                    />{" "}
                                    {zipCode}
                                  </label>
                                </div>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="p-4 bgWhiteRight">
              <div className="form-group mb-4">
                <label>Business Type</label>
                <Accordion>
                  {categoriesData.map((category, index) => (
                    <Accordion.Item
                      eventKey={index.toString()}
                      key={category._id}
                    >
                      <Accordion.Header>
                        <input
                          className="selectAllCheck"
                          type="checkbox"
                          checked={
                            categoryCheckboxes[category._id]?.checked || false
                          }
                          onChange={() =>
                            handleCategoryCheckboxChange(category._id, category)
                          }
                        />{" "}
                        {category.BusinessCategory}
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="listBusiness">
                          {category.SubCategories.map(
                            (subCategory, subIndex) => (
                              <li key={subIndex}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={
                                      categoryCheckboxes[category._id]
                                        ?.subCategories[subIndex] || false
                                    }
                                    onChange={() =>
                                      handleSubCategoryCheckboxChange(
                                        category._id,
                                        subIndex
                                      )
                                    }
                                  />
                                  {subCategory}
                                </label>
                              </li>
                            )
                          )}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              {createReq ? (
                <>
                  <div className="col-12 mb-3">
                    <label className="form-label mb-1">Message</label>
                    <textarea
                      className="form-control"
                      type="text"
                      id="message"
                      name="message"
                      value={message}
                      rows={5}
                      onChange={handleChange}
                    ></textarea>
                    {/* {errors.message && (
                <div className="text-danger">{errors.message}</div>
              )} */}
                  </div>
                  <Button
                    variant="outline-primary"
                    className="px-3"
                    onClick={handleClickCreateReq}
                  >
                    Create Request
                  </Button>
                </>
              ) : (
                <Button
                  variant="outline-primary"
                  className="px-3"
                  onClick={handleShowListingsClick}
                >
                  Show Listing
                </Button>
              )}
              {/* <br/><br/><br/><br/><br/><br/><br/> */}
            </div>

            {/* <div className="searchFilterMap">
              <iframe frameborder="0" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Population Estimator map</a></iframe>
            </div> */}
            <div id="map" className="searchFilterMap"></div>
          </div>
        </form>
      </div>
    </>
  );
}

export default FilterSearch;
