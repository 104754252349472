// // components/Sidebar.js
import React, { useState } from 'react';
import { FaChartPie, FaBars } from "react-icons/fa";
import './leftsidebar.css';
import {Link} from 'react-router-dom';

import { FaAngleDown } from "react-icons/fa6";

const Sidebar = () => {


  const [isActive, setIsActive] = useState(false);
  
  const toggleClass = () => {
    setIsActive(!isActive);
  }


  return (


    <div className="home-page-container">
      
    <h3>Select Data Extracting Method</h3>
  
    <Link to='/rpa' className="sidebar-item">
      <span className="icon"><FaChartPie /></span> {/* Icon */}
        <span className="text">RPA</span> {/* Text */}
      </Link>
    <Link to='/import-from-web' className="sidebar-item">
      <span className="icon"><FaChartPie /></span> {/* Icon */}
        <span className="text">Import from Web</span> {/* Text */}
      </Link>
    <Link to='/llm' className="sidebar-item">
      <span className="icon"><FaChartPie /></span> {/* Icon */}
        <span className="text">LLM</span> {/* Text */}
        </Link>
    <Link to='/import-from-google' className="sidebar-item">
      <span className="icon"><FaChartPie /></span> {/* Icon */}
        <span className="text">Import Data from Google</span> {/* Text */}
        </Link>

    {/* <Link to='/my-files-data' className="sidebar-item">
      <span className="icon"><FaChartPie /></span>
      <span className="text">My Files</span>
    </Link> */}

    <Link onClick={toggleClass} className="sidebar-item">
      <span className="icon"><FaChartPie /></span>
      <span className="text">My Files</span>
      <span className='float-end me-2 mt-2'><FaAngleDown /></span>
    </Link>
    <div className={isActive ? "sendRec active" : "sendRec"}>
      <Link to='/my-files-data'>Sender</Link>
      <hr className='my-2' />
      <Link to='/import-receive'> Receive</Link>
    </div>
  
  </div>
  );
};

export default Sidebar;
