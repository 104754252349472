// import React, { useState } from "react";

// import { TbLayoutCollage, TbPhoto } from "react-icons/tb";
// import { IoSettingsOutline } from "react-icons/io5";
// import { LuHeadphones } from "react-icons/lu";
// import { Link } from "react-router-dom";
// import { FaBloggerB, FaImage, FaVideo } from "react-icons/fa6";
// import CkEditiorListing_for_dashboard from "./CkEditiorListing_for_dashboard";
// import Imagegenrator from "./Imagegenrator";
// import Videogenrator from "./Videogenrator";
// import SaveContentImageListing from "./SaveContentImageListing";

// function EditiorSidebar() {
//   const [activeTab, setActiveTab] = useState(1);
//   const [isDropdown1Open, setDropdown1Open] = useState(false);
//   const [isDropdown2Open, setDropdown2Open] = useState(false);
//   const [isDropdown3Open, setDropdown3Open] = useState(false);

//   const handleTabClick = (tabIndex) => {
//     setActiveTab(tabIndex);
//   };
//   const toggleDropdown1 = () => {
//     setDropdown1Open(!isDropdown1Open);
//   };

//   const toggleDropdown2 = () => {
//     setDropdown2Open(!isDropdown2Open);
//   };

//   const toggleDropdown3 = () => {
//     setDropdown3Open(!isDropdown3Open);
//   };

//   return (
//     <>
//       <div className="d-flex mainDashboard">
//         <div className="sidebarDashbaord">
//           <div className="leftMenu">
//             <div className="leftSideBar">
//               <div className="logoItem"></div>
//               <div className="sideMenu">
//                 <ul>
//                   {/* <li><Link to="/Contentgenrator_old"><TbPhoto className='icon' /> Creative</Link></li> */}
//                 </ul>
//                 {/* <h3 className='moreLine'>More</h3> */}
//                 <ul>
//                   {/* <li>
//                     <Link to="/blog">
//                       <TbPhoto className="icon" /> Blog{" "}
//                     </Link>
//                   </li>

//                   <li>
//                     <Link to="/imageg_genrator">
//                       <TbPhoto className="icon" />
//                       Image generate{" "}
//                     </Link>
//                   </li>

//                   <li>
//                     <Link to="/video_genrator">
//                       <TbPhoto className="icon" /> Video generate
//                     </Link>
//                   </li> */}

//                   {/* <li><Link><IoSettingsOutline className='icon' /> Settings</Link></li> */}
//                 </ul>

//                 <ul className="tab-list">
//                   <li>
//                     <Link
//                       className={activeTab === 1 ? "active" : ""}
//                       onClick={() => handleTabClick(1)}
//                     >
//                       <FaBloggerB className="me-2" /> Blog
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       className={activeTab === 2 ? "active" : ""}
//                       onClick={() => handleTabClick(2)}
//                     >
//                       <FaImage className="me-2" />
//                       Image generate
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       className={activeTab === 3 ? "active" : ""}
//                       onClick={() => handleTabClick(3)}
//                     >
//                       <FaVideo className="me-2" /> Video generate
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       className={activeTab === 4 ? "active" : ""}
//                       onClick={() => handleTabClick(4)}
//                     >
//                       <FaBloggerB className="me-2" />
//                       User Data
//                     </Link>
//                     {activeTab === 4 && (
//                       <div className="dropdowns">
//                         <div className="mt-4">
//                           <Link
//                             className={isDropdown1Open ? "active" : ""}
//                             onClick={() => toggleDropdown1}
//                           >
//                             <FaBloggerB className="me-2" />
//                             Generated Content
//                           </Link>

//                           {isDropdown1Open && (
//                             <div className="dropdown-content">
//                                           <SaveContentImageListing activeTab={activeTab} />
//                             </div>
//                           )}
//                         </div>

//                         <div className="mt-2">
//                           <Link
//                             className={activeTab === 4 ? "active" : ""}
//                             onClick={() => toggleDropdown2}
//                           >
//                             <FaBloggerB className="me-2" />
//                             Generated Images
//                           </Link>

//                           {isDropdown2Open && (
//                             <div className="dropdown-content"></div>
//                           )}
//                         </div>
//                         <div className="mt-2">
//                           <Link
//                             className={activeTab === 4 ? "active" : ""}
//                             onClick={() => toggleDropdown3}
//                           >
//                             <FaBloggerB className="me-2" />
//                             Generated Video
//                           </Link>

//                           {isDropdown3Open && (
//                             <div className="dropdown-content"></div>
//                           )}
//                         </div>
//                       </div>
//                     )}
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="px-5 pt-5 w-100">
//           <div
//             className={`tabFiles ckEditerCustome ${
//               activeTab === 1 ? "active" : ""
//             }`}
//           >
//             <CkEditiorListing_for_dashboard />
//           </div>
//           <div className={`tabFiles ${activeTab === 2 ? "active" : ""}`}>
//             <Imagegenrator />
//           </div>
//           <div className={`tabFiles ${activeTab === 3 ? "active" : ""}`}>
//             <Videogenrator />
//           </div>
//           <div className={`tabFiles ${activeTab === 4 ? "active" : ""}`}>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default EditiorSidebar;

import React, { useState } from "react";
import { TbLayoutCollage, TbPhoto } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { LuHeadphones } from "react-icons/lu";
import { Link } from "react-router-dom";
import { FaBloggerB, FaImage, FaVideo } from "react-icons/fa6";
import CkEditiorListing_for_dashboard from "./CkEditiorListing_for_dashboard";
import Imagegenrator from "./Imagegenrator";
import Videogenrator from "./Videogenrator";
import SaveContentImageListing from "./SaveContentImageListing";
import SaveImagesListing from "./SaveImagesListing";

function EditiorSidebar() {
  const [activeTab, setActiveTab] = useState(1);
  const [isDropdown1Open, setDropdown1Open] = useState(false);
  const [isDropdown2Open, setDropdown2Open] = useState(false);
  const [isDropdown3Open, setDropdown3Open] = useState(false);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const toggleDropdown1 = () => {
    setDropdown1Open(!isDropdown1Open);
    setDropdown2Open(false);
    setDropdown3Open(false);
  };

  const toggleDropdown2 = () => {
    setDropdown2Open(!isDropdown2Open);
    setDropdown1Open(false);
    setDropdown3Open(false);
  };

  const toggleDropdown3 = () => {
    setDropdown3Open(!isDropdown3Open);
    setDropdown1Open(false);
    setDropdown2Open(false);
  };

  return (
    <>
      <div className="d-flex mainDashboard">
        <div className="sidebarDashbaord">
          <div className="leftMenu">
            <div className="leftSideBar">
              <div className="logoItem"></div>
              <div className="sideMenu">
                <ul></ul>
                <ul></ul>
                <ul className="tab-list">
                  <li>
                    <Link
                      className={activeTab === 1 ? "active" : ""}
                      onClick={() => handleTabClick(1)}
                    >
                      <FaBloggerB className="me-2" /> Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={activeTab === 2 ? "active" : ""}
                      onClick={() => handleTabClick(2)}
                    >
                      <FaImage className="me-2" />
                      Generate Image
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={activeTab === 3 ? "active" : ""}
                      onClick={() => handleTabClick(3)}
                    >
                      <FaVideo className="me-2" /> Generate Video
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={activeTab === 4 ? "active" : ""}
                      onClick={() => handleTabClick(4)}
                    >
                      <FaBloggerB className="me-2" /> User Data
                    </Link>
                    {activeTab === 4 && (
                      <div className="dropdowns">
                        <div className="mt-4">
                          <Link
                            className={isDropdown1Open ? "active" : ""}
                            onClick={toggleDropdown1}
                          >
                            <FaBloggerB className="me-2" />
                            Generated Content
                          </Link>
                        </div>
                        <div className="mt-2">
                          <Link
                            className={isDropdown2Open ? "active" : ""}
                            onClick={toggleDropdown2}
                          >
                            <FaBloggerB className="me-2" />
                            Generated Images
                          </Link>
                          {isDropdown2Open && (
                            <div className="dropdown-content"></div>
                          )}
                        </div>
                        <div className="mt-2">
                          <Link
                            className={isDropdown3Open ? "active" : ""}
                            onClick={toggleDropdown3}
                          >
                            <FaBloggerB className="me-2" />
                            Generated Video
                          </Link>
                          {isDropdown3Open && (
                            <div className="dropdown-content"></div>
                          )}
                        </div>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="px-5 pt-5 w-100">
          <div
            className={`tabFiles ckEditerCustome ${
              activeTab === 1 ? "active" : ""
            }`}
          >
            <CkEditiorListing_for_dashboard />
          </div>
          <div className={`tabFiles ${activeTab === 2 ? "active" : ""}`}>
            <Imagegenrator />
          </div>
          <div className={`tabFiles ${activeTab === 3 ? "active" : ""}`}>
            <Videogenrator />
          </div>
          <div className={`tabFiles ${activeTab === 4 ? "active" : ""}`}>
            {isDropdown1Open && (
              <SaveContentImageListing activeTab={activeTab} />
            )}
            {isDropdown2Open && <SaveImagesListing activeTab={activeTab} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default EditiorSidebar;
