import React, { useState } from "react";
import { Button, Col, Row } from "antd";
import TextArea from "antd/es/input/TextArea";

const RewriteModal = ({ textValue, onClose, onChangeText,setBlock ,Block}) => {
  const [rewriteText, setRewriteText] = useState(textValue);
  const [responseText, setResponseText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setResponseText("This is a rewritten content ");
      }, 3000);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleInsert = () => {
    onChangeText(Block);
    onClose();
  };

  return (
    <>
      <div
        style={{
          background: "white",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
          minWidth: "1000px",
        }}

        className="customehsggsgs"
      >
        <TextArea
          rows={6}
          cols={50}
          placeholder="Type your text here..."
          // value={responseText.length == "" ? rewriteText : responseText}
          value={Block}
          // onChange={(e) => setRewriteText(e.target.value)}
          onChange={(e) => setBlock(e.target.value)}

          style={{ border: "1px solid #d9d9d9", borderRadius: "4px", minHeight:"300px", }}
        />
        <Row justify="end" style={{ marginTop: "10px" }}>
          <Col>
            <Button style={{ marginRight: "8px" }} onClick={onClose}>
              Cancel
            </Button>
            {responseText.length == "" ? (
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={loading}
                disabled={loading}
              >
                {loading ? "Rewriting" : "Rewrite"}
              </Button>
            ) : (
              <Button onClick={handleInsert}>Insert</Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default RewriteModal;
