import React, { useState } from "react";
import { allApi } from "../../ApiWrapper";

import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import TextareaAutosize from "react-textarea-autosize";

import { Button, Checkbox, Modal } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Imagegenrator = () => {
  const [inputText, setInputText] = useState();
  const [images, setImages] = useState([]);
  const [checkedUrls, setCheckedUrls] = useState([]);
  const [displayImages, setDisplayImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadImage,setUploadImage]=useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [llmModeldata, setLLMmodeldata] = useState({});
  const userID = useSelector((state) => state?.all?.auth?.data?._id);
  const navigate=useNavigate();

  const LLMmodel = async () => {
    try {
      const response = await allApi({
        url: `https://us2.sourcesoftsolutions.com:8072/get_configs/${userID}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        formData: false,
      });

      console.log("fetchmodeluserpreference", response?.data);
      setLLMmodeldata(response?.data);
      return response?.data;
    } catch (error) {
      console.error("Error fetching LLM model data:", error);
    }
  };

  const handleImageCheck = (image) => {
    const newCheckedUrls = [...checkedUrls];
    if (newCheckedUrls.includes(image)) {
      newCheckedUrls.splice(newCheckedUrls.indexOf(image), 1);
    } else {
      newCheckedUrls.push(image);
    }
    setCheckedUrls(newCheckedUrls);
  };

//   const handleInsert = async() => {
//     setDisplayImages(checkedUrls);
//     setCheckedUrls([]);
//     const formData = new FormData();

//     try {
//       formData.append("files[]",checkedUrls);

//       await allApi({
//        url: 'https://us2.sourcesoftsolutions.com:8062/uploadImage',

//         method: "POST",
//         body: formData,
//         formData: true,
//       }).then((data) => {
//         setUploadImage(data?.response);
// console.log("upload images",data?.response)
//         if (data?.status) {

//           toast.success(data?.message);
//         }
//         toast.error(data?.error);
//       });
//     } catch (error) {
//     } finally {
//     }
//     handleClose();
//   };


const handleInsert = async() => {
  setDisplayImages(checkedUrls);
  setCheckedUrls([]);
  const formData = new FormData();

  try {
    

    // for (let i = 0; i < checkedUrls?.length; i++) {
    //   formData.append('files[]', checkedUrls[i]);
    // }
    formData.append('files[]', checkedUrls);

    await allApi({
      url: 'https://us2.sourcesoftsolutions.com:8062/uploadImage',
      method: "POST",
      body: formData,
      formData: true,
    }).then((data) => {
      setUploadImage(data?.response);
      console.log("upload images", data?.response);
      if (data?.status) {
        toast.success(data?.message);
      } else {
        toast.error(data?.error);
      }
    });
  } catch (error) {
    console.error("Error while uploading images:", error);
  } finally {
    handleClose();
  }
};

  const handleGenerateImage = async () => {
    setImages([]);
    setCheckedUrls([]);
    const modelData = await LLMmodel();
    console.log("modelData", modelData);
    const formData = new FormData();

    
if(modelData?.tool_image_gen)
  {
    formData.append("llm_factory", modelData?.model_image_gen);
    formData.append("agent_type", "image");
    formData.append("tool", modelData?.tool_image_gen);
    formData.append("agent_task_prompt", modelData?.image_agent_task_prompt);
    formData.append("user_prompt", inputText);
    formData.append("openai_key",modelData?.openai);


    try {
      setLoading(true);
      setShow(true);

      await allApi({
        // url: `https://us2.sourcesoftsolutions.com:8076/create_agent`,
       url: 'https://us2.sourcesoftsolutions.com:5006/create_agent',

        method: "POST",
        body: formData,
        formData: true,
      }).then((data) => {
        console.log(data, "imagedata");
        setImages(data?.response);

        if (data?.status) {
          setLoading(false);

          toast.success(data?.message);
        }
        toast.error(data?.error);
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  else
  {
    toast.success("add keys..... ")
    navigate('/UserPrefrence')

  }
  };





  const saveImages = async () => {

    try {
      let resp;

      // const encodedMessage = encodeURIComponent(contentBlocks);

      resp = await allApi({
        url: "https://us2.sourcesoftsolutions.com:8062/saveImages",
        body: {
          user_id: userID,
          prompt: inputText,
          llm_used: "openai",
          tools_used: "dalle",
          generated_images: displayImages,
          language: "English",
        },

        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        formData: false,
      });

      if (resp) {
        toast.success(resp?.message);
      }

    } catch (error) {
      console.error("Error while making API call:", error);
    }
  };

  return (
    <>
      <div className="container-fluid ps-3">
        <div className="mainBox themeInput pt-5">
          <div className="row justify-content-center pt-5">
            <div className="col-lg-7">
              <h1 className="text-center">Enter Prompt For Image Genrator</h1>
              <div className="form-group mt-4">
                <label htmlFor="prompt">Enter prompt:</label>
                <TextareaAutosize
                  id="prompt"
                  className="form-control mt-2"
                  placeholder="Enter your input"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                />
              </div>

              <div className="d-flex justify-content-center twoBothBtn my-5 ">
                <button
                  type="submit"
                  className="btn btnSuccess"
                  onClick={handleGenerateImage}
                >
                  Genrate Image
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

{displayImages && displayImages?.length > 0 && (
  <div className="container mt-3">
    <div className="row justify-content-center">
      {displayImages.map((url, index) => (
        <div key={index} className="col-sm-6 col-md-4 col-lg-3 mb-4">
          <div className="card w-auto">
            <img
              src={url}
              className="card-img-top"
              alt={`Image ${index + 1}`}
            />
          </div>
        </div>
      ))}
    </div>
    <button className="btn btn-primary" onClick={saveImages}>
      Save Images
    </button>
  </div>
)}


      <Modal
        title={"AI Generated Images"}
        open={show}
        footer={[
          <Button key="back" onClick={handleClose}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={!images?.length}
            onClick={handleInsert}
          >
            Insert
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            disabled={loading}
            onClick={handleGenerateImage}
          >
            {loading ? "Generating" : "Regenerate"}
          </Button>,
        ]}
      >
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              minHeight: "200px",
            }}
          >
            {loading && (
              <Oval
                visible={true}
                height="50"
                width="50"
                color="#316CF4"
                ariaLabel="oval-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            )}
            {images?.length > 0 &&
              images?.map((image, index) => (
                <div
                  key={index}
                  style={{
                    marginRight: "10px",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleImageCheck(image)}
                >
                  <Checkbox
                    id={`check-${index}`}
                    name={`check-${index}`}
                    checked={checkedUrls.includes(image)}
                    onChange={() => handleImageCheck(image)}
                  />
                  <img
                    src={image}
                    alt={`Thumbnail ${index + 1}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      border: checkedUrls.includes(image)
                        ? "2px solid blue"
                        : "2px solid transparent",
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Imagegenrator;
