import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getPayload,
  setPopup,
  setSelectedConfig,
  setSelectedReceviers,
  setSelectedSenders,
  SubmitPost,
} from "../../../../Store/Action";
import { useDispatch } from "react-redux";
import {
  SENDER_DATA,
  SAVE_SENDER_LIST,
  UNIQUE_ID,
} from "../../../../Store/Action/Type";
import "../Listing/listing.css";
import NavLink from "../New/routes/NavLink";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

import { v4 as uuidv4 } from "uuid";
import { Modal } from "react-bootstrap";
import ReceiverSelect from "./RececiverSelect";

import { FaAngleRight, FaListCheck } from "react-icons/fa6";

import { IoSearch } from "react-icons/io5";
import UploadSender from "./UploadSender";

function SenderSelect() {
  const {
    // SenderData,
    unique,
  } = useSelector((state) => state?.all);

  // const Senderlist = localStorage.getItem("allsender");
  // const SenderData = JSON.parse(Senderlist);
     const SenderData = useSelector((state) => state?.all?.SENDER_LIST);;


  console.log(SenderData, "SenderData");
  const [show, setshow] = useState(true);

  console.log("unique+++", unique);
  const [showuplad, setshowuplaod] = useState(false);
  const [showA, setShowA] = useState({});
  const [showrecevier, setShowRecevier] = useState(false);
  const [configName, setConfigName] = useState("");
  const popupReducer = useSelector((state) => state?.all);
  const { showModal } = popupReducer?.popupReducer?.modal || {};

  const [showB, setShowB] = useState([]);

  const [active, setActive] = useState([]);

  const [rightActive, setRightActive] = useState([]);

  const dispatch = useDispatch();
  const [sender, setSender] = useState([]);

  const selectedSenders = useSelector((state) => state?.all);
  console.log("selectedSenders", selectedSenders?.selectedSenders);
  const selectedConfig = useSelector((state) => state?.all?.selectedConfig);
  console.log("selctedConfig", selectedConfig);
  const [data, setdata] = useState({
    user_id: "",
    name: "",
    senders_id: [],
    receivers_id: [],
    schedule: "",
    template_id: "",
  });

  // useEffect(() => {
  //   FetchApi();
  // }, []);

  useEffect(() => {
    setShowA(SenderData);
    if (selectedConfig && selectedConfig.data) {
      const configData = selectedConfig.data.configuration;
      const selectConfig = selectedConfig?.data;
      setdata({
        ...data,
        name: configData?.name,
        senders_id: selectConfig?.senders?.map((ele) => ele?._id),
        receivers_id: selectConfig?.receivers?.map((ele) => ele?._id),
        template_id: configData.template_id,
      });
      setConfigName(configData.name);

      setShowB(
        SenderData?.filter((sender) =>
          selectedConfig.data.configuration.senders_id.includes(sender._id)
        )
      );
    }
    // else if (selectedSenders?.selectedSenders?.length > 0) {
    //   console.log("object", selectedSenders?.selectedSenders);
    //   setShowB(selectedSenders?.selectedSenders);

    //   // setConfigName(selectedSenders?.selectedSenders?.configName);
    // }
    if (selectedSenders?.selectedSenders?.showB?.length > 0) {
      setConfigName(selectedSenders?.selectedSenders?.configName);
      setShowB(selectedSenders?.selectedSenders?.showB);
    }
  }, [SenderData]);

  console.log("data?.senders_id", data);
  const FetchApi = () => {
    // if (Object.keys(SenderData)) {
    dispatch(getPayload(SENDER_DATA));
    // }
  };

  const selectShowA = () => {
    console.log("callselectShowA");
    // let arr = Object.keys(showA);
    let arr = showA;
    console.log("arr", showA);
    if (showA.length === active.length) {
      setActive([]);
    } else {
      setActive(arr);
    }
  };

  const selectShowB = () => {
    let arr = showB;

    if (showB.length === rightActive.length) {
      setRightActive([]);
    } else {
      setRightActive(arr);
    }
  };

  const handleClickLeft = (val) => {
    let arr = [...active];

    let idx = arr.findIndex((ele) => ele === val);
    if (idx >= 0) {
      arr.splice(idx, 1);
    } else {
      arr.push(val);
    }
    console.log("arrhandleClickLeft", arr);
    setActive(arr);
  };

  console.log("active", active);

  const handleClickRight = (val) => {
    console.log("val", val);
    let arr = [...rightActive];
    let idx = arr.findIndex((ele) => ele === val);
    if (idx >= 0) {
      arr.splice(idx, 1);
    } else {
      arr.push(val);
    }
    setRightActive(arr);
    console.log("arr222", arr);
  };

  const setDataRight = () => {
    console.log("showBsetDataRight", active);

    if (!active.length) {
      return;
    }

    let leftData = [...active];

    console.log(leftData, "254");
    console.log("active>>>>>>>>>>", active);

    console.log("activesdf", active);
    setShowB(leftData);
    // setActive([]);
  };

  const setDataLeft = () => {
    console.log("callingleft");
    console.log("rightActive", rightActive);
    if (!rightActive.length) {
      return;
    }

    let rightData = [...showB];

    console.log("rightData", rightActive);

    const filteredData = rightData.filter((ele) => {
      return !rightActive.some((item) => item._id === ele._id);
    });

    setShowB(filteredData);
    console.log("rightData", rightData);
    setRightActive([]);
  };

  const HandleOpen = () => {
    setShowRecevier(true);
  };

  const onSubmit = async (e) => {
    console.log("showBnext", showB);
    e.preventDefault();

    if (showB.length ==0) {
      toast.error("Please Select Senders from the right list");
      return;
    }

    const newUniqueId = uuidv4();
    dispatch({ type: UNIQUE_ID, payload: newUniqueId });

    // await new Promise((resolve, reject) => {
    let arr = [];
    console.log("=showB====", showB);
    for (const iterator of showB) {
      arr.push(iterator?._id);
    }
    const newData = {
      ...data,
      name: configName,
      senders_id: arr,
      config_id: selectedConfig && selectedConfig.data.configuration?._id,
    };

    localStorage.setItem("data", JSON.stringify(newData));
    dispatch(setSelectedSenders({ showB, configName: configName }));

    dispatch(setPopup({ modalType: "RECEVIER", showModal: true }));

    console.log("arrqwertyu", arr);
  };

  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput.split("").length > 1) {
      const filtersender = Object.keys(showA || {}).filter((item) =>
        item.toLowerCase().includes(searchInput.toLowerCase())
      );

      console.log(filtersender);
      console.log("Filter Data", filtersender, SenderData);
      setShowA(filtersender.reduce((a, v) => ({ ...a, [v]: v }), {}));
      console.log(searchValue);
    } else {
      setShowA(SenderData);
    }
  };

  const [searchInputnew, setSearchInputnew] = useState("");

  const searchItemsnew = (searchValuenew) => {
    setSearchInputnew(searchValuenew);
    if (searchInputnew.split("").length > 1) {
      const filtersendernew = Object.keys(showB || {}).filter((item) =>
        item.toLowerCase().includes(searchInputnew.toLowerCase())
      );

      console.log(filtersendernew);
      console.log("Filter Data", filtersendernew, SenderData);
      setShowB(filtersendernew.reduce((a, v) => ({ ...a, [v]: v }), {}));
      console.log(searchValuenew);
    } else {
      setShowB(SenderData);
    }
  };

  console.log("showB", showB);

  console.log("Rendered showA:", showA);
  console.log("Rendered showB:", showB);

  console.log("showA", show);

  const handleClose = () => {
    dispatch(setSelectedSenders());
    dispatch(setSelectedConfig());
    dispatch(setSelectedReceviers());
    localStorage.removeItem("data");
    dispatch(setPopup({ modalType: "", showModal: false }));
  };
  const handleUplaod = () => {
    // handleClose();
    dispatch(setPopup({ modalType: "UPLOAD_SENDER", showModal: true }));
  };
  return (
    <>
      <Modal
        // show={setshow !== true ? show : false}
        show={showModal}
        onHide={handleClose}
        size="xl"
        className="emailPoup"
      >
        <div className="px-3">
          <div className="row justify-content-center">
            <div className="col-xl-2 border-end">
              <div className="stepsList pt-5">
                <ul className="listOpt">
                  <li className="active">
                    <span>1</span>Chose Senders
                  </li>
                  <li>
                    <span>2</span>Chose Receivers
                  </li>
                  <li>
                    <span>3</span>Chose Template
                  </li>
                  <li>
                    <span>4</span>Chose Time
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-10 ps-4">
              <h3 className="popTitle my-4">
                <FaListCheck className="me-2" /> Create Your Configuration
              </h3>
              <p className="mb-2">Configuration Name</p>
              <div className="configName d-inline-flex align-items-center">
                <input
                  className="form-control mb-0 me-3"
                  type="text"
                  name="name"
                  value={
                    // configData && configData
                    //   ? configData?.data?.configuration?.name
                    //   :
                    configName
                  }
                  onChange={(e) => setConfigName(e.target.value)}
                />
                <span>(It's default name you can update it.)</span>
              </div>

              <div className="choseSender my-4">
                <h3 className="d-flex justify-content-between mb-1">
                  Choose Senders
                  {/* <Link to="/importsenders"> */}
                  {/* <img
                    className="img-fluid"
                    src="Images/contact-icon-v3-small.svg"
                    alt="imagesender"
                    onClick={handleUplaod}
                  /> */}
                  <button   onClick={handleUplaod}> Import Senders</button>
                  {/* </Link> */}
                </h3>
                <p>Select senders from left side and move them to right.</p>
              </div>

              <div className="row">
                <div className="dual-list list-left col-md">
                  <div className="well text-right">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <div className="btn-group checkBoxSize">
                          <input
                            type="checkbox"
                            className="checkBox m-0"
                            onClick={selectShowA}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="serchBox m-0">
                          <input
                            type="search"
                            name="SearchDualList"
                            className="form-control m-0"
                            placeholder="Search"
                            onChange={(e) => searchItems(e.target.value)}
                          />
                          <button>
                            <IoSearch />
                          </button>
                        </div>
                      </div>
                    </div>
                    <ul className="list-group">
                      {showA && Array.isArray(showA) && showA.length > 0 ? (
                        showA.map((entry, index) => {
                          let username = entry;
                          return (
                            <li
                              className={`list-group-item ${
                                active.includes(username) && "active"
                              }`}
                              key={index}
                              onClick={() => handleClickLeft(username)}
                            >
                              {` ${username?.first_name}  ${username?.last_name}`}
                            </li>
                          );
                        })
                      ) : (
                        <li>No Senders</li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="list-arrows col-auto text-center d-flex flex-column justify-content-center align-items-center">
                  <button
                    className="btn btn-default btn-sm move-right"
                    onClick={setDataRight}
                  >
                    <i className="far fa-arrow-square-right" />
                  </button>
                  <button
                    className="btn btn-default btn-sm move-left"
                    onClick={setDataLeft}
                  >
                    <i className="far fa-arrow-square-left" />
                  </button>
                </div>
                <div className="dual-list list-right col-md">
                  <div className="well">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <div className="btn-group checkBoxSize">
                          <input
                            type="checkbox"
                            className="checkBox m-0"
                            onClick={selectShowB}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="serchBox m-0">
                          <input
                            type="text"
                            name="SearchDualList"
                            className="form-control m-0"
                            placeholder="search"
                            onChange={(e) => searchItemsnew(e.target.value)}
                          />
                          <button>
                            <IoSearch />
                          </button>
                        </div>
                      </div>
                    </div>
                    <ul className="list-group">
                      {/* {configData && configData
                        ? configData?.data?.senders?.map((ele, index) => {
                            console.log("ele::::::", ele);
                            if (!ele) return null;
                            <>
                              <li
                                className={`list-group-item ${"active"}`}
                                key={index}
                                onClick={() => handleClickRight(show)}
                              >
                                {`${ele.first_name}  ${ele.last_name} `}
                                jahnvi sah
                              </li>
                            </>;
                          })
                        :  */}
                      {/* {selectedSenders?.selectedSenders &&
                      selectedSenders?.selectedSenders?.length > 0
                        ? selectedSenders?.selectedSenders?.map(
                            (show, index) => {
                              if (!show) return null;
                              return (
                                <li
                                  className={`list-group-item ${
                                    rightActive.includes(show) && "active"
                                  }`}
                                  key={index}
                                  onClick={() => handleClickRight(show)}
                                >
                                  {`${show.first_name}  ${show.last_name} `}
                                </li>
                              );
                            }
                          )
                        :  */}
                      {showB &&
                        Array.isArray(showB) &&
                        showB.map((show, index) => {
                          if (!show) return null;
                          return (
                            <li
                              className={`list-group-item ${
                                rightActive.includes(show) && "active"
                              }`}
                              key={index}
                              onClick={() => handleClickRight(show)}
                            >
                              {`${show.first_name}  ${show.last_name} `}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end btnBackNext">
            {/* <button type="button" className="btn-alternate me-4">Back</button> */}
            <button type="button" className="btn buttonNext" onClick={onSubmit}>
              Next <FaAngleRight className="ms-1 mb-1" />
            </button>
          </div>
          <ToastContainer />
        </div>
      </Modal>
      {/* {showrecevier && (
        <ReceiverSelect

        //   showmoadl={showrecevier}
        />
      )}

      {showuplad && <UploadSender />} */}
    </>
  );
}

export default SenderSelect;
