import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { FaAngleLeft, FaAngleRight, FaListCheck } from "react-icons/fa6";
import {
  getEmailPayload,
  setConfiguration,
  setPopup,
  setSelectedConfig,
  setSelectedReceviers,
  setSelectedSenders,
} from "../../../../Store/Action";
import { CONFIGURATION } from "../../../../Store/Action/Type";

const SchduleEdit = () => {
  const [deliveryTime, setDeliveryTime] = useState("later");
  const dispatch = useDispatch();
  const popupReducer = useSelector((state) => state?.all);
  const { showModal } = popupReducer?.popupReducer?.modal || {};

  const scheduleEditData = popupReducer?.popupReducer?.modal?.scheduleEditData;

  const [timezone, setTimeZone] = useState(scheduleEditData?.timezone || "");
  const schedule = scheduleEditData?.schedule || "";

  console.log(scheduleEditData?.schedule, "scheduleEditData");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryHour, setDeliveryHour] = useState("");

  const [show, setShow] = useState(true);
  const template = localStorage?.getItem("template");
  const templatedata = JSON.parse(template);
  // console.log("templatedata", templatedata);
  const userID = useSelector((state) => state?.all?.auth?.data?._id);
  const senderData = localStorage.getItem("EditConfigdata");
  // const configData = JSON.parse(senderData);
  const formatDateWithoutMillisAndZ = (date) => {
    return date.toISOString().replace(/\.\d{3}Z$/, "");
  };
  const data = localStorage?.getItem("data");
  const configurationdata = JSON.parse(data);
  // console.log("configurationdata", configurationdata);

  const configData = useSelector(
    (state) => state?.all?.SelectedConfig?.data?.configuration
  );

  // console.log("configData0000000", configData);

  // const convertToTimeZone = (gmtTimeStr, timeZone) => {
  //   const gmtDate = new Date(gmtTimeStr);
  //   const options = {
  //     timeZone: timeZone,
  //     year: "numeric",
  //     month: "short",
  //     day: "numeric",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //     hour12: false,
  //   };
  //   const localizedDateStr = gmtDate.toLocaleString("en-US", options);
  //   const [datePart, timePart] = localizedDateStr.split(", ")[1].split(" ");
  //   const [time] = localizedDateStr.split(", ")[2].split(" ");
  //   return {
  //     date: `${datePart}, ${gmtDate.getFullYear()}`,
  //     time: time,
  //   };
  // };

  // useEffect(() => {
  //   if (schedule) {
  //     // const { date, time } = convertToTimeZone(schedule, timezone);
  //     setDeliveryDate(scheduleEditData?.schedule);
  //     //  setDeliveryHour(time);
  //   }
  // }, [schedule, timezone]);
  console.log("deliveryDate", deliveryDate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      schedule: "",
      // send_type: "",
      timezone: timezone,
    };

    if (deliveryTime === "now") {
      payload.schedule = formatDateWithoutMillisAndZ(new Date());
      // payload.send_type = "1";
    } else if (deliveryTime === "later") {
      payload.schedule = `${deliveryDate}T${deliveryHour}:00`;
      // payload.send_type = "2";
    }

    try {
      const response = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/updateSchedule/${userID}/${scheduleEditData?._id}/${scheduleEditData?.configuration_id}`,
        {
          method: "PUT",
          body: JSON?.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      if (responseData?.status == "true") {
        toast.success(responseData?.message);
        handleClose();
      } else {
        toast.error(responseData?.message);
      }
    } catch (error) {
      console.log(error, "error on sheduledit model");
    }
  };

  const handleClose = () => {
    dispatch(setSelectedReceviers());
    dispatch(setSelectedSenders());
    dispatch(setSelectedConfig());
    dispatch(setPopup({ modalType: "", showModal: false }));
  };

  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose}>
        <div className="mt-4">
          <Row>
            <Col xl={10} className="ps-4">
              <h3 className="popTitle my-4">
                <FaListCheck className="me-2" /> Edit Your Schedule
              </h3>
              <div className="">
                <form onSubmit={handleSubmit}>
                  <div className="d-flex gap-5 my-5"></div>
                  {deliveryTime === "later" && (
                    <>
                      <div className="row mb-5">
                        <div className="col-md-5">
                          <label htmlFor="timeZone" className="form-label">
                            Select Time zone:
                          </label>
                          <select
                            className="form-select"
                            id="timeZone"
                            value={timezone}
                            onChange={(e) => setTimeZone(e.target.value)}
                          >
                            <option value={"ist"}>
                              Indian Standard Time(IST)
                            </option>
                            <option value={"est"}>
                              Eastern Standard Time(EST)
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="row mb-5">
                        <div className="col-md-5">
                          <label htmlFor="deliveryDate" className="form-label">
                            Set specific delivery Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="deliveryDate"
                            value={deliveryDate}
                            onChange={(e) => setDeliveryDate(e.target.value)}
                          />
                        </div>
                        <div className="col-md-5">
                          <label htmlFor="deliveryHour" className="form-label">
                            Set specific delivery Time
                          </label>
                          <input
                            type="time"
                            className="form-control"
                            id="deliveryHour"
                            value={deliveryHour}
                            onChange={(e) => setDeliveryHour(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="d-flex justify-content-end btnBackNext mt-5">
                    <button className="btn buttonBack" onClick={handleClose}>
                      <FaAngleLeft className="me-1 mb-1" /> Cancel
                    </button>
                    <button type="submit" className="btn buttonNext">
                      Next <FaAngleRight className="ms-1 mb-1" />
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default SchduleEdit;
