import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { allApi } from "../../ApiWrapper";
import "./Agent.css";
const AgentServices = ({ onBack, onNext, setAgentDetails }) => {
  const [selectedOption, setSelectedOption] = useState({
    service: "",
    tool: "",
  });
  // const [addedOptions, setAddedOptions] = useState([]);
  const [agentServices, setAgentServices] = useState([]);
  const [agentTools, setAgentTools] = useState([]);

  useEffect(() => {
    async function fetchdata() {
      try {
        // const response = await apiClient.get("/services");
        const response = await allApi({
          url: `${process.env.REACT_APP_API_BACKEND_AGENT_URL}/services`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          formData: false,
        });
        setAgentServices(response?.data);
      } catch (err) {
        toast.error(err);
        console.log(err);
      }
    }
    fetchdata();
  }, []);
  console.log("agentServices", agentServices);
  const handleServiceChange = async (event) => {
    console.log("event.target.value", event.target.value);
    setSelectedOption({ service: event.target.value, tools: "" });
    setAgentTools([]);
    const service = agentServices.find((obj) => obj._id == event.target.value);
    console.log("service", service);
    try {
      for (const id of service.tool_ids) {
        const response = await allApi({
          url: `${process.env.REACT_APP_API_BACKEND_AGENT_URL}/tools/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          formData: false,
        });
        setAgentTools((tools) => [...tools, response?.data]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log("agentTools", agentTools);

  const handleToolChange = async (event) => {
    setSelectedOption({ ...selectedOption, tool: event.target.value });
  };

  const handleNext = () => {
    if (!selectedOption.service || !selectedOption.tool) {
      toast.warn("Please fill required fields");
      return;
    }
    const service = agentServices.find(
      (obj) => obj._id == selectedOption.service
    );
    const tool = agentTools.find((obj) => obj._id == selectedOption.tool);
    setAgentDetails(service, tool);
    onNext();
  };

  console.log("agentServices", agentServices);

  return (
    <div className="detail-container py-5">
      <h2>Services</h2>
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="inputDataSelect mb-5">
            <label htmlFor="services">Services</label>
            <select
              id="services"
              value={selectedOption.service}
              onChange={handleServiceChange}
            >
              <option value="">Select an option</option>
              {agentServices?.map((service) => (
                <>
                  {console.log("ser", service._id)}
                  <option key={service._id} value={service._id}>
                    {service.name}
                  </option>
                </>
              ))}
            </select>
          </div>
          <div className="inputDataSelect mb-5">
            <label htmlFor="tools">Tools</label>
            <select
              id="tools"
              value={selectedOption.tool}
              onChange={handleToolChange}
            >
              <option value="">Select an option</option>
              {agentTools.map((tool) => (
                <option key={tool?._id} value={tool?._id}>
                  {tool?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex justify-content-between">
            <button className="btn2 bt" onClick={() => onBack()}>
              Back
            </button>
            <button className="btn1 bt" onClick={handleNext}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentServices;
