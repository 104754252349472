import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";

import { Modal } from "react-bootstrap";
import {
  EmailPost,
  getEmailPayload,
  setPopup,
  setSaveReceiverData,
} from "../../../../Store/Action";
import {
  RECEIVER_UPLOAD,
  SAVE_RECEIVER_LIST,
} from "../../../../Store/Action/Type";

function UploadRecevier() {
  const dispatch = useDispatch();

  const popupReducer = useSelector((state) => state?.all);
  const { showModal } = popupReducer?.popupReducer?.modal || {};
  const UserId = useSelector((state) => state.all?.auth?.data?._id);

  //   const FetchApi = useCallback(async () => {
  //     dispatch(SubmitPost(RECEIVER_UPLOAD));
  //   }, [dispatch]);

  //   useEffect(() => {
  //     FetchApi();
  //   }, []);

  const [file, setFile] = useState({});

  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(URL.createObjectURL(selectedFile));
    }
  };

  // const submitHandler = (e) => {
  //   e.preventDefault();

  //   const files = fileInputRef.current?.files;
  //   console.log("Files from input:", files);

  //   // if (files && files.length > 0) {
  //   //   const selectedFile = files[0];

  //   //   const fd = new FormData();
  //   //   fd.append("file", selectedFile);
  //   //   dispatch(SubmitPost(`${RECEIVER_UPLOAD}?category=file`, fd));
  //   //   toast.success("Uploaders List Uploaded Successfully");
  //   //   navigate("/receiverlisting");
  //   // } else {
  //   //   toast.error("Please select a file before uploading.");
  //   // }
  // };

  const submitHandler = (e) => {
    e.preventDefault();

    const files = fileInputRef.current?.files;
    console.log("Files from input:", files);

    if (files && files.length > 0) {
      const selectedFile = files[0];

      const fetchReciverData = async () => {
        try {
          const fd = new FormData();
          fd.append("file", selectedFile);
          fd.append("User", UserId);

          const fetchrecivers = await dispatch(
            EmailPost(RECEIVER_UPLOAD, fd)
          ).then((data) => {
            console.log("data_RECEIVER_UPLOAD", data);
            if (data?.status === "true") {
              toast.success(data?.message);
              fetchReciverDataredux();
              handleClose();
              dispatch(setPopup({ modalType: "RECEVIER", showModal: true }));
            } else {
              toast.error(data?.error);
            }
          });

          // console.log("fetchrecivers", fetchrecivers);
          // if (fetchrecivers?.status === "true") {
          // } else {
          //   toast.error(fetchrecivers?.error);
          // }
        } catch (error) {
          console.error("Error fetching sender data 1111:", error);
          toast.error(error);
        }
      };
      fetchReciverData();
    } else {
      toast.error("Please select a file before uploading.");
    }
  };

  const fetchReciverDataredux = async () => {
    try {
      dispatch(getEmailPayload(`${SAVE_RECEIVER_LIST}?user_id=${UserId}`)).then(
        (data) => {
          console.log("reciveraddafdafdfagaf", data?.data);
          dispatch(setSaveReceiverData(data?.data));
        }
      );
    } catch (error) {
      console.log(error, "error in SAVE_RECEIVER_LIST ");
    }
  };

  const handleDownload12 = () => {
    const link = document.createElement("a");
    // link.href = "Images/sample_receivers.xlsx";
    // link.download = "receivers.xlsx";
    // link.href="https://us2.sourcesoftsolutions.com:8062/sender_files/new_receivers.csv";
    link.href = "https://www.dalaalstreet.com/Images/new_receiver.csv";
    link.download = "receivers.csv";

    link.click();
  };

  const handleDownload = async () => {
    let csvUrl =
      "https://us2.sourcesoftsolutions.com:8062/sender_files/new_receivers.csv";

    try {
      // Fetch the CSV file
      const response = await fetch(csvUrl);

      // Convert the response to a blob
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "new_receivers.csv");
      link.style.display = "none";

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger a click event on the link
      link.click();

      // Remove the link and revoke the URL after the download is initiated
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download CSV file:", error);
    }
  };

  const handleExcelDownload = async () => {
    let ExcelUrl =
      "https://us2.sourcesoftsolutions.com:8062/sender_files/receivers.xlsx";

    try {
      // Fetch the CSV file
      const response = await fetch(ExcelUrl);

      // Convert the response to a blob
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "receivers.xlsx");
      link.style.display = "none";

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger a click event on the link
      link.click();

      // Remove the link and revoke the URL after the download is initiated
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download CSV file:", error);
    }
  };
  const handleClose = () => {
    dispatch(setPopup({ modalType: "", showModal: false }));
  };
  const handleBack = () => {
    dispatch(setPopup({ modalType: "RECEVIER", showModal: false }));
    handleClose();
  };
  return (
    <>
      <Modal
        // show={setshow !== true ? show : false}
        show={showModal}
        onHide={handleClose}
        size="xl"
        className="emailPoup"
      >
        <div className="container mb-4">
          <div className="container-wrap mt-4">
            <div className="row justify-content-center align-items-stretch">
              <div className="col-md-8">
                <button className="m-4" onClick={handleDownload}>
                  Download CSV file
                </button>
                <button onClick={handleExcelDownload}>
                  Download Excel file
                </button>

                <div className="step_forms-wrap py-5 h-100">
                  <h3 className="title_1 text-center mb-4">Import Receivers</h3>
                  <form
                    onSubmit={(e) => {
                      submitHandler(e);
                    }}
                  >
                    <div className="form-group">
                      <div className="file-upload darkBordr">
                        <div className="image-upload-wrap text-center">
                          <input
                            ref={fileInputRef}
                            className="file-upload"
                            type="file"
                            accept=".csv"
                            onChange={handleChange}
                          />
                          <div className="drag-text">
                            <h3>
                              <span>Supported formats: CSV/Excel File</span>
                            </h3>
                            {/* <img src={file} alt="check" /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="hidden-button">
                      Upload
                    </button>
                  </form>
                  {/* <NavLink to={"/receiverlisting"}> */}
                  <button
                    type="button"
                    className="btn-primary me-2 mt-4 text-white"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  {/* </NavLink> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Modal>
    </>
  );
}

export default UploadRecevier;
