import React, { useState } from "react";
import { toast } from "react-toastify";
import { allApi } from "../../ApiWrapper";

const AgentPrompt = ({ agentDetails, onBack }) => {
  const [actionText, setActionText] = useState({
    agent_taks_prompt: "",
    user_prompt: "",
  });
  const [display, setDisplay] = useState("");

  const handleGenerate = async () => {
    if (!actionText.agent_taks_prompt || !actionText.user_prompt) {
      toast.warn("Please fill required fields");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("llm_factory", agentDetails.llm_details.llm_type);
      formData.append("agent_type", agentDetails.agent_type);
      formData.append("tool", agentDetails.agent_tool.value);
      formData.append("agent_task_prompt", actionText.agent_taks_prompt);
      formData.append("user_prompt", actionText.user_prompt);

      const response = await allApi({
        // url: `${process.env.REACT_APP_API_BACKEND_AGENT_URL}/create_agent`,
        url: "https://us2.sourcesoftsolutions.com:5006/create_agent",
        method: "POST",
        body: formData,
        formData: true,
      });
      console.log("response.response", response.response);
      setDisplay(response.response);
    } catch (error) {
      toast.error("Something went wrong please try again");
      console.error(error);
    }
  };

  return (
    <div className="detail-container">
      <h2>Agent Prompt</h2>
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div class="inputData mt-3">
            <input
              id="agent_prompt"
              type="text"
              value={actionText.agent_taks_prompt}
              onChange={(e) =>
                setActionText({
                  ...actionText,
                  agent_taks_prompt: e.target.value,
                })
              }
              required
              // placeholder="Agent Action"
            />
            <label htmlFor="agent_prompt">Agent Task Prompt</label>
            <div class="underline"></div>
          </div>
          <div class="inputData mt-5">
            <input
              id="user_prompt"
              type="text"
              value={actionText.user_prompt}
              onChange={(e) =>
                setActionText({ ...actionText, user_prompt: e.target.value })
              }
              required
              // placeholder="Enter Your action"
            />
            <label htmlFor="user_prompt">User Prompt</label>
            <div class="underline"></div>
          </div>
          
          <div className="d-flex justify-content-between mt-4">
            <button className="btn2 bt" onClick={() => onBack()}>Back</button>
            <button onClick={handleGenerate}>Generate</button>
          </div>
          <div className="display-area">{display}</div>

        </div>
      </div>

    </div>
  );
};

export default AgentPrompt;
