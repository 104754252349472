export const validateEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (!email) return "Email is required";
  if (!regex.test(email)) return "Invalid email address";
  return "";
};

export const validatePassword = (password) => {
  if (!password) return "Password is required";
  if (password.length < 6)
    return "Password should be at least 6 characters long";
  return "";
};

export const validateName = (name) => {
  if (!name) return "Name is required";
  if (name.length < 2) return "Name should be at least 2 characters long";
  return "";
};

export const validateMobile = (mobile) => {
  const regex = /^[0-9]{10}$/;
  if (!mobile) return "Mobile number is required";
  if (!regex.test(mobile)) return "Invalid mobile number";
  return "";
};

export const validateRegister = (data) => {
  return {
    firstName: validateName(data.firstName),
    lastName: validateName(data.lastName),
    email: validateEmail(data.email),
    mobile: validateMobile(data.mobile),
    password: validatePassword(data.password),
  };
};

export const validateLogin = (data) => {
  return {
    email: validateEmail(data.email),
    password: validatePassword(data.password),
  };
};

export const validateForgetEmail = (data) => {
  return {
    email: validateEmail(data.email),
  };
};

export const validateCountry = (country) => {
  if (!country) return "Country is required";
  return "";
};

export const validateState = (state) => {
  if (!state) return "State is required";
  return "";
};

export const validateCity = (city) => {
  if (!city) return "City is required";
  return "";
};

export const validateZipCode = (zipCode) => {
  // const regex = /^[0-9]{6}$/;
  if (!zipCode) return "Zip Code is required";
  // if (!regex.test(zipCode)) return "Invalid Zip Code";
  return "";
};

export const validateMessage = (message) => {
  if (!message) return "Message is required";
  if (message.length < 10)
    return "Message should be at least 10 characters long";
  return "";
};

export const validateModalFields = (data) => {
  return {
    country: validateCountry(data.country),
    state: validateState(data.state),
    city: validateCity(data.city),
    zipCode: validateZipCode(data.zipCode),
    message: validateMessage(data.message),
  };
};
