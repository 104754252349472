import React from "react";
import ImportReceiver from "./ImportReceiver";

function Receiver() {
  return (
    <>
      <ImportReceiver />
    </>
  );
}

export default Receiver;
