import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { SubmitPost } from "../../../../../Store/Action/";
import NavLink from "../routes/NavLink";
import { ToastContainer, toast } from "react-toastify";
import { RECEIVER_UPLOAD } from "../../../../../Store/Action/Type";
import { useNavigate } from "react-router-dom";

function ImportReceiver() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const FetchApi = useCallback(async () => {
    dispatch(SubmitPost(RECEIVER_UPLOAD));
  }, [dispatch]);

  useEffect(() => {
    FetchApi();
  }, []);

  const [file, setFile] = useState({});

  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(URL.createObjectURL(selectedFile));
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const files = fileInputRef.current?.files;
    console.log("Files from input:", files);

    if (files && files.length > 0) {
      const selectedFile = files[0];

      const fd = new FormData();
      fd.append("file", selectedFile);
      dispatch(SubmitPost(`${RECEIVER_UPLOAD}?category=file`, fd));
      toast.success("Uploaders List Uploaded Successfully");
      navigate("/receiverlisting");
    } else {
      toast.error("Please select a file before uploading.");
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = 'Images/receivcreds.csv'; 
    link.download = 'receivcreds.csv'; 
    link.click();
  };
  return (
    <div>
    
      <div className="container mb-4">
        <div className="container-wrap mt-4">
          <div className="row justify-content-center align-items-stretch">
            <div className="col-md-8">
            <button onClick={handleDownload}>Download sample file</button>

              <div className="step_forms-wrap py-5 h-100">
                <h3 className="title_1 text-center mb-4">Import Receivers</h3>
                <form
                  onSubmit={(e) => {
                    submitHandler(e);
                  }}
                >
                                
              
                  <div className="form-group">
                    <div className="file-upload darkBordr">
                      <div className="image-upload-wrap text-center">
                        <input
                          ref={fileInputRef}
                          className="file-upload"
                          type="file"
                          accept=".csv"
                          onChange={handleChange}
                        />
                        <div className="drag-text">
                          <h3>
                            <span>Supported formats: CSV/Excel File</span>
                          </h3>
                          {/* <img src={file} alt="check" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="hidden-button">
                    Upload
                  </button>
                </form>
                <NavLink to={"/receiverlisting"}>
                  <button
                    type="button"
                    className="btn-primary me-2 mt-4 text-white"
                  >
                    Back
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ImportReceiver;
