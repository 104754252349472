import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";

const SaveContentImageListing = ({ activeTab }) => {
  console.log("activeTab", activeTab);
  const [active, setActive] = useState(1);
  const [generatedContent, setGeneratedContent] = useState([]);
  const userID = useSelector((state) => state?.all?.auth?.data?._id);

  const handleClick = (index) => {
    setActive(index);
  };

  const get_content_detail = async () => {
    try {
      const resp = await axios.get(
        // `https://us2.sourcesoftsolutions.com:8072/get-content?user_id=${userID}`,
        `https://us2.sourcesoftsolutions.com:8062/get-content?user_id=${userID}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("savecontent", resp?.data?.data);
      setGeneratedContent(resp?.data?.data);
    } catch (error) {
      console.error("Error while making API call:", error);
    }
  };

  useEffect(() => {
    get_content_detail();
  }, [activeTab]);

  return (
    <div>
      <section className="py-5">
        <Container>
          <Row>
            <Col lg={9}>
              <aside className="py-5" id="Authentication">
                <div className="titleId">
                  <h2 className="m-0">Content History</h2>
                </div>
                <div className="titleId">
                  {/* <h2 className="m-0">Filter</h2> */}
                  <input type="text"></input>
                  <Button className="me-4">Filter</Button>
                </div>
                <Accordion className="docAccordian" defaultActiveKey="0" flush>
                  {generatedContent?.map((ele, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header>{ele?.prompt}</Accordion.Header>
                      <Accordion.Body>
                        <ul className="aocList">
                          {ele?.generated_content?.map((content, idx) => (
                            <li key={idx}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: content.replace(/\n/g, "<br>"),
                                }}
                              ></span>
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </aside>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default SaveContentImageListing;
