import React, { useState } from "react";
import { ProgressBar } from "react-loader-spinner";
import { toast } from "react-toastify";
import { API } from "../../ApiWrapper";

function getFaviconUrl(domain, size = 32) {
  const googleFaviconServiceUrl = "https://www.google.com/s2/favicons";
  return `${googleFaviconServiceUrl}?domain=${domain}&sz=${size}`;
}

function getDomainFromUrl(url) {
  const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/\n]+)/i;
  const matches = url.match(regex);
  return matches && matches[1];
}

function isYouTubeUrl(url) {
  const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/i;
  return pattern.test(url);
}

const UrlsUploader = ({ onDataSources, close }) => {
  // const [urls, setUrls] = useState([
  //   {
  //     _id: "",
  //     img: "",
  //     name: "",
  //     data: "",
  //     title: "",
  //     desc: "",
  //   },
  // ]);
  const [urls, setUrls] = useState([""]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      let filteredUrls = urls.map((url, index) => {
        if (isYouTubeUrl(url)) {
          console.log(isYouTubeUrl(url));
          return {
            _id: index,
            title: getDomainFromUrl(url),
            type: "URL/youtube",
            data: url,
            img: getFaviconUrl(url),
            desc: `This is data source of provided URL is: ${getDomainFromUrl(
              url
            )}`,
          };
        }
        return {
          _id: index,
          title: getDomainFromUrl(url),
          img: getFaviconUrl(url),
          type: "URL/news",
          data: url,
          desc: `This is data source of provided URL is: ${getDomainFromUrl(
            url
          )}`,
        };
      });
      filteredUrls = filteredUrls.filter((url) => url.data !== "");
      console.log(filteredUrls);

      if (filteredUrls == 0) return;

      const payload = isYouTubeUrl(filteredUrls[0].data)
        ? {
            url: "/youtube_url",
            method: "POST",
            body: {
              url: filteredUrls.map((url) => url.data),
            },
            formData: false,
          }
        : {
            url: "/article_urls",
            method: "POST",
            body: {
              urls: filteredUrls.map((url) => url.data),
            },
            formData: false,
          };
      const response = await API(payload);

      toast.success("Your URL is processed successfully!");
      const fileUrl = [];
      filteredUrls.map((url) => {
        fileUrl.push({
          file_path: `${url.title}.${
            url.type == "URL/news" ? "news" : "youtube"
          }`,
          filename: `${url.title}`,
        });
      });
      console.log("fileUrl: ", fileUrl);
      onDataSources(fileUrl);
      close();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.warning("Please try again");
      console.log(err);
    }
  };

  const handleAddUrl = () => {
    setUrls([...urls, ""]);
    // setUrls([
    //   ...urls,
    //   {
    //     _id: "",
    //     img: "",
    //     name: "",
    //     data: "",
    //     title: "",
    //     desc: "",
    //   },
    // ]);
  };

  const handleDeleteUrl = (index) => {
    const updatedUrls = urls.filter((_, i) => i !== index);
    setUrls(updatedUrls);
  };

  const handleUrlChange = (index, value) => {
    // const updatedUrls = [...urls];
    // updatedUrls[index] = {
    //   _id: index,
    //   img: getFaviconUrl(value),
    //   name: getDomainFromUrl(value),
    //   data: value,
    //   title: "",
    //   desc: `This is data source of provided URL${getDomainFromUrl(value)}`,
    // };
    const updatedUrls = [...urls];
    updatedUrls[index] = value;
    setUrls(updatedUrls);
  };

  return (
    <>
      <div className="add-url">
        <h5>Add your URLs here</h5>
        <button className="add-button" onClick={handleAddUrl}>
          Add URL
        </button>
      </div>
      <div className="url-container">
        {urls.map((url, index) => (
          <div key={index} className="url-add">
            <h5>{index + 1 + "."}</h5>
            <input
              className="url-input"
              type="text"
              value={url.data}
              placeholder="Enter url i.e. www.google.com"
              onChange={(e) => handleUrlChange(index, e.target.value)}
            />
            {index > 0 && (
              <button
                className="delete-button"
                onClick={() => handleDeleteUrl(index)}
              >
                Delete
              </button>
            )}
          </div>
        ))}
      </div>
      <div className="file-submit">
        {isLoading ? (
          <div className="file-loading-bar">
            <ProgressBar
              height={40}
              width={40}
              barColor="#000"
              borderColor="#000"
            />
            <h6>Processing</h6>
          </div>
        ) : (
          <button onClick={handleSubmit}>Submit</button>
        )}
      </div>
    </>
  );
};

export default UrlsUploader;
