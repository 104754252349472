import { useRef, useState } from "react";
import FilesUploader from "../FilesUploader";
import UrlsUploader from "../UrlsUploader";
import DbConnector from "../DbConnector";

const AddDataModal = ({ isOpen, close, onDataSources, update }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    {
      title: "Add Files",
      content: (
        <FilesUploader
          close={close}
          onDataSources={onDataSources}
          update={update}
        />
      ),
    },
    {
      title: "URLs",
      content: <UrlsUploader close={close} onDataSources={onDataSources} />,
    },
    {
      title: "DB Servers",
      content: <DbConnector close={close} onDataSources={onDataSources} />,
    },
  ];
  const overlayRef = useRef(null);

  const handleOverlayClick = (e) => {
    if (e.target === overlayRef.current) {
      close();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="modal-overlay"
      ref={overlayRef}
      onClick={handleOverlayClick}
    >
      <div className="modal-add">
        <div className="modal-header-container">
          <button onClick={close} className="close-button">
            ×
          </button>
        </div>
        <div className="modal-body">
          <div className="sidebar-container">
            <div className="modal-sidebar">
              {tabs.map((tab, index) => (
                <button
                  key={index}
                  className={`sidebar-tab ${
                    activeTab === index ? "active" : ""
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {tab.title}
                </button>
              ))}
            </div>
            <div className="modal-content">{tabs[activeTab].content}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDataModal;
