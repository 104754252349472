import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal } from "react-bootstrap";
import {
  EmailPost,
  getEmailPayload,
  setPopup,
  setSaveSenderData,
} from "../../../../Store/Action";
import { SAVE_SENDER_LIST, SENDER_UPLOAD } from "../../../../Store/Action/Type";

function CreateConfigSender() {
  const [file, setFile] = useState({});
  const [logicalName, setLogicalName] = useState("");
  const UserId = useSelector((state) => state.all?.auth?.data?._id);
  const [senderlist, setsenderlist] = useState([]);
  const SenderData = useSelector((state) => state?.all?.SENDER_LIST);
  const [filtervalue, setFilterValue] = useState("");
  const popupReducer = useSelector((state) => state?.all);
  const { showModal } = popupReducer?.popupReducer?.modal || {};
  const dispatch = useDispatch();

  useEffect(() => {
    fetchSenderDataredux();
  }, []);
  useEffect(() => {
    setsenderlist(SenderData);
  }, [SenderData]);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(URL.createObjectURL(selectedFile));
    }
  };
  const fileInputRef = useRef(null);

  const submitHandler = async (e) => {
    e.preventDefault();

    const files = fileInputRef.current?.files;
    console.log("Files from input:", files);

    if (files && files.length > 0) {
      const selectedFile = files[0];

      const fd = new FormData();
      fd.append("file", selectedFile);
      fd.append("User", UserId);
      fd.append("logical_name", logicalName);
      const fetchSenderData = async () => {
        try {
          const fetchsender = await dispatch(EmailPost(SENDER_UPLOAD, fd));
          console.log("fetchsender", fetchsender);
          if (fetchsender?.status === "true") {
            toast.success(fetchsender?.data_summary);
            toast.success(fetchsender?.message);
            // navigate("/senderlisting");
            fetchSenderDataredux();
            handleClose();
          } else {
            toast.error(fetchsender?.message);
            handleClose();
          }
        } catch (error) {
          console.error("Error fetching sender data 1111:", error);
          toast.error(error);
        }
      };

      fetchSenderData();

      // console.log("response", response);
      // if (response?.error?.length > 0) {
      //   toast.success(response?.data_summary);
      //   navigate("/senderlisting");
      // } else {
      // toast.error(response?.message);
      // }
    } else {
      toast.error("Please select a file before uploading.");
    }
  };

  const fetchSenderDataredux = async () => {
    try {
      dispatch(getEmailPayload(`${SAVE_SENDER_LIST}?user_id=${UserId}`)).then(
        (data) => {
          dispatch(setSaveSenderData(data?.data));
          console.log("senderdararrararrarar", data?.data);
        }
      );
    } catch (error) {
      console.log(error, "error in SAVE_SENDER_LIST ");
    }
  };
  console.log("senderlist?????", senderlist);

  const handleDownload = async () => {
    let csvUrl =
      "https://us2.sourcesoftsolutions.com:8062/sender_files/new_senders.csv";

    try {
      // Fetch the CSV file
      const response = await fetch(csvUrl);

      // Convert the response to a blob
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "new_senders.csv");

      link.style.display = "none";

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger a click event on the link
      link.click();

      // Remove the link and revoke the URL after the download is initiated
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download CSV file:", error);
    }
  };

  const handleDownloadExcel = async () => {
    let ExcelUrl =
      "https://us2.sourcesoftsolutions.com:8062/sender_files/Senders.xlsx";

    try {
      // Fetch the CSV file
      const response = await fetch(ExcelUrl);

      // Convert the response to a blob
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Senders.xlsx");
      link.style.display = "none";

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger a click event on the link
      link.click();

      // Remove the link and revoke the URL after the download is initiated
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download CSV file:", error);
    }
  };

  console.log("senderlist", senderlist);
  const handleClose = () => {
    dispatch(setPopup({ modalType: "", showModal: false }));
  };

  return (
    <>
      <Modal
        // show={setshow !== true ? show : false}
        show={showModal}
        onHide={handleClose}
        size="xl"
        className="emailPoup"
      >
        {/* <div className="main-datasources-container">
          <div className="heading-button">
            <h3>My Files</h3>
          </div> */}

        <div className="emailPoup">
          <div className="container mb-4">
            <div className="container-wrap mt-4">
              <div className="row justify-content-center align-items-stretch">
                <div className="col-md-8">
                  <div className="mb-4 gap-4 d-flex">
                    <button onClick={handleDownload}>
                      Download Sample CSV file
                    </button>
                    <button onClick={handleDownloadExcel}>
                      Download Sample Excel file
                    </button>
                  </div>

                  <div className="step_forms-wrap">
                    <h3 className="title_1 text-center mb-4">Import Senders</h3>
                    <form
                      onSubmit={(e) => {
                        submitHandler(e);
                      }}
                    >
                      <div className="form-group mb-3">
                        <label>Logical Name</label>
                        <input
                          type="text"
                          className="form-control border border-success-subtle"
                          placeholder="Logical Name"
                          value={logicalName}
                          onChange={(e) => setLogicalName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <div className="file-upload darkBordr">
                          <div className="image-upload-wrap text-center">
                            <input
                              ref={fileInputRef}
                              className="file-upload"
                              type="file"
                              name="file"
                              accept=".csv"
                              onChange={handleChange}
                            />
                            <div className="drag-text">
                              <h3>
                                <span>Supported formats: CSV/Excel File</span>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button type="submit" className="hidden-button mt-4">
                        Upload
                      </button>
                    </form>
                    {/* <button type="button" className="btn-primary me-2 mt-4 text-white">Back</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
        {/* </div> */}
      </Modal>
    </>
  );
}

export default CreateConfigSender;
