import React, { useState,useEffect } from "react";
// import apiURL from "./Store/Action/api-url";
import { allApi } from "../../ApiWrapper";

import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import TextareaAutosize from 'react-textarea-autosize';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


import { useDispatch, useSelector } from 'react-redux';
import CkEditiorListing from "./CkEditiorListing";


const Contentgenrator = ({selectedTitle}) => {
   const [inputValue, setInputValue] = useState(selectedTitle);

   const [selectedTool, setSelectedTool] = useState("");
   const [selectedModel, setSelectedModel] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [answer,setanswer]=useState();
   const [urls,seturls]=useState();
   const [image,setImage]=useState(false);
   const [content,setContent]=useState(false);

   const[data,setdata]=useState(false);

   const[edit,setIsedit]=useState(false);
   const extractUrls = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = text.match(urlRegex);
    seturls(urls);
    return urls;
  };

  
  const userID = useSelector((state) => state?.user?.data?._id);
  const navigate=useNavigate();


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleToolChange = (event) => {
    setSelectedTool(event.target.value);
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  const handleSubmit =async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setContent(true);

    try {
        await allApi({
  
         url: `${process.env.REACT_APP_API_BACKEND_OTHER_URL}/content`,          
          // url:'http://192.168.2.171:2000/content',
          method: "POST",
            body: {
      
             user_input: inputValue,
            // user_input:"Latest AI Trands",
            llm_model_name:"gpt-4-1106-preview",
            recursion_limit:100,

            },
            headers: {
                'Content-Type': 'application/json'
            },
  
            formData: false,
        }).then((data) => {
          console.log(data?.message,"geteekamam")

          setanswer(data?.response);
          extractUrls(data?.response);
          setdata(true);

          if(data?.status==true)
          {
            setIsLoading(false);
            toast.success(data?.message);
          }
            toast.error(data?.error);

        });
    } catch (error) {
    } finally {
        setIsLoading(false);

    }
  };



  // const postFacebook = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true)
  
  //   try {
  //     let resp;
  
  //     const encodedMessage = encodeURIComponent(answer);

  //       resp = await allApi({
  //         url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.post_facebook}?` +
  //           `access_token=EAAF6Edo7an4BOx88zACXwZA1uHFTyGEmB3MO0Kyzy3trnK31v6UTxmAaYUS3Usfvm5hLvmebKyRCMEQ9o5PBJYfTC4gpoZBJ4ZA9YSlgGHRR2ANIXkGKsjLD9wmtjyqcaGZA3ob0ig2kkWF97cKZAEYmQAeqxEvoEnFECa7MfOejWImXyjHhSArBCEpjurzfjYhgMenDGSZB9iOyhJWHimWnLr`+
  //            // `&message=${answer}`,
  //             `&message=${encodedMessage}`+
  //             //  `&url=${urls[0]}`,
  //              `&url=https://www.bdtask.com/blog/uploads/software-development-life-cycle.jpg`,
  //           // +
  //           // `&published=true` +
  //          // `&url=${imageUrl}`,
           
  //         method: "POST",
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         formData: false,
  //       });
  
      
      
  //     // console.log(resp, "postfacebook from data");
  
  //     setIsLoading(false)
  //   } catch (error) {
  //     console.error("Error while making API call:", error);
  //     setIsLoading(false)
  //   }
  // }


  const handlegenrateImage=async()=>{

    setIsLoading(true);
    setImage(true)

    try {
        await allApi({
          //  url:'http://192.168.2.171:2000/generate-images',
           url: `${process.env.REACT_APP_API_BACKEND_OTHER_URL}/generate-images`,

          method: "POST",
            body: {
             user_input: inputValue,
            llm_model_name:"gpt-4-1106-preview",
            recursion_limit:100,

            },
            headers: {
                'Content-Type': 'application/json'
            },
  
            formData: false,
        }).then((data) => {
             console.log(data,"imagedata")
          setanswer(data?.response);
          extractUrls(data?.response);

          if(data?.status)
          {
            setIsLoading(false);

              
              toast.success(data?.message);
          }
            toast.error(data?.error);
        });
    } catch (error) {
    } finally {
        setIsLoading(false);

    }

  }


  return (
    <>
    {data && data === true ? (
  <>
    <CkEditiorListing
    contentdata={answer}
    setcontentdata ={setanswer}
                
    
    />
  </>
) : (
  <>
  <div className="container-fluid min-vh-100 ps-3">
            <div className="mainBox themeInput pt-5">
        <div className="row justify-content-center pt-5">
          <div className="col-lg-7">
              <h1 className="text-center">Enter Prompt </h1>
              <div className="form-group mt-4">
                  <label htmlFor="prompt">Enter prompt:</label>
                  <TextareaAutosize
                      className="form-control mt-2"
                      placeholder="Enter your input"
                      value={inputValue}
                      onChange={handleInputChange}
                  />
              </div>

              <div className="d-flex justify-content-between twoBothBtn my-5 ">
                <button
                  type="submit"
                  className="btn cancel"
                  onClick={handleSubmit}
                >Genrate Content</button>

                <button
                    type="submit"
                    className="btn btnSuccess"
                    onClick={handlegenrateImage}
                  >Genrate Image</button>
                

                <button className="btn cancel">Genrate Video</button>

              </div>
             
              {isLoading ? (
                      <Oval
                        visible={true}
                        height="50"
                        width="50"
                        color="#4fa94d"
                        ariaLabel="oval-loading"
                        wrapperStyle={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    ) : (
                      ""
                    )}

              
            </div>
            
          </div>
         
         
        </div>


      </div>




  </>
      

                    )}

    </>
  );
};

export default Contentgenrator;
