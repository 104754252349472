import axios from "axios";
import { BASECONFIG } from "../Config";
import { BASELLM } from "../Config";

export const getDataApi = async (
  URL,
  params = {},
  token,
  baseUrl = process.env.REACT_APP_BACKEND_URL
) => {
  const headers = {
    Authorization: `${token}`,
    "Content-Type": "application/json",
  };

  return await axios.get(`${baseUrl}/${URL}`, {
    headers: headers,
    params: params,
  });
};

export const postDataApi = async (
  URL,
  data = {},
  baseUrl = process.env.REACT_APP_BACKEND_URL
) => {
  let headers = {};
  if (!(data instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }
  return await axios.post(`${baseUrl}/${URL}`, data, {
    headers: headers,
  });
};

export const putDataApi = async (
  URL,
  data = {},
  baseUrl = process.env.REACT_APP_BACKEND_URL
) => {
  const headers = {};
  return await axios.put(`${baseUrl}/${URL}`, data, {
    headers: headers,
  });
};

export const DeleteDataApi = async (
  URL,
  data = {},
  baseUrl = process.env.REACT_APP_BACKEND_URL
) => {
  const headers = {};
  return await axios.delete(`${baseUrl}/${URL}`, {
    headers: headers,
    data,
  });
};
export const makeSearchString = (filter) => {
  const searchParams = new URLSearchParams();
  for (const key in filter) {
    if (filter[key]) {
      searchParams.append(key, filter[key]);
    }
  }
  return searchParams.toString();
};

export const postEmail = async (
  URL,
  data = {},
  baseUrl = process.env.REACT_APP_BACKEND_NEW_APP
) => {
  let headers = {};
  if (!(data instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }
  return await axios.post(`${baseUrl}/${URL}`, data, {
    headers: headers,
  });
};

export const getEMAILApiUrl = async (
  URL,
  params = {},
  token,
  baseUrl = process.env.REACT_APP_BACKEND_NEW_APP
) => {
  const headers = {
    Authorization: `${token}`,
    "Content-Type": "application/json",
  };

  return await axios.get(`${baseUrl}/${URL}`, {
    headers: headers,
    params: params,
  });
};

export const allApi = async ({
  body,
  headers = {},
  method,
  signal,
  url,
  formData = false,
}) => {
  // headers['Access-Control-Allow-Origin'] = '*';
  // console.log({ formData });
  console.log("url", url);
  try {
    return await fetch(url, {
      method,
      headers,
      body: body ? (formData ? body : JSON.stringify(body)) : null,
      signal,
    })
      .then((response) => {
        if (response.url.includes("X-Amz-Credential")) {
          return true;
        }
        return response.clone().json();
      })
      .then((data) => {
        console.log({ data });
        return data;
      });
  } catch (error) {
    throw Error(error);
  }
};

export const API = async ({
  body,
  headers = {},
  method,
  signal,
  url,
  formData = false,
}) => {
  // const token =localStorage.getItem("token");

  // headers["Authorization"] = `${token}`;
  if (!formData) {
    headers["content-type"] = "application/json";
  }
  try {
    const response = await fetch(BASELLM.BASE_LLM_URL + url, {
      method,
      headers,
      body: body ? (formData ? body : JSON.stringify(body)) : null,
      signal,
    });
    if (!response.ok) {
      throw await response.clone().json();
    }

    return response.clone().json();
  } catch (error) {
    console.log("error", error);
    // throw error;
    // toast.error(error);
  }
};
