import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import NavLink from "../routes/NavLink";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPayload } from "../../../../../Store/Action";
import { SubmitPost } from "../../../../../Store/Action/index";
import { SENDER_DATA, SENDER_UPLOAD } from "../../../../../Store/Action/Type";
import { useNavigate } from "react-router-dom";

function ImportSender() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const FetchApi = useCallback(async () => {
    dispatch(SubmitPost(SENDER_UPLOAD));
    dispatch(getPayload(SENDER_DATA));
  }, [dispatch]);

  useEffect(() => {
    FetchApi();
  }, []);

  const [file, setFile] = useState({});

  console.log(file, "filedata");
  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(URL.createObjectURL(selectedFile));
    }
  };

  const fileInputRef = useRef(null);

  const submitHandler = async (e) => {
    e.preventDefault();

    const files = fileInputRef.current?.files;
    console.log("Files from input:", files);

    if (files && files.length > 0) {
      const selectedFile = files[0];

      const fd = new FormData();
      fd.append("file", selectedFile);
      fd.append("User", "User1");
      const fetchSenderData = async () => {
        try {
          const fetchsender = await dispatch(SubmitPost(SENDER_UPLOAD, fd));
          console.log("fetchsender", fetchsender);
          if (fetchsender?.error) {
            toast.error(fetchsender?.message);
          } else {
            toast.success(fetchsender?.data_summary);
            navigate("/senderlisting");
          }
        } catch (error) {
          console.error("Error fetching sender data:", error);
        }
      };

      fetchSenderData();

      // console.log("response", response);
      // if (response?.error?.length > 0) {
      //   toast.success(response?.data_summary);
      //   navigate("/senderlisting");
      // } else {
      // toast.error(response?.message);
      // }
    } else {
      toast.error("Please select a file before uploading.");
    }
  };

  const SenderList = (e) => {
    dispatch(getPayload(SENDER_DATA));
  };
  const handleDownload = () => {
    // Example for static PDF download
    const link = document.createElement("a");
    link.href = "Images/sender_list.csv"; // Replace with your PDF path
    link.download = "sender.csv"; // Optional: specify a download name
    link.click();
  };
  return (
    <div>
      <div className="container mb-4">
        <div className="container-wrap mt-4">
          <div className="row justify-content-center align-items-stretch">
            <div className="col-md-8">
              <button onClick={handleDownload}>Download sample file</button>
              <div className="step_forms-wrap py-5 h-100">
                <h3 className="title_1 text-center mb-4">Import Senders</h3>
                <form
                  className=""
                  action=""
                  method=""
                  onSubmit={(e) => {
                    submitHandler(e);
                  }}
                >
                  <div className="form-group">
                    <div className="file-upload darkBordr">
                      <div className="image-upload-wrap text-center">
                        <input
                          ref={fileInputRef}
                          className="file-upload"
                          type="file"
                          name="file"
                          accept=".csv"
                          onChange={handleChange}
                        />
                        <div className="drag-text">
                          <h3>
                            <span>Supported formats: CSV/Excel File</span>
                          </h3>
                          {/* <img src={file} alt="check" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="hidden-button">
                    Upload
                  </button>
                </form>
                <NavLink to={"/senderlisting"}>
                  <button
                    type="button"
                    className="btn-primary me-2 mt-4 text-white"
                  >
                    Back{" "}
                  </button>
                </NavLink>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default ImportSender;
