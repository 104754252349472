// import ClassicEditor from "../custom/build/ckeditor";
import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "../custom/ckeditor5-custom-build/build/ckeditor";
import ClassicEditor from "./custom/ckeditor5-custom-build/build/ckeditor";

import { useDispatch } from "react-redux";

import { Dropdown, Menu, Upload } from "antd";
import {
  CopyOutlined,
  FacebookFilled,
  InstagramFilled,
  ReloadOutlined,
  ShareAltOutlined,
  YoutubeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { BsStars } from "react-icons/bs";
import RewriteModal from "./RewriteModal";
import GenerateModal from "./GenerateContentModal";
// import apiURL from "./Store/Action/api-url";
import { allApi } from "../../ApiWrapper";

import { Oval } from "react-loader-spinner";
import GenerateContentModal from "./GenerateContentModal";
import GenrateImageModal from "./GenrateImageModal";
import axios from "axios";
import { useSelector } from "react-redux";
import AddMoreGenerateContentModal from "./AddMoreGenerateContentModal";
import { toast } from "react-toastify";
// import { SetpopupReducerData } from "./Store/reducer/PopupReducer";
export const formatContent = (content) => {
  const segments = content
    .split("\n")
    .filter((segment) => segment.trim() !== "");

  return segments.map((text) => `<p>${text}</p>`).join("");
};

const CkEditiorListing_for_dashboard = ({ contentdata }) => {
  const [changedBlocks, setChangeBlocks] = useState({
    editor: null,
    range: null,
    originalText: "",
    rewriteText: "",
  });

  const userID = useSelector((state) => state?.all?.auth?.data?._id);

  const [pageData, setpageData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInsta, setIsLoadingInsta] = useState(false);
  const [dropdownContent, setDropDownContent] = useState("menu");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [Block, setBlock] = useState();
  const [Imageurl, setImageUrl] = useState();
  const [Title, setTitle] = useState("");
  // var initialContent = contentdata || "";
  const [initialContent, setInitialContent] = useState(contentdata || "");

  // console.log(contentdata,"0000initialContent")
  const [newGenerateContent, setNewGenerateContent] = useState("");
  const [newAddMoreContent, setNewAddMoreGenerateContent] = useState("");
  const [userInput, setUserInput] = useState("");

  const [showReGenerate, setShowReGenerate] = useState(false);

  const [NewImageurl, setNewImageurl] = useState();

  const [ReGeneratecontentBlocks, setReGeneratecontentBlocks] = useState();
  const [contentBlocks, setContentBlocks] = useState([]);

  useEffect(() => {
    setInitialContent(contentdata || "");
  }, [contentdata]);

  useEffect(() => {
    let strippedInitialContent = "";

    if (initialContent?.length > 0) {
      strippedInitialContent = initialContent?.replace(/<\/?[^>]+(>|$)/g, "");

      setContentBlocks(() => [strippedInitialContent + newAddMoreContent]);
      setInitialContent("");
    } else {
      //  setContentBlocks((prev) => [prev + newAddMoreContent + imagesMarkup]);
      setContentBlocks((prev) => [prev + newAddMoreContent]);
    }

    showReGenerate && showReGenerate == true
      ? setReGeneratecontentBlocks(newGenerateContent)
      : setReGeneratecontentBlocks();
    setNewAddMoreGenerateContent("");
    setNewImageurl("");
  }, [initialContent, newAddMoreContent, NewImageurl, newGenerateContent]);

  useEffect(() => {}, [contentBlocks]);

  // const handleChange = (event, editor, index) => {
  //   const data = editor.getData();
  //   const updatedContentBlocks = [...contentBlocks];
  //   updatedContentBlocks[index] = data;

  //   console.log(updatedContentBlocks,"updatedContentBlocks")

  //   const updatedDataWithInlineStyles = updatedContentBlocks.map((block) =>
  //     block.replace(/<figure class="image"[^>]*style="([^"]*)"[^>]*>\s*<img([^>]*)src="([^"]*)"([^>]*)>/g,
  //     (match, figureStyle, imgAttributes, src, imgStyle) => {
  //       const widthMatch = figureStyle.match(/width:\s*(\d+)%/);
  //       const width = widthMatch ? widthMatch[1] : '100';
  //       return `<img src="${src}" style="width:${width}%;${imgAttributes}" />`;
  //     })
  //   );
  //   // setContentBlocks((prev) => [data]);
  //       // setContentBlocks((prev) => [updatedDataWithInlineStyles]);

  //   setdata(updatedDataWithInlineStyles);
  // };

  const handleChange = (event, editor, index) => {
    const data = editor.getData();
    const updatedContentBlocks = [...contentBlocks];
    updatedContentBlocks[index] = data;

    console.log(updatedContentBlocks, "updatedContentBlocks");

    const updatedDataWithInlineStyles = updatedContentBlocks?.map((block) =>
      block.replace(
        /<figure class="image.*?" style="(.*?)">.*?<img(.*?)src="(.*?)"(.*?)>/g,
        (match, figureStyle, imgAttributes, src, imgExtraAttributes) => {
          const widthMatch = figureStyle.match(/width:(\d+)%/);
          const width = widthMatch ? widthMatch[1] : "100";

          const dimensionMatches = imgExtraAttributes.match(
            /width="(\d+)"\s+height="(\d+)"/
          );
          const originalWidth = dimensionMatches ? dimensionMatches[1] : "auto";
          const originalHeight = dimensionMatches
            ? dimensionMatches[2]
            : "auto";

          return `<img src="${src}" style="width:${width}%;height:auto;" width="${originalWidth}" height="${originalHeight}" />`;
        }
      )
    );

    setContentBlocks((prev) => [data]);

    // setdata(updatedDataWithInlineStyles);
  };

  const handleBlur = (event, editor) => {
    console.log("Blur.", editor);
  };

  const handleFocus = (event, editor) => {
    console.log("Focus.", editor);
  };

  const handleCopy = () => {
    const plainText = contentBlocks
      .map((content) => content.replace(/<\/?[^>]+(>|$)/g, ""))
      .join("\n");
    navigator.clipboard.writeText(plainText);
    setDropdownOpen(!dropdownOpen);
  };

  const handleRewriteClick = (block) => {
    setBlock(block);
    console.log(block, "blockk");
    createInstance();
    setDropDownContent("rewrite");
  };

  const handleGenerateContent = () => {
    setDropDownContent("generatecontent");
    setShowReGenerate(true);
  };

  const handleAddMoreContent = () => {
    setDropDownContent("addmorecontent");
    setShowReGenerate(false);
  };
  const handleGenerateImage = () => {
    createInstance();
    setDropDownContent("generateimage");
    setShowReGenerate(false);
  };

  const createInstance = () => {
    const domEditableElement = document.querySelector(
      ".ck-editor__editable_inline"
    );
    const editor = domEditableElement.ckeditorInstance;
    const selection = editor.model.document.selection;
    const range = selection.getFirstRange();

    let str = "";
    for (const item of range.getItems()) {
      str = str + item.data;
    }

    setChangeBlocks({
      editor: editor,
      range: range,
      originalText: str,
      rewriteText: "",
    });
  };

  // const setSelected = (text, range, editor) => {
  //   let str = "";

  //   setChangeBlocks({ ...changedBlocks, originalText: str });

  //   editor.model.change((writer) => {
  //     writer.remove(range);

  //     console.log(text, "0000000004554");
  //     text.map((url) => {
  //       const imageElement = writer.createElement("imageBlock", {
  //         // src: "https://ckeditor.com/docs/assets/4.2.1/img/cog.svg",
  //         src: url,
  //       });
  //       editor.model.insertContent(imageElement, range);
  //     });
  //   });
  // };

  const setSelected = (text, range, editor) => {
    let str = "";

    setChangeBlocks({ ...changedBlocks, originalText: str });

    editor.model.change((writer) => {
      writer.remove(range);

      let endPosition = range;
      text.map((url) => {
        const imageElement = writer.createElement("imageBlock", {
          src: url,
        });
        editor.model.insertContent(imageElement, endPosition);
        endPosition = writer.createPositionAfter(imageElement);
      });
    });
  };

  const get_page_detail = async () => {
    // try {
    //   const resp = await axios.get(
    //     `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.get_page_details}/${userID}`,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   setpageData(resp.data?.data);
    // } catch (error) {
    //   console.error("Error while making API call:", error);
    // }
  };

  useEffect(() => {
    // get_page_detail();
  }, [userID]);

  // const postFacebook = async (e) => {
  //   setIsLoading(true);

  //   if (NewImageurl) {
  //     try {
  //       let resp;

  //       const encodedMessage = encodeURIComponent(contentBlocks).replace(
  //         /<\/?[^>]+(>|$)/g,
  //         ""
  //       );
  //       console.log(encodedMessage, "encodedMessageencodedMessage");

  //       resp = await allApi({
  //         url:
  //           `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.post_facebook}?` +
  //           `access_token=${pageData?.page_token}` +
  //           `&message=${encodedMessage}` +
  //           `&url=https://us2.sourcesoftsolutions.com:8019/${Imageurl}`,
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         formData: false,
  //       });
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error("Error while making API call:", error);
  //       setIsLoading(false);
  //     }
  //   } else if (newGenerateContent) {
  //     try {
  //       let resp;

  //       const encodedMessage = encodeURIComponent(newGenerateContent).replace(
  //         /<\/?[^>]+(>|$)/g,
  //         ""
  //       );
  //       console.log(encodedMessage, "encodedMessageencodedMessage");

  //       resp = await allApi({
  //         url:
  //           `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.post_facebook}?` +
  //           `access_token=${pageData?.page_token}` +
  //           `&message=${encodedMessage}` +
  //           `&url=https://us2.sourcesoftsolutions.com:8019/${Imageurl}`,
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         formData: false,
  //       });
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error("Error while making API call:", error);
  //       setIsLoading(false);
  //     }
  //   }
  //   try {
  //     let resp;

  //     const encodedMessage = encodeURIComponent(contentBlocks).replace(
  //       /<\/?[^>]+(>|$)/g,
  //       ""
  //     );
  //     console.log(encodedMessage, "encodedMessageencodedMessage");

  //     resp = await allApi({
  //       url:
  //         `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.post_facebook}?` +
  //         `access_token=${pageData?.page_token}` +
  //         `&message=${encodedMessage}` +
  //         `&url=https://us2.sourcesoftsolutions.com:8019/${Imageurl}`,
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       formData: false,
  //     });
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error while making API call:", error);
  //     setIsLoading(false);
  //   }
  // };

  const handleInstagrampost = async (e) => {
    setIsLoadingInsta(true);

    const encodedMessage =
      encodeURIComponent(contentBlocks).replace(/<\/?p>/g, "") + " \n";

    try {
      let resp;

      resp = await allApi({
        url:
          `https://graph.facebook.com/v19.0/${pageData?.instagram_business_account_id}/media?` +
          `access_token=${pageData?.page_token}` +
          `&caption=${encodedMessage}` +
          `&image_url=https://us2.sourcesoftsolutions.com:8019/${Imageurl}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        formData: false,
      });

      if (resp?.id) {
        await new Promise((resolve) => setTimeout(resolve, 40000));

        try {
          const response = await allApi({
            url:
              `https://graph.facebook.com/v19.0/${pageData?.instagram_business_account_id}/media_publish?` +
              `creation_id=${resp?.id}` +
              "&" +
              `access_token=${pageData?.page_token}`,

            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            formData: false,
          });

          console.log(response, "response from Instagram post");
        } catch (error) {
          console.error("Error while making API call:", error);
        }
      }

      setIsLoadingInsta(false);
    } catch (error) {
      console.error("Error while making API call:", error);
      setIsLoadingInsta(false);
    }
  };

  const dispatch = useDispatch();

  // const handleShowlogin = () => {
  //   dispatch(
  //     SetpopupReducerData({
  //       modalType: "Login",
  //       showModal: true,
  //     })
  //   );
  // };

  const menu = (
    <Menu>
      {contentBlocks?.map((block, index) => (
        <Menu.Item
          key={index}
          className="menu-item"
          // icon={<ReloadOutlined />}
          onClick={() => handleRewriteClick(block)}
        >
          {/* Rewrite */}
        </Menu.Item>
      ))}
      <Menu.Item
        key="generatecontent"
        icon={<BsStars />}
        onClick={handleGenerateContent}
      >
        ReGenerateContent
      </Menu.Item>
      <Menu.Item
        key="addmorecontent"
        icon={<BsStars />}
        onClick={handleAddMoreContent}
      >
        AddMoreContent
      </Menu.Item>
      <Menu.Item
        key="generateimage"
        icon={<BsStars />}
        onClick={handleGenerateImage}
      >
        GenerateImage
      </Menu.Item>
      <Menu.Item key="copy" icon={<CopyOutlined />} onClick={handleCopy}>
        Copy
      </Menu.Item>

      <Menu.Item key="share" icon={<ShareAltOutlined />}>
        Share
        {userID && userID ? (
          <>
            <Menu.SubMenu>
              <Menu.Item
                key="facebook"
                icon={<FacebookFilled />}
                // onClick={postFacebook}
              >
                Facebook
              </Menu.Item>
              <Menu.Item
                key="instagram"
                icon={<InstagramFilled />}
                onClick={handleInstagrampost}
              >
                Instagram
              </Menu.Item>
              <Menu.Item key="youtube" icon={<YoutubeOutlined />}>
                YouTube
              </Menu.Item>
            </Menu.SubMenu>
          </>
        ) : (
          <>
            <Menu.SubMenu>
              <Menu.Item
                key="facebook"
                icon={<FacebookFilled />}
                // onClick={handleShowlogin}
              >
                Login with Social media
              </Menu.Item>
            </Menu.SubMenu>
          </>
        )}
      </Menu.Item>
      {isLoading ? (
        <Oval
          visible={true}
          height="50"
          width="50"
          color="#4fa94d"
          ariaLabel="oval-loading"
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      ) : (
        ""
      )}

      {isLoadingInsta ? (
        <Oval
          visible={true}
          height="50"
          width="50"
          color="#4fa94d"
          ariaLabel="oval-loading"
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      ) : (
        ""
      )}
    </Menu>
  );

  const handleClose = () => {
    setDropdownOpen(false);
    setDropDownContent("menu");
  };

  const handleVisibleChange = (flag) => {
    setDropdownOpen(flag);
    setDropDownContent("menu");
  };

  const handleMenu = () => {
    if (dropdownContent == "rewrite") {
      return (
        <RewriteModal
          onClose={handleClose}
          textValue={changedBlocks.originalText}
          onChangeText={(text) => {
            setSelected(text, changedBlocks.range, changedBlocks.editor);
          }}
          Block={Block}
          setBlock={setBlock}
          setUserInput={setUserInput}
        />
      );
    } else if (dropdownContent == "generatecontent") {
      return (
        <GenerateContentModal
          onClose={handleClose}
          setNewGenerateContent={setNewGenerateContent}
          setUserInput={setUserInput}
        />
      );
    } else if (dropdownContent == "generateimage") {
      return (
        <GenrateImageModal
          onClose={handleClose}
          // setNewImageurl={setNewImageurl}
          setNewImageurl={(url) =>
            setSelected(url, changedBlocks.range, changedBlocks.editor)
          }
        />
      );
    } else if (dropdownContent == "addmorecontent") {
      return (
        <AddMoreGenerateContentModal
          onClose={handleClose}
          setNewAddMoreGenerateContent={setNewAddMoreGenerateContent}
          setUserInput={setUserInput}
        />
      );
    } else {
      return menu;
    }
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            formData.append("file", file);

            fetch("https://us2.sourcesoftsolutions.com:8019/upload_image", {
              method: "POST",
              body: formData,
            })
              .then((response) => response.json())
              .then((result) => {
                console.log(result?.image_path, "result0000");
                // setImageUrl(result?.image_path);
                resolve({
                  default:
                    "https://us2.sourcesoftsolutions.com:8019/" +
                    result?.image_path,
                });
              })
              .catch((error) => {
                reject(error);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const saveContent = async () => {
    setIsLoading(true);

    if (!Title) {
      toast.error("Please add a title for the blog.");
      setIsLoading(false);
    }

    try {
      const resp = await allApi({
        url: "https://us2.sourcesoftsolutions.com:8062/save-content",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          user_id: userID,
          prompt: userInput,
          llm_used: "llama",
          tools_used: "tavily",
          generated_content: contentBlocks,
          language: "English",
          title: Title,
        },
        formData: false,
      });
      console.log("resp", resp);

      if (resp && resp.message) {
        toast.success(resp.message);
      } else {
        toast.error("Failed to save content. Please try again.");
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error while making API call:", error);

      setIsLoading(false);
    }
  };

  return (
    <div className="box">
      <Dropdown
        overlay={handleMenu}
        trigger={["contextMenu"]}
        onVisibleChange={handleVisibleChange}
        visible={dropdownOpen}
      >
        <div>
          {ReGeneratecontentBlocks ? (
            <CKEditor
              config={{
                image: {
                  toolbar: [
                    "|",
                    "imageStyle:full",
                    "imageStyle:side",
                    "imageStyle:wrapText",
                    "imageStyle:breakText",
                    "|",
                    "imageStyle:alignLeft",
                    "imageStyle:alignCenter",
                    "imageStyle:alignRight",
                    "|",
                    "resizeImage",
                  ],
                  resizeOptions: [
                    {
                      name: "resizeImage:original",
                      value: null,
                      label: "Original",
                      icon: "original",
                    },
                    {
                      name: "resizeImage:25",
                      value: "25",
                      label: "25%",
                      icon: "medium",
                    },
                    {
                      name: "resizeImage:50",
                      value: "50",
                      label: "50%",
                      icon: "medium",
                    },
                    {
                      name: "resizeImage:75",
                      value: "75",
                      label: "75%",
                      icon: "large",
                    },
                    {
                      name: "resizeImage:custom",
                      value: "custom",
                      label: "Custom",
                      icon: "custom",
                    },
                  ],
                  resizeUnit: "%",
                },
                extraPlugins: [uploadPlugin],
              }}
              editor={ClassicEditor}
              data={formatContent(ReGeneratecontentBlocks)}
              onChange={(event, editor) => handleChange(event, editor)}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          ) : (
            <>
              {contentBlocks?.map((content, index) => (
                <CKEditor
                  key={index}
                  config={{
                    image: {
                      toolbar: [
                        "|",
                        "imageStyle:full",
                        "imageStyle:side",
                        "imageStyle:wrapText",
                        "imageStyle:breakText",
                        "|",
                        "imageStyle:alignLeft",
                        "imageStyle:alignCenter",
                        "imageStyle:alignRight",
                        "|",
                        "resizeImage",
                      ],
                      resizeOptions: [
                        {
                          name: "resizeImage:original",
                          value: null,
                          label: "Original",
                          icon: "original",
                        },
                        {
                          name: "resizeImage:25",
                          value: "25",
                          label: "25%",
                          icon: "medium",
                        },
                        {
                          name: "resizeImage:50",
                          value: "50",
                          label: "50%",
                          icon: "medium",
                        },
                        {
                          name: "resizeImage:75",
                          value: "75",
                          label: "75%",
                          icon: "large",
                        },
                        {
                          name: "resizeImage:custom",
                          value: "custom",
                          label: "Custom",
                          icon: "custom",
                        },
                      ],
                      resizeUnit: "%",
                    },
                    extraPlugins: [uploadPlugin],
                  }}
                  editor={ClassicEditor}
                  data={content?.replace(/\n/g, "<br>")}
                  onChange={(event, editor) =>
                    handleChange(event, editor, index)
                  }
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
              ))}
            </>
          )}
        </div>
      </Dropdown>
      <div className="mt-5">
        <label>
          <h4></h4>Title
        </label>
        <input
          type="text"
          name="title"
          value={Title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <button className="mt-4" onClick={saveContent}>
        Save
      </button>
    </div>
  );
};

export default CkEditiorListing_for_dashboard;
