import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { Link } from "react-router-dom";
import "./datasources.css";
import { Button, Input, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";

import Sidebar from "./leftsidebar";
import { allApi } from "../../../ApiWrapper";

const boxes = [
  {
    Name: "Pine Street School",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "Kiddlez",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "Beyond Sushi",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "Dunkin'",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "Buenas Nachos",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "Gregorys Coffee",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "LENWICH",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "Neapolitan Express",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
];

const ImportFromGoogle = () => {
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [googleInput, setGoogleInput] = useState({
    query: "",
    number_of_links: 10,
  });
  const [impFromGoogle, setImpFromGoogle] = useState([]);
  const [error, setError] = useState("");
  const showModal = () => {
    setOpen(true);
    setError("");
  };

  const handleSubmit = async () => {
    if (!googleInput.query.length) {
      setError("Please Enter the topic");
      return;
    }
    if (googleInput.number_of_links <= 0) {
      setError("Please check the number again");
      return;
    }
    try {
      console.log(googleInput);
      setloading(true);
      const response = await allApi({
        url: "http://us2.sourcesoftsolutions.com:5073/fetch-emails",
        method: "POST",
        body: {
          query: googleInput.query,
          number_of_links: parseInt(googleInput.number_of_links),
        },
        formData: false,
        headers: { "Content-Type": "application/json" },
      });
      console.log(response);
      setImpFromGoogle(response?.data);
      setError("");
      setGoogleInput({
        query: "",
        number_of_links: 10,
      });
      setOpen(!open);
    } catch (err) {
      console.log(err);
      toast.error("something went wrong");
    }
    setloading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Sidebar />
      <div className="main-datasources-container">
        <Modal
          open={open}
          confirmLoading={loading}
          onCancel={handleCancel}
          style={{ height: "100px", top: 150 }}
          footer={[
            <Button key="back" onClick={handleCancel}>
              cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleSubmit}
            >
              Submit
            </Button>,
          ]}
        >
          <label htmlFor="query" className="llm-label">
            Query
          </label>
          <Input
            id="query"
            placeholder="Enter your"
            size="large"
            value={googleInput.query}
            onChange={(e) =>
              setGoogleInput({ ...googleInput, query: e.target.value })
            }
          />
          <label htmlFor="number_of_links" className="llm-label">
            Number of links
          </label>
          <Input
            id="number_of_links"
            type="number"
            size="large"
            value={googleInput.number_of_links}
            onChange={(e) =>
              setGoogleInput({
                ...googleInput,
                number_of_links: e.target.value,
              })
            }
          />
          {error && <p className="error-message">{error}</p>}
        </Modal>
        <div className="heading-button">
          <h3>Import from Google</h3>
          <button className="primary-custom-btn" onClick={showModal}>
            <FaPlus />
            create
          </button>
        </div>
        <div className="proSearchList">
          {impFromGoogle.length === 0 ? (
            <>
              <h3>No imports Yet!</h3>
            </>
          ) : (
            impFromGoogle.map((imp, index) => (
              <div className="listBox" key={index}>
                <div className="p-4">
                  <h4>Emails</h4>
                  <p className="my-3">{imp.url}</p>
                  {imp.emails.map((email) => (
                    <p className="my-3">{email}</p>
                  ))}
                  <a className="btn" href={imp.url} target="_blank">
                    View
                  </a>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default ImportFromGoogle;
