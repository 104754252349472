import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  EmailPost,
  getEmailPayload,
  setPopup,
  setSaveReceiverData,
  setSaveSenderData,
} from "../../../../Store/Action";
import {
  SAVE_RECEIVER_LIST,
  SAVE_SENDER_LIST,
  SENDER_DATA,
  SENDER_UPLOAD,
} from "../../../../Store/Action/Type";
import { Modal } from "react-bootstrap";

function UploadSender() {
  const dispatch = useDispatch();

  const popupReducer = useSelector((state) => state?.all);
  const { showModal } = popupReducer?.popupReducer?.modal || {};
  const UserId = useSelector((state) => state.all?.auth?.data?._id);
  //   const FetchApi = useCallback(async () => {
  //     dispatch(EmailPost(SENDER_UPLOAD));
  //     dispatch(getEmailPayload(SENDER_DATA));
  //   }, [dispatch]);

  //   useEffect(() => {
  //     FetchApi();
  //   }, []);

  const handleBack = () => {
    dispatch(setPopup({ modalType: "SENDER", showModal: false }));
    handleClose();
  };

  const [file, setFile] = useState({});

  console.log(file, "filedata");
  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(URL.createObjectURL(selectedFile));
    }
  };

  const fileInputRef = useRef(null);

  const submitHandler = async (e) => {
    e.preventDefault();

    const files = fileInputRef.current?.files;
    console.log("Files from input:", files);

    if (files && files.length > 0) {
      const selectedFile = files[0];

      const fd = new FormData();
      fd.append("file", selectedFile);
      fd.append("User", UserId);
      const fetchSenderData = async () => {
        try {
          const fetchsender = await dispatch(EmailPost(SENDER_UPLOAD, fd));
          console.log("fetchsender", fetchsender);
          if (fetchsender?.status === "true") {
            toast.success(fetchsender?.data_summary);
            toast.success(fetchsender?.message);
            // navigate("/senderlisting");
            fetchSenderDataredux();
            handleClose();

            dispatch(setPopup({ modalType: "SENDER", showModal: true }));
          } else {
            toast.error(fetchsender?.message);
          }
        } catch (error) {
          console.error("Error fetching sender data 1111:", error);
          toast.error(error);
        }
      };

      fetchSenderData();

      // console.log("response", response);
      // if (response?.error?.length > 0) {
      //   toast.success(response?.data_summary);
      //   navigate("/senderlisting");
      // } else {
      // toast.error(response?.message);
      // }
    } else {
      toast.error("Please select a file before uploading.");
    }
  };

  const fetchSenderDataredux = async () => {
    try {
      dispatch(getEmailPayload(`${SAVE_SENDER_LIST}?user_id=${UserId}`)).then(
        (data) => {
          console.log("senderdararrararrarar", data?.data);
          dispatch(setSaveSenderData(data?.data));
        }
      );
    } catch (error) {
      console.log(error, "error in SAVE_SENDER_LIST ");
    }
  };

  //   const SenderList = (e) => {
  //     dispatch(getPayload(SENDER_DATA));
  //   };

  const handleDownload = async () => {
    let csvUrl =
      "https://us2.sourcesoftsolutions.com:8062/sender_files/new_senders.csv";

    try {
      // Fetch the CSV file
      const response = await fetch(csvUrl);

      // Convert the response to a blob
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "new_senders.csv");
      link.style.display = "none";

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger a click event on the link
      link.click();

      // Remove the link and revoke the URL after the download is initiated
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download CSV file:", error);
    }
  };

  const handleDownloadExcel = async () => {
    let ExcelUrl =
      "https://us2.sourcesoftsolutions.com:8062/sender_files/Senders.xlsx";

    try {
      // Fetch the CSV file
      const response = await fetch(ExcelUrl);

      // Convert the response to a blob
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Senders.xlsx");
      link.style.display = "none";

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger a click event on the link
      link.click();

      // Remove the link and revoke the URL after the download is initiated
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download CSV file:", error);
    }
  };

  const handleClose = () => {
    dispatch(setPopup({ modalType: "", showModal: false }));
  };
  return (
    <>
      <Modal
        // show={setshow !== true ? show : false}
        show={showModal}
        onHide={handleClose}
        size="xl"
        className="emailPoup"
      >
        <div className="container mb-4">
          <div className="container-wrap mt-4">
            <div className="row justify-content-center align-items-stretch">
              <div className="col-md-8">
                <button className="m-4" onClick={handleDownload}>
                  Download CSV file
                </button>
                <button onClick={handleDownloadExcel}>
                  Download Excel file
                </button>

                <div className="step_forms-wrap py-5 h-100">
                  <h3 className="title_1 text-center mb-4">
                    Import Senders hello
                  </h3>
                  <form
                    className=""
                    action=""
                    method=""
                    onSubmit={(e) => {
                      submitHandler(e);
                    }}
                  >
                    <div className="form-group">
                      <div className="file-upload darkBordr">
                        <div className="image-upload-wrap text-center">
                          <input
                            ref={fileInputRef}
                            className="file-upload"
                            type="file"
                            name="file"
                            accept=".csv"
                            onChange={handleChange}
                          />
                          <div className="drag-text">
                            <h3>
                              <span>Supported formats: CSV/Excel File</span>
                            </h3>
                            {/* <img src={file} alt="check" /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="hidden-button">
                      Upload
                    </button>
                  </form>

                  <button
                    type="button"
                    className="btn-primary me-2 mt-4 text-white"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  {/* <NavLink to={"/senderlisting"}>
                    <button
                      type="button"
                      className="btn-primary me-2 mt-4 text-white"
                    >
                      Back{" "}
                    </button>
                  </NavLink> */}
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </Modal>
    </>
  );
}

export default UploadSender;
