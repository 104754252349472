import React, { useState } from "react";

const AgentDetails = ({ llm_details, onBack, onNext }) => {
  console.log(llm_details);
  return (
    <>
      <div className="detail-container">
        <h2>Agent Details</h2>
        {llm_details && (
          <ul>
            <li>
              <strong>ID:</strong> {llm_details._id}
            </li>
            <li>
              <strong>Name:</strong> {llm_details.name}
            </li>
            <li>
              <strong>LLM Type:</strong> {llm_details.llm_type}
            </li>
            <li>
              <strong>Current Language:</strong> {llm_details.current_language}
            </li>
            <li>
              <strong>Default Language:</strong> {llm_details.default_language}
            </li>
            <li>
              <strong>Agent State:</strong> {llm_details.agent_state}
            </li>
            <li>
              <strong>Personality:</strong> {llm_details.personality}
            </li>
            <li>
              <strong>State:</strong> {llm_details.state}
            </li>
            <li>
              <strong>Key:</strong> {llm_details.key}
            </li>
          </ul>
        )}
        <div className="d-flex justify-content-between mt-4">
          <button className="btn2 bt" onClick={() => onBack()}>
            Back
          </button>
          <button className="btn1 bt" onClick={() => onNext()}>
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default AgentDetails;
