import React, { useRef, useState } from "react";
import { FaSquarePlus } from "react-icons/fa6";
import AddDataModal from "./modals/AddDataModal";
// import "./style,.css";

const DataIngest = ({
  dataSources,
  onNextStep,
  onDataSources,
  update,
  setSelectedFiles,
  removeSelectedFile,
  selectedFiles,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  console.log(dataSources);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleFileSelection = (fileUrl) => {
    const isSelected = selectedFiles?.includes(fileUrl);

    if (isSelected) {
      removeSelectedFile(fileUrl);
    } else {
      setSelectedFiles(fileUrl);
    }
  };

  const isSelectedFile = (data) => {
    return selectedFiles?.includes(
      `${process.env.REACT_APP_API_BACKEND_LLM_URL}/${data?.file_path}`
    );
  };

  return (
    <>
      <AddDataModal
        isOpen={modalOpen}
        close={closeModal}
        onDataSources={onDataSources}
        update={update}
      />
      <div className="data-sources-grid">
        <div className="adding-card card-dim" onClick={openModal}>
          <FaSquarePlus />
          <h6>Add Data Services</h6>
        </div>
        {dataSources &&
          dataSources?.length >= 0 &&
          dataSources?.map((data, index) => (
            <>
              <div
                className={
                  "card-dim data-sources-card " +
                  (isSelectedFile(data) ? "cheked-data-sources-card" : "")
                }
                key={index}
                onClick={() =>
                  handleFileSelection(
                    `${process.env.REACT_APP_API_BACKEND_LLM_URL}/${data?.file_path}`
                  )
                }
              >
                <div className="data-sources-img-box">
                  <img
                    src={
                      `${process.env.REACT_APP_API_BACKEND_LLM_URL}/${data?.file_path}` ||
                      "https://icon-library.com/images/pdf-icon/pdf-icon-11.jpg"
                    }
                    alt=""
                  />
                </div>
                <h4>{data?.filename}</h4>
                <p></p>
                <input
                  type="checkbox"
                  id={`topping-${index}`} // Add unique id for each checkbox
                  name={`topping-${index}`}
                  checked={isSelectedFile(data)}
                  value={`${process.env.REACT_APP_API_BACKEND_LLM_URL}/${data?.file_path}`}
                  onChange={() =>
                    handleFileSelection(
                      `${process.env.REACT_APP_API_BACKEND_LLM_URL}/${data?.file_path}`
                    )
                  }
                />
              </div>
            </>
          ))}
      </div>
      {/* <div className="step-btn-cover">
        {selectedFile && <div>Selected File: {selectedFile.filename}</div>}
      </div> */}
      <div className="steps-btn">
        <div></div>
        <button type="button" className="next" onClick={() => onNextStep()}>
          Next
        </button>
      </div>
    </>
  );
};

export default DataIngest;
