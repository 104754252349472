import React from "react";
import "../Modal/modal.css";

const LoginForm = ({
  loginData,
  onChange,
  onSubmit,
  loginErrors,
  onKeyDown,
}) => {
  return (
    <form onSubmit={onSubmit} onKeyDown={onKeyDown}>
      <div class="inputData mt-4">
        <input
          type="text"
          name="email"
          id="email"
          // placeholder="Enter Email"
          value={loginData.email}
          onChange={onChange}
          required
        />
        <label htmlFor="email">Email</label>
        <div class="underline"></div>
      </div>
      {loginErrors && <div className="error">{loginErrors?.email}</div>}
      <div class="inputData mt-4">
        <input
          type="password"
          name="password"
          id="password"
          // placeholder="Enter Password"
          value={loginData.password}
          onChange={onChange}
          required
        />
        <label htmlFor="password">Password</label>
        <div class="underline"></div>
      {loginErrors?.password && (
        <div className="error">{loginErrors?.password}</div>
      )}
      </div>
    </form>
  );
};

export default LoginForm;
