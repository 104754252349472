import { combineReducers } from "redux";
import Global from "./Global";
import reducer from "./gmapsDataReducer";

const rootReducer = combineReducers({
  all: Global,
  apiDataSlice: reducer,
});

export default rootReducer;
