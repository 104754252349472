import React, { useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const BusinesAllDetail = ({ detailedData }) => {
  const containerStyle = {
    width: "1000px",
    height: "850px",
  };

  const center = {
    lat: Number(detailedData?.latitude),
    lng: Number(detailedData?.longitude),
  };
  console.log(center, "centerdataabcxyz");
  const defaultZoom = 10;

  const validCoordinates =
    detailedData &&
    !isNaN(parseFloat(detailedData?.latitude)) &&
    !isNaN(parseFloat(detailedData?.longitude));

  useEffect(() => {
    const map = new window.google.maps.Map(
      document.getElementById("mapdatadettails"),
      {
        center: { lat: center?.lat, lng: center?.lng },
        zoom: 13,
      }
    );

    const marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(
        parseFloat(center?.lat),
        parseFloat(center?.lng)
      ),
      label: detailedData?.Name,
    });

    marker.setMap(map);
  }, [detailedData]);

  return (
    <div>
      {/* <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={validCoordinates ? 20 : defaultZoom}
        >
          {validCoordinates && <Marker position={center} />}
        </GoogleMap>
      </LoadScript> */}
      <div
        id="mapdatadettails"
        style={{ height: "900px", width: "100%" }}
      ></div>
    </div>
  );
};

export default BusinesAllDetail;
