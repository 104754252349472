import React, { lazy, Suspense, useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Page/Maps/Pages/Layout";
import Contact from "./Page/Maps/Pages/Contact";
import Faq from "./Page/Maps/Pages/Faq";
import ImportTemplate from "./Page/Email/Pages/New/Template/ImportTemplate";
import ImportContact from "./Page/Email/Pages/New/Contact/ImportContact";
import Sender from "./Page/Email/Pages/New/Sender/Sender";
import Receiver from "./Page/Email/Pages/New/Receiver/Receiver";
import SenderListing from "./Page/Email/Pages/Listing/SenderListing";
import ReceiverListing from "./Page/Email/Pages/Listing/ReceiverListing";
import TemplateChange from "./Page/Email/Pages/New/Template/TemplateChange";
import Email from "./Page/Email/Pages/New/Email/Email";
import NotFound from "./Components/Error/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./Components/PrivateRoutes/PrivateRoute";
import Dashboard from "./Components/Dashboard";
import MainBusinessDetail from "./Page/Maps/components/BusinessDetail/MainBusinessDetail";
import MarketingResearch from "./Components/MarketResearch/MarketResearch";
import AITool from "./Components/LLMusecase/AITool";
import Agent from "./Components/AgentFramework/Agent";
import UserPrefrence from "./Components/UserPrefrence/UserPrefrence";
import Compose from "./Page/Email/components/Sidebar/Compose";
import SelectTemplate from "./Page/Email/Pages/New/Template1/SelectTemplate";
import { useDispatch, useSelector } from "react-redux";
import OpenModel from "./Page/Email/Pages/Modal/OpenModel";
import RPA from "./Components/DataSources/DataSources/RPA";
import LLM from "./Components/DataSources/DataSources/LLM";
import ImportFromGoogle from "./Components/DataSources/DataSources/ImportFromGoogle";
import ImportFromWeb from "./Components/DataSources/DataSources/ImportFromWeb";
import { EmailPost } from "./Store/Action";
import { NEW_INBOX, NEW_OUTBOX, NEW_UNREAD } from "./Store/Action/Type";
import Documentation from "./Components/Documentation/Documentation";
import MyFiles from "./Components/MyFiles/MyFiles";
import MyConfigurations from "./Components/MyFiles/MyConfigurations";
import DataMyFiles from "./Components/DataSources/DataSources/DataMyFiles";
import ImportReceive from "./Components/DataSources/DataSources/ImportReceive";
import EditiorSidebar from "./Components/MarketResearch/EditiorSidebar";
import CkEditiorListing_for_dashboard from "./Components/MarketResearch/CkEditiorListing_for_dashboard";
import Imagegenrator from "./Components/MarketResearch/Imagegenrator";
import Videogenrator from "./Components/MarketResearch/Videogenrator";
import UserProfile from "./Components/Dashboard_Design/UserProfile";
import Notification from "./Page/Maps/components/Header/Notification";
import Payment from "./Components/Payment";
import Congratulations from "./Components/Suceess";

const Home = lazy(() => import("./Page/Maps/Pages/Home"));
const About = lazy(() => import("./Page/Maps/Pages/About"));
const EmailLayout = lazy(() => import("./Page/Email/EmailLayout/EmailLayout"));
function App() {
  const [showPopup, setShowPopup] = useState(false);
  const popupReducer = useSelector((state) => state?.all);
  const { showModal } = popupReducer?.popupReducer?.modal || {};
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("showModalApp", showModal);
    setShowPopup(!!showModal);
    return () => {
      setShowPopup(false);
    };
  }, [showModal]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const payload = {
  //         user_id: "user123",
  //         email_id: "echobyte76@gmail.com",
  //         criteria: "newest",
  //       };
  //       await dispatch(EmailPost(NEW_INBOX, payload));
  //       await dispatch(EmailPost(NEW_OUTBOX, payload));
  //       await dispatch(EmailPost(NEW_UNREAD, payload));
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [dispatch]);

  return (
    <>
      {showPopup && <OpenModel />}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />

      <BrowserRouter>
        <Layout />
        <Suspense fallback={<div>Loading...</div>}>
          {/* <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/email" element={<EmailLayout />} />
            <Route path="/newtemplate" element={<ImportTemplate />} />
            <Route path="/importcontact" element={<ImportContact />} />
            <Route path="/importsenders" element={<Sender />} />
            <Route path="/receiver" element={<Receiver />} />
            <Route path="/senderlisting" element={<SenderListing />} />
            <Route path="/receiverlisting" element={<ReceiverListing />} />
            <Route path="/templatelist" element={<TemplateChange />} />
            <Route path="/newemail" element={<Email />} />
            <Route path="*" element={<NotFound />} />
          </Routes> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
            <Route
              path="/businessDetail/:id"
              element={<MainBusinessDetail />}
            />

            <Route
              path="/blog"
              element={
                <CkEditiorListing_for_dashboard
                  element={<CkEditiorListing_for_dashboard />}
                />
              }
            />
            <Route
              path="/imageg_genrator"
              element={<Imagegenrator element={<Imagegenrator />} />}
            />
            <Route
              path="/video_genrator"
              element={<Videogenrator element={<Videogenrator />} />}
            />

            <Route
              path="/my_files"
              element={<MyFiles element={<MyFiles />} />}
            />
            <Route
              path="/my_files/:active"
              element={<MyFiles element={<MyFiles />} />}
            />

            <Route
              path="/compose"
              element={<PrivateRoute element={<Compose />} />}
            />

            <Route
              path="/select_template"
              element={<PrivateRoute element={<SelectTemplate />} />}
            />

            <Route
              path="/newtemplate"
              element={<PrivateRoute element={<ImportTemplate />} />}
            />
            <Route
              path="/importcontact"
              element={<PrivateRoute element={<ImportContact />} />}
            />
            <Route
              path="/importsenders"
              element={<PrivateRoute element={<Sender />} />}
            />
            <Route
              path="/receiver"
              element={<PrivateRoute element={<Receiver />} />}
            />
            <Route
              path="/senderlisting"
              element={<PrivateRoute element={<SenderListing />} />}
            />
            <Route
              path="/receiverlisting"
              element={<PrivateRoute element={<ReceiverListing />} />}
            />
            <Route
              path="/templatelist"
              element={<PrivateRoute element={<SelectTemplate />} />}
            />
            {/* {<TemplateChange />} />} /> */}
            <Route
              path="/newemail"
              element={<PrivateRoute element={<Email />} />}
            />
            <Route
              path="/dashboard"
              element={<PrivateRoute element={<Dashboard />} />}
            />

            <Route path="/documentation" element={<Documentation />} />

            <Route path="/MarketingResearch" element={<MarketingResearch />} />
            <Route path="/AITool" element={<AITool />} />
            <Route path="/AgentFramework" element={<Agent />} />
            <Route path="/UserPrefrence" element={<UserPrefrence />} />
            <Route path="/AITool" element={<AITool />} />

            <Route path="/llm" element={<LLM />} />
            <Route path="/import-from-google" element={<ImportFromGoogle />} />
            <Route path="/import-from-web" element={<ImportFromWeb />} />
            <Route path="/my-files-data" element={<DataMyFiles />} />
            <Route path="/import-receive" element={<ImportReceive />} />
            <Route path="/Notification" element={<Notification />} />

            <Route path="*" element={<NotFound />} />

            <Route
              path="/email"
              element={<PrivateRoute element={<UserProfile />} />}
            />

            <Route path="/rpa" element={<PrivateRoute element={<RPA />} />} />

            <Route
              path="/editor_dashboard"
              element={<PrivateRoute element={<EditiorSidebar />} />}
            />

            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/success" element={<Congratulations />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
