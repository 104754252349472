import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { FaAngleLeft, FaAngleRight, FaListCheck } from "react-icons/fa6";
import {
  getEmailPayload,
  setConfiguration,
  setPopup,
  setSelectedConfig,
  setSelectedReceviers,
  setSelectedSenders,
} from "../../../../Store/Action";
import { CONFIGURATION } from "../../../../Store/Action/Type";

const ConfigurationForm = () => {
  const [deliveryTime, setDeliveryTime] = useState("now");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [timezone, setTimeZone] = useState("ist");
  const [deliveryHour, setDeliveryHour] = useState("");
  const [deliveryInterval, setDeliveryInterval] = useState("");
  const [mailCount, setMailCount] = useState("");
  const [show, setShow] = useState(true);
  const template = localStorage?.getItem("template");
  const templatedata = JSON.parse(template);
  console.log("templatedata", templatedata);
  const userID = useSelector((state) => state?.all?.auth?.data?._id);
  const senderData = localStorage.getItem("EditConfigdata");
  // const configData = JSON.parse(senderData);
  const formatDateWithoutMillisAndZ = (date) => {
    return date.toISOString().replace(/\.\d{3}Z$/, "");
  };
  const data = localStorage?.getItem("data");
  const configurationdata = JSON.parse(data);
  console.log("configurationdata", configurationdata);
  const dispatch = useDispatch();
  const popupReducer = useSelector((state) => state?.all);
  const { showModal } = popupReducer?.popupReducer?.modal || {};

  const configData = useSelector(
    (state) => state?.all?.SelectedConfig?.data?.configuration
  );
  const UserId = useSelector((state) => state.all?.auth?.data?._id);

  console.log("configData0000000", configData);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      user_id: UserId,
      name: configurationdata?.name,
      senders_id: configurationdata?.senders_id,
      receivers_id: configurationdata?.receivers_id,
      schedule: "",
      send_type: "",
      template_id: configurationdata?.template_id,
      timezone: timezone,
      time_gap: deliveryInterval,
      sender_email_count: mailCount,
    };
    console.log("payload", payload);

    if (deliveryTime === "now") {
      payload.schedule = formatDateWithoutMillisAndZ(new Date());
      payload.send_type = "1";
    } else if (deliveryTime === "later") {
      payload.schedule = `${deliveryDate}T${deliveryHour}:00`;
      payload.send_type = "2";
    }

    const editpayload = {
      user_id: UserId,
      name: configurationdata?.name,
      senders_id: configurationdata?.senders,
      receivers_id: configurationdata?.receivers,
      schedule: "",
      send_type: "",
      template_id: configurationdata?.tempalte?._id,
      timezone: timezone,
      time_gap: deliveryInterval,
      sender_email_count: mailCount,
    };
    if (deliveryTime === "now") {
      editpayload.schedule = formatDateWithoutMillisAndZ(new Date());
      payload.send_type = "1";
    } else if (deliveryTime === "later") {
      editpayload.schedule = `${deliveryDate}T${deliveryHour}:00`;
      payload.send_type = "2";
    }
    console.log("Payload:", payload);

    if (configurationdata?.config_id) {
      try {
        const response = await fetch(
          // `https://us2.sourcesoftsolutions.com:8062/updateConfiguration?configuration_id=${configData?.data?.configuration?._id}&user_id=user123`,
          `https://us2.sourcesoftsolutions.com:8062/updateConfiguration?configuration_id=${configurationdata?.config_id}&user_id=${UserId}`,
          {
            method: "PUT",
            // body: JSON?.stringify(editpayload),
            body: JSON?.stringify(payload),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        if (responseData) {
          toast.success(responseData?.message);
          dispatch(setSelectedSenders());
          dispatch(setSelectedReceviers());
          dispatch(setPopup({ modalType: "", showModal: false }));
          dispatch(getEmailPayload(`${CONFIGURATION}?user_id=${UserId}`)).then(
            (data) => {
              console.log("data?.data", data?.data);
              dispatch(setConfiguration(data?.data));
              // setConfigData(data?.data);
            }
          );
          localStorage?.removeItem("data");
          localStorage?.removeItem("EditConfigdata");
        }
      } catch {}
    } else {
      try {
        const response = await fetch(
          "https://us2.sourcesoftsolutions.com:8062/configurations",
          {
            method: "POST",
            body: JSON?.stringify(payload),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        if (responseData) {
          toast.success(responseData?.message);
          setShow(false);
          dispatch(setSelectedSenders());
          dispatch(setSelectedReceviers());
          localStorage?.removeItem("data");
          dispatch(setPopup({ modalType: "", showModal: false }));
          dispatch(getEmailPayload(`${CONFIGURATION}?user_id=${UserId}`)).then(
            (data) => {
              console.log("data?.data", data?.data);
              dispatch(setConfiguration(data?.data));
              // setConfigData(data?.data);
            }
          );
        }
      } catch {}
    }

    // Here you would send the payload to your backend or handle it as needed
  };

  const handleBack = () => {
    dispatch(setPopup({ modalType: "TEMPLATE", showModal: true }));
  };

  const handleClose = () => {
    dispatch(setSelectedReceviers());
    dispatch(setSelectedSenders());
    dispatch(setSelectedConfig());
    dispatch(setPopup({ modalType: "", showModal: false }));
  };

  // const deliveryIntervals = Array.from({ length: 301 }, (_, i) => i);
  const deliveryIntervals = Array.from({ length: 6 }, (_, i) => (i + 1) * 60);

  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose}>
        <div className="mt-4">
          <Row>
            <Col xl={2} className="border-end">
              <div className="stepsList pt-5">
                <ul className="listOpt">
                  <li className="active">
                    <span>1</span>Chose Senders
                  </li>
                  <li className="active">
                    <span>2</span>Chose Receivers
                  </li>
                  <li className="active">
                    <span>3</span>Chose Template
                  </li>
                  <li className="active">
                    <span>4</span>Chose Time
                  </li>
                </ul>
              </div>
            </Col>

            <Col xl={10} className="ps-4">
              <h3 className="popTitle my-4">
                <FaListCheck className="me-2" /> Create Your Configuration
              </h3>

              <div className="choseSender mt-4">
                <h3 className="mb-1">Chose Time</h3>
                <p>Set delivery time of mail e.g now or later.</p>
              </div>

              <div className="">
                <form onSubmit={handleSubmit}>
                  <div className="d-flex gap-5 my-5">
                    <div className="form-check d-flex gap-3 m-0 p-0">
                      <input
                        className="form-check-input m-0"
                        type="radio"
                        name="deliveryTime"
                        id="now"
                        value="now"
                        checked={deliveryTime === "now"}
                        onChange={(e) => setDeliveryTime(e.target.value)}
                      />
                      <label className="form-check-label m-0" htmlFor="now">
                        Now
                      </label>
                    </div>
                    <div className="form-check d-flex gap-3 m-0 p-0">
                      <input
                        className="form-check-input m-0"
                        type="radio"
                        name="deliveryTime"
                        id="later"
                        value="later"
                        checked={deliveryTime === "later"}
                        onChange={(e) => setDeliveryTime(e.target.value)}
                      />
                      <label className="form-check-label m-0" htmlFor="later">
                        Later
                      </label>
                    </div>
                  </div>
                  {deliveryTime === "later" ? (
                    <>
                      <div className="row mb-5">
                        <div className="col-md-5">
                          <label htmlFor="timeZone" className="form-label">
                            Select Time zone:
                          </label>
                          <select
                            className="form-select"
                            id="timeZone"
                            value={timezone}
                            onChange={(e) => setTimeZone(e.target.value)}
                          >
                            <option value={"ist"}>
                              Indian Standard Time(IST)
                            </option>
                            <option value={"est"}>
                              Eastern Standard Time(EST)
                            </option>
                          </select>
                        </div>
                        {/* <div className="col-md-5">
                          <label
                            htmlFor="deliveryOption"
                            className="form-label"
                          >
                            Delivery option (Time between each email)
                          </label>
                          <select className="form-select" id="deliveryOption">
                            <option selected>Indian Standard Time(IST)</option>
                          </select>
                        </div> */}
                      </div>

                      <div className="row mb-5">
                        <div className="col-md-5">
                          <label htmlFor="deliveryDate" className="form-label">
                            Set specific delivery Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="deliveryDate"
                            value={deliveryDate}
                            onChange={(e) => setDeliveryDate(e.target.value)}
                          />
                        </div>
                        <div className="col-md-5">
                          <label htmlFor="deliveryHour" className="form-label">
                            Set specific delivery Time
                          </label>
                          <input
                            type="time"
                            className="form-control"
                            id="deliveryHour"
                            value={deliveryHour}
                            onChange={(e) => setDeliveryHour(e.target.value)}
                          />
                        </div>

                        <div className="col-md-5">
                          <label
                            htmlFor="deliveryOption"
                            className="form-label"
                          >
                            Delivery option (Time between each email)
                          </label>
                          <select
                            className="form-select"
                            id="deliveryInterval"
                            value={deliveryInterval}
                            onChange={(e) =>
                              setDeliveryInterval(e.target.value)
                            }
                          >
                            <option> Select time </option>
                            {deliveryIntervals.map((interval) => (
                              <option key={interval} value={interval}>
                                {interval} seconds
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-5">
                          <label
                            htmlFor="deliveryOption"
                            className="form-label"
                          >
                            Sender mail count
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="mailCount"
                            value={mailCount}
                            onChange={(e) => setMailCount(e.target.value)}
                            min="0"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-5">
                        <label htmlFor="deliveryOption" className="form-label">
                          Delivery option (Time between each email)
                        </label>
                        <select
                          className="form-select"
                          id="deliveryInterval"
                          value={deliveryInterval}
                          onChange={(e) => setDeliveryInterval(e.target.value)}
                        >
                          <option> Select time </option>
                          {deliveryIntervals.map((interval) => (
                            <option key={interval} value={interval}>
                              {interval} seconds
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-5">
                        <label htmlFor="deliveryOption" className="form-label">
                          Sender mail count
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="mailCount"
                          value={mailCount}
                          onChange={(e) => setMailCount(e.target.value)}
                          min="0"
                        />
                      </div>
                    </>
                  )}

                  <div className="d-flex justify-content-end btnBackNext mt-5">
                    <button className="btn buttonBack" onClick={handleBack}>
                      <FaAngleLeft className="me-1 mb-1" /> Back
                    </button>
                    <button type="submit" className="btn buttonNext">
                      Next <FaAngleRight className="ms-1 mb-1" />
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ConfigurationForm;
