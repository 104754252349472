import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./datasources.css";
import { Button, Input, Modal } from "antd";
import { toast } from "react-toastify";
import { allApi } from "../../../ApiWrapper";

import Sidebar from "./leftsidebar";

const web = [
  {
    key: "google_map",
    name: "Google Map",
  },
  {
    key: "insta",
    name: "Instagram",
  },
  {
    key: "amazon",
    name: "Amazon",
  },
];

const ImportFromWeb = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [webInput, setWebInput] = useState({
    url: "",
  });
  const [impFromWeb, setImpFromWeb] = useState([]);
  const [error, setError] = useState("");

  const showModal = () => {
    setOpen(true);
    setError("");
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setWebInput({
      url: value,
    });
  };

  const handleSubmit = async () => {
    if (!webInput.url.length) {
      setError("URL field cannot be empty");
      return;
    }
    try {
      console.log(webInput);
      setLoading(true);
      const response = await allApi({
        url: "http://us2.sourcesoftsolutions.com:5073/process_importfrom_web",

        method: "POST",
        body: webInput,
        formData: false,
        headers: { "Content-Type": "application/json" },
      });
      console.log(response);
      setImpFromWeb([
        ...impFromWeb,
        { ...response?.data, source: webInput.url },
      ]);
      setError("");
      setWebInput({
        url: "",
      });
      setOpen(false);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  console.log("impFromWeb", impFromWeb);

  return (
    <>
      <Sidebar />
      <div className="main-datasources-container">
        <Modal
          open={open}
          confirmLoading={loading}
          onCancel={handleCancel}
          style={{ height: "100px", top: 150 }}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleSubmit}
            >
              Submit
            </Button>,
          ]}
        >
          <label htmlFor="web-input" className="web-label">
            Import from Web
          </label>

          <label htmlFor="url-input" className="web-label">
            Add URL
          </label>
          <Input
            id="url-input"
            placeholder="Enter URL"
            size="large"
            value={webInput.url}
            onChange={handleInputChange}
          />
          {error && <p className="error-message">{error}</p>}
        </Modal>
        <div className="heading-button">
          <h3>Import from Web</h3>
          <button className="primary-custom-btn" onClick={showModal}>
            <FaPlus />
            Create
          </button>
        </div>
        {/* <div className="proSearchList"> */}
        {impFromWeb.length === 0 ? (
          <h3>No imports yet!</h3>
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <table className="table">
                <thead>
                  <tr>
                    {Object.keys(impFromWeb[0][0]).map((key) => (
                      <th scope="col" key={key}>
                        {key}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {impFromWeb?.map((ele, index) => (
                    <tr key={index}>
                      {Object.keys(impFromWeb[0][0]).map((key, i) => (
                        <td key={i}>{ele[0][key]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {/* </div> */}
      </div>
    </>
  );
};

export default ImportFromWeb;
