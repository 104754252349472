import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPayload, SubmitPost } from "../../../../Store/Action";
import { useDispatch } from "react-redux";
import {
  SENDER_DATA,
  SAVE_SENDER_LIST,
  UNIQUE_ID,
} from "../../../../Store/Action/Type";
import "./listing.css";
import NavLink from "../New/routes/NavLink";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function SenderListing() {
  const { SenderData, unique } = useSelector((state) => state?.all);
  console.log(SenderData,"SenderData")

  console.log("unique+++", unique);

  const [showA, setShowA] = useState({});

  const [showB, setShowB] = useState({});

  const [active, setActive] = useState([]);

  const [rightActive, setRightActive] = useState([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    FetchApi();
  }, []);

  useEffect(() => {
    setShowA(SenderData);
  }, [SenderData]);

  const FetchApi = () => {
    // if (Object.keys(SenderData)) {
    dispatch(getPayload(SENDER_DATA));
    // }
  };

  const selectShowA = () => {
    let arr = Object.keys(showA);

    if (Object.keys(showA).length === active.length) {
      setActive([]);
    } else {
      setActive(arr);
    }
  };

  const selectShowB = () => {
    let arr = Object.keys(showB);
    if (Object.keys(showB).length === rightActive.length) {
      setRightActive([]);
    } else {
      setRightActive(arr);
    }
  };

  const handleClickLeft = (val) => {
    let arr = [...active];
    let idx = arr.findIndex((ele) => ele === val);
    if (idx >= 0) {
      arr.splice(idx, 1);
    } else {
      arr.push(val);
    }
    setActive(arr);
  };

  console.log("active", active);

  const handleClickRight = (val) => {
    let arr = [...rightActive];
    let idx = arr.findIndex((ele) => ele === val);
    if (idx >= 0) {
      arr.splice(idx, 1);
    } else {
      arr.push(val);
    }
    setRightActive(arr);
  };

  const setDataRight = () => {
    if (!active.length) {
      return;
    }

    let leftData = [...showA];
    let rightData = { ...showB };

    console.log("leftData", leftData);
    console.log("rightData", rightData);
    active.forEach((item) => {
      leftData.map((single, index) => {
        console.log("item", item);
        if (single[item]) {
          rightData[item] = single[item];
        }
      });
    });

    setShowB(rightData);
  };

  const setDataLeft = () => {
    if (!rightActive.length) {
      return;
    }

    let rightData = { ...showB };

    rightActive.forEach((item) => {
      delete rightData[item];
    });

    setShowB(rightData);
    setRightActive([]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (active.length === 0) {
      toast.error("Please Select Senders from the left list");
      return;
    }

    if (Object.keys(showB).length === 0) {
      toast.error("Please Select Senders from the right list");
      return;
    }

    const newUniqueId = uuidv4();
    dispatch({ type: UNIQUE_ID, payload: newUniqueId });

    await new Promise((resolve, reject) => {
      let arr = [];
      for (const iterator in showB) {
        SenderData?.map((keyName) => {
          if (keyName[iterator]) {
            arr.push(keyName[iterator]);
            navigate("/receiverlisting");
          }
        });
      }
      resolve(arr);
    }).then((data) => {
      dispatch(
        SubmitPost(SAVE_SENDER_LIST, {
          senders: data,
          unique_id: newUniqueId,
        })
      );
    });
  };

  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput.split("").length > 1) {
      const filtersender = Object.keys(showA || {}).filter((item) =>
        item.toLowerCase().includes(searchInput.toLowerCase())
      );

      console.log(filtersender);
      console.log("Filter Data", filtersender, SenderData);
      setShowA(filtersender.reduce((a, v) => ({ ...a, [v]: v }), {}));
      console.log(searchValue);
    } else {
      setShowA(SenderData);
    }
  };

  const [searchInputnew, setSearchInputnew] = useState("");

  const searchItemsnew = (searchValuenew) => {
    setSearchInputnew(searchValuenew);
    if (searchInputnew.split("").length > 1) {
      const filtersendernew = Object.keys(showB || {}).filter((item) =>
        item.toLowerCase().includes(searchInputnew.toLowerCase())
      );

      console.log(filtersendernew);
      console.log("Filter Data", filtersendernew, SenderData);
      setShowB(filtersendernew.reduce((a, v) => ({ ...a, [v]: v }), {}));
      console.log(searchValuenew);
    } else {
      setShowB(SenderData);
    }
  };

  console.log("Rendered showA:", showA);
  console.log("Rendered showB:", showB);

  return (
    <div className="container mt-5">
          <h3 className="title_1 withImp mb-4 d-flex">Choose Senders
            <Link to="/importsenders" className="ms-auto">
            {/* <img className="img-fluid me-2" src="Images/contact-icon-v3-small.svg" alt="imagesender" />Import Senders */}
            <button>Import Senders</button>
            </Link>
          </h3>
      <div className="row justify-content-center">
        <div className="dual-list list-left col-md-5">
          <div className="well text-right">
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="btn-group">
                  <input
                    type="checkbox"
                    className="checkBoxSize"
                    onClick={selectShowA}
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <input
                    type="search"
                    name="SearchDualList"
                    className="form-control"
                    placeholder="search"
                    onChange={(e) => searchItems(e.target.value)}
                  />
                  <span className="input-group-addon">
                    <i className="fas fa-search" />
                  </span>
                </div>
              </div>
            </div>
            <ul className="list-group">
              {showA && Array.isArray(showA) && showA.length > 0 ? (
                showA.map((entry, index) => {
                  let username = Object.keys(entry)[0];
                  return (
                    <li
                      className={`list-group-item ${
                        active.includes(username) && "active"
                      }`}
                      key={index}
                      onClick={() => handleClickLeft(username)}
                    >
                      {username}
                    </li>
                  );
                })
              ) : (
                <li>No Senders</li>
              )}
            </ul>
          </div>
        </div>
        <div className="list-arrows col-md-1 text-center d-flex flex-column justify-content-center align-items-center">
          <button
            className="btn btn-default btn-sm move-right"
            onClick={setDataRight}
          >
            <i className="far fa-arrow-square-right" />
          </button>
          <button
            className="btn btn-default btn-sm move-left"
            onClick={setDataLeft}
          >
            <i className="far fa-arrow-square-left" />
          </button>
        </div>
        <div className="dual-list list-right col-md-5">
          <div className="well">
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="btn-group">
                  <input
                    type="checkbox"
                    className="checkBoxSize"
                    onClick={selectShowB}
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <input
                    type="text"
                    name="SearchDualList"
                    className="form-control"
                    placeholder="search"
                    onChange={(e) => searchItemsnew(e.target.value)}
                  />
                  <span className="input-group-addon">
                    <i className="fas fa-search" />
                  </span>
                </div>
              </div>
            </div>
            <ul className="list-group">
              {Object.keys(showB || {}).map((show, index) => (
                <li
                  className={`list-group-item ${
                    rightActive.includes(show) && "active"
                  }`}
                  key={index}
                  onClick={() => handleClickRight(show)}
                >
                  {show}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <NavLink to={"/"}>
          <button type="button" className="btn-alternate me-4 text-black">
            Back
          </button>
        </NavLink>

        <button
          type="button"
          className="btn-primary me-5 text-black"
          onClick={onSubmit}
        >
          Next
        </button>
        <ToastContainer />
      </div>
    </div>
  );
}

export default SenderListing;
