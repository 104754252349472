import React, { useEffect, useState } from "react";
 import "./style,.css";

const manage_models = [
  {
    _id: 1,
    img: "https://www.google.com/s2/favicons?sz=32&domain_url=https%3A%2F%2Fopenai.com",
    title: "Open AI",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus accusantium tempora voluptatibus odio nobis optio ratione et voluptatum! Fuga est ipsa minus in ipsum laboriosam cum possimus ab placeat eum.",
  },
  {
    _id: 2,
    img: "https://www.google.com/s2/favicons?sz=32&domain_url=https%3A%2F%2Fllama.meta.com",
    title: "Llama",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus accusantium tempora voluptatibus odio nobis optio ratione et voluptatum! Fuga est ipsa minus in ipsum laboriosam cum possimus ab placeat eum.",
  },
];

const ManageModel = ({ onNextStep, onBackStep }) => {
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(1);

  useEffect(() => {
    try {
      setModels(manage_models);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <h4>Choose the model</h4>
      <div className="models-grid">
        {models?.map((model) => (
          <div
            onClick={() => setSelectedModel(model._id)}
            className={`model-card-dim data-sources-card ${
              selectedModel == model._id ? "active-model" : ""
            }`}
            key={model._id}
          >
            <input
              type="radio"
              name="selectedTitle"
              checked={selectedModel === model._id}
              onChange={() => setSelectedModel(model._id)}
            />
            <div className="model-card-content">
              <img src={model.img} alt="image" />
              <h4>{model.title}</h4>
              <p>{model.desc}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="step-btn-cover"></div>
      <div className="steps-btn">
        <button type="button" className="back" onClick={() => onBackStep()}>
          Back
        </button>
        <button type="button" className="next" onClick={() => onNextStep()}>
          Next
        </button>
      </div>
    </>
  );
};

export default ManageModel;
