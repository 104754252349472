import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { TbLayoutCollage } from "react-icons/tb";
import { allApi } from "../../../../ApiWrapper";
import { toast } from "react-toastify";

const DateFormatter = (unFormattedDate) => {
  const date = new Date(unFormattedDate);
  const formattedDate =
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedTime = hours + ":" + minutes + ":" + seconds + " " + ampm;
  return formattedDate + " " + formattedTime;
};

const formatErrorMessage = (message) => {
  const parts = message.split(". "); // Split by period and space
  return parts.map((part, index) => (
    <span key={index}>
      {part}.<br />
    </span>
  ));
};

function Notification() {
  const [isActive, setIsActive] = useState(false);
  const UserId = useSelector((state) => state.all?.auth?.data?._id);
  const User = useSelector((state) => state.all?.auth?.data);
  const { active } = useParams();
  const [activeTab, setActiveTab] = useState(parseInt(active) || 0);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [member, setMember] = useState([]);
  const [memberId, setMmeberId] = useState("");
  const [memberindex, setMemberIndex] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toggleFullScree = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    setActiveTab(parseInt(active) || 0);
  }, [active]);
  const [profileListTab, setProfileListTab] = useState(1);

  const tabBody = (tabIndex) => {
    setProfileListTab(tabIndex);
  };

  const getUserNotification = async () => {
    setIsLoading(true);
    try {
      const response = await allApi({
        url: `https://us2.sourcesoftsolutions.com:5006/errors?user_id=${UserId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        formData: false,
      });
      console.log(response);
      setNotifications(response?.data);
    } catch (err) {
      console.log(err);
      toast.error(err?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserNotification();
  }, []);
  console.log("notifications", notifications);
  return (
    <>
      <section className="usrPrfSection">
        <div className="left">
          <div className="leftMenu">
            {/* <div className="leftSideBar"> */}
            <div className="logoItem"></div>
            <div className="menuBar">
              <ul>
                <li
                  className={profileListTab === 1 ? "active" : ""}
                  onClick={() => tabBody(1)}
                ></li>
                <li>
                  <Link to="/NotificcationData">
                    <TbLayoutCollage className="icon" /> Notification
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mainContent me-3 mt-5">
          <div className="row">
            <div className="col-xl-12">
              <div
              // className={`profileTabBody ${
              //   profileListTab === 2 ? "active" : ""
              // }`}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>User Name</th>
                      <th scope="col">Error</th>
                      <th scope="col">Error Type</th>
                      <th scope="col">Model</th>
                      <th scope="col">Agent</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications.length !== 0 ? (
                      notifications.map((notification, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{notification.user_name}</td>
                          <td>{formatErrorMessage(notification.message)}</td>
                          <td>{notification?.type}</td>
                          <td>{notification?.model}</td>
                          <td>{notification.agent_factory}</td>
                          <td>
                            {notification.timestamp
                              ? DateFormatter(notification.timestamp)
                              : ""}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <>No records found</>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Notification;
