import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import EmailLayout from "../../Page/Email/EmailLayout/EmailLayout";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import RPA from "../DataSources/DataSources/RPA";

function UserProfile() {
  const [isActive, setIsActive] = useState(false);
  const UserId = useSelector((state) => state.all?.auth?.data?._id);
  const User = useSelector((state) => state.all?.auth?.data);
  const { active } = useParams();
  const [activeTab, setActiveTab] = useState(parseInt(active) || 0);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [member, setMember] = useState([]);
  const [memberId, setMmeberId] = useState("");
  const [memberindex, setMemberIndex] = useState(null);
  const toggleFullScree = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    setActiveTab(parseInt(active) || 0);
  }, [active]);

  const toggleTab = (index) => {
    if (activeTab === index) {
      //   navigate("/my_files");
      setActiveTab(0);
    } else {
      //   navigate(`/my_files/${index}`);
      setActiveTab(index);
    }
  };

  const [profileListTab, setProfileListTab] = useState(1);

  const tabBody = (tabIndex) => {
    setProfileListTab(tabIndex);
  };

  const fetchMember = async () => {
    try {
      const data = await fetch(
        `https://api.dalaalstreet.com/team/leads/${UserId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const response = await data.json();
      if (response) {
        console.log("response", response);
        setMember(response?.members);
      } else {
        console.error("Error updating user data:", response.message);
      }
    } catch {}
  };
  useEffect(() => {
    fetchMember();
  }, []);

  console.log("memberindex", memberindex);
  return (
    <>
      <section className={`usrPrfSection ${isActive ? "active" : ""}`}>
        <div className="leftPanel">
          <div className="leftMenu">
            <button className="arrowBtn" onClick={toggleFullScree}>
              <FaAngleLeft />
            </button>
            <div className="profilePic">{`${User?.firstName} ${User?.lastName}`}</div>
            <div className="mb-4">
              <ul className="menuList px-4">
                <li
                  className={profileListTab === 1 ? "active" : ""}
                  onClick={() => tabBody(1)}
                >
                  <a href="#">View Tasks</a>
                </li>

                <li
                  className={profileListTab === 2 ? "active" : ""}
                  onClick={() => tabBody(2)}
                >
                  <a href="#"> My Mail Box</a>
                </li>
                <li
                  className={profileListTab === 3 ? "active" : ""}
                  onClick={() => tabBody(3)}
                >
                  <a href="#">Data Sources</a>
                </li>
                <li
                  className={profileListTab === 4 ? "active" : ""}
                  onClick={() => tabBody(4)}
                >
                  <a href="#">Tasks</a>
                </li>
              </ul>
            </div>

            <div className="">
              <h3 className="teamTitle">Team Members</h3>
              <ul className="menuList px-4">
                {member &&
                  member?.length > 0 &&
                  member?.map((ele, index) => {
                    return (
                      <>
                        <li
                          //   className={memberindex === index ? "active" : ""}
                          onClick={() => tabBody(5)}
                        >
                          <a
                            href="#"
                            onClick={() => {
                              setMmeberId(ele?._id);
                              setMemberIndex(index);
                            }}
                          >{`${ele?.firstName} ${ele?.lastName}`}</a>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className="mainContent">
          <div className="row">
            <div className="col-xl-12">
              <div
                className={`profileTabBody ${
                  profileListTab === 2 ? "active" : ""
                }`}
              >
                <EmailLayout memberId={UserId} />
              </div>

              <div
                className={`profileTabBody ${
                  profileListTab === 3 ? "active" : ""
                }`}
              >
                <RPA />
              </div>
              <div
              // className={`
              //  profileTabBody ${
              //    profileListTab === 5 && memberId ? "active" : ""
              //  }`}
              >
                <EmailLayout memberId={memberId} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserProfile;
