import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getDataApi,
  getEMAILApiUrl,
  postDataApi,
  postEmail,
} from "../../ApiWrapper/index";
import {
  EMAILS_INBOX,
  EMAILS_OUTBOX,
  EMAILS_UNREAD,
  SELECTED_EMAIL,
  INDEX_EMAIL,
  SENDER_UPLOAD,
  RECEIVER_UPLOAD,
  TEMPLATE_UPLOAD,
  TEMPLATE_DATA,
  SENDER_DATA,
  RECEIVER_DATA,
  UNIQUE_ID,
  SAVE_SENDER_LIST,
  SAVE_RECEIVER_LIST,
  FILTER_SENDER,
  FILTER_RECEIVER,
  FILTER_BODY,
  SEND_NOW,
  SEND_LATER,
  SENDER_EMAIL,
  RECEIVER_EMAIL,
  CHOOSE_EMAIL,
  EMAILS_LOADING,
  CLEAR_SENDER_DATA,
  SET_SELECTED_EMAIL_DATA,
  AUTH,
  REGISTER,
  FORGET_PASSWORD,
  SET_SELECTED_CATEGORY,
  CLEAR_CATEGORY_DATA,
  RESET_SELECTED_EMAIL,
  CONFIGURATION,
  POPUP,
  SET_SELECTED_SENDERS,
  SET_SELECTED_RECEVIERS,
  SET_SELECTED_CONFIG,
  NEW_INBOX,
  NEW_OUTBOX,
  NEW_UNREAD,
  SET_LOADER,
  CONFIRM_PASSWORD,
  TEMPLATE,
} from "./Type";

const getApiUrl = (endpoint) => {
  if (
    endpoint === AUTH ||
    endpoint === REGISTER ||
    endpoint === FORGET_PASSWORD ||
    endpoint === CONFIRM_PASSWORD
  ) {
    return process.env.REACT_APP_GOOGLE_MAP_DATA_URL;
  }
  return process.env.REACT_APP_BACKEND_URL;
};

const getApi = (endpoint) => {
  if (
    endpoint === AUTH ||
    endpoint === REGISTER ||
    endpoint === FORGET_PASSWORD ||
    endpoint === CONFIRM_PASSWORD
  ) {
    return process.env.REACT_APP_GOOGLE_MAP_DATA_URL;
  }
  return process.env.REACT_APP_BACKEND_NEW_URL;
};
// const getEMAILApiUrl = (endpoint) => {
//   return process.env.REACT_APP_EMAIL_BACKEND_URL;
// };

const setLoginDetails = (payload) => {
  localStorage.setItem("Token", payload.data.token);
  return {
    type: AUTH,
    payload: { ...payload, session: true },
  };
};

export const registerUser = (payload) => {
  return {
    type: REGISTER,
    payload: payload,
  };
};

export const forgetPassword = (payload) => {
  return { FORGET_PASSWORD, payload: payload };
};

export const setEmailInbox = (payload) => ({
  type: EMAILS_INBOX,
  payload: payload,
});

export const setEmailOutbox = (payload) => ({
  type: EMAILS_OUTBOX,
  payload: payload,
});

export const setEmailUnread = (payload) => ({
  type: EMAILS_UNREAD,
  payload: payload,
});

export const setConfiguration = (payload) => ({
  type: CONFIGURATION,
  payload: payload,
});

export const setSelectedEmail = (payload) => ({
  type: SELECTED_EMAIL,
  payload: payload,
});

export const setIndexEmail = (payload) => ({
  type: INDEX_EMAIL,
  payload: payload,
});

export const setSenderEmail = (payload) => ({
  type: SENDER_EMAIL,
  payload: payload,
});

export const setReceiverEmail = (payload) => ({
  type: RECEIVER_EMAIL,
  payload: payload,
});

export const setChooseEmail = (payload) => ({
  type: CHOOSE_EMAIL,
  payload: payload,
});

const setSenderUpload = (payload) => ({
  type: SENDER_UPLOAD,
  payload: payload,
});

const setReceiverUpload = (payload) => ({
  type: RECEIVER_UPLOAD,
  payload: payload,
});

const setTemplateUpload = (payload) => ({
  type: TEMPLATE_UPLOAD,
  payload: payload,
});

const setTemplateData = (payload) => ({
  type: TEMPLATE_DATA,
  payload: payload,
});

const setSenderData = (payload) => ({
  type: SENDER_DATA,
  payload: payload,
});

export const setFilterSender = (payload) => ({
  type: FILTER_SENDER,
  payload: payload,
});

export const setFilterReceiver = (payload) => ({
  type: FILTER_RECEIVER,
  payload: payload,
});

export const setFilterBody = (payload) => ({
  type: FILTER_BODY,
  payload: payload,
});

const setReceiverData = (payload) => ({
  type: RECEIVER_DATA,
  payload: payload,
});

const setUniqueId = (payload) => ({
  type: UNIQUE_ID,
  payload: payload,
});

export const setSaveReceiverData = (payload) => ({
  type: SAVE_RECEIVER_LIST,
  payload: payload,
});

export const setSaveSenderData = (payload) => ({
  type: SAVE_SENDER_LIST,
  payload: payload,
});

const setSendNow = (payload) => ({
  type: SEND_NOW,
  payload: payload,
});

const setSendLater = (payload) => ({
  type: SEND_LATER,
  payload: payload,
});

export const setEmailLoading = (payload) => ({
  type: EMAILS_LOADING,
  payload: payload.emailsloading,
});

export const clearSenderData = () => {
  return {
    type: CLEAR_SENDER_DATA,
  };
};

export const setSelectedEmailData = (data) => {
  return {
    type: SET_SELECTED_EMAIL_DATA,
    payload: data,
  };
};

export const setCategory = (category) => {
  return {
    type: SET_SELECTED_CATEGORY,
    payload: category,
  };
};

export const clearCategoryData = () => {
  return {
    type: CLEAR_CATEGORY_DATA,
  };
};

export const resetSelectedEmailData = () => {
  return {
    type: RESET_SELECTED_EMAIL,
  };
};

export const setPopup = (data) => {
  return {
    type: POPUP,
    payload: data,
  };
};

export const setSelectedSenders = (senders) => ({
  type: SET_SELECTED_SENDERS,
  payload: senders,
});

export const setSelectedReceviers = (senders) => ({
  type: SET_SELECTED_RECEVIERS,
  payload: senders,
});

export const setSelectedConfig = (senders) => ({
  type: SET_SELECTED_CONFIG,
  payload: senders,
});

export const setLoader = (data) => ({
  type: SET_LOADER,
  payload: data,
});

export const setConfirm = (data) => ({
  type: CONFIRM_PASSWORD,
  payload: data,
});
export const setTemplate = (data) => {
  return {
    type: TEMPLATE,
    payload: data,
  };
};

// Get data from endpoint
export const getPayload =
  (baseEndPoint, queryParams = {}) =>
  (dispatch) => {
    return getDataApi(baseEndPoint, queryParams)
      .then((response) => response.data)
      .then((data) => {
        switch (baseEndPoint) {
          case SENDER_DATA:
            dispatch(setSenderData(data.data_summary));
            break;

          case RECEIVER_DATA:
            dispatch(setReceiverData(data.data_summary));
            break;

          case UNIQUE_ID:
            dispatch(setUniqueId(data.data_summary));
            break;

          case TEMPLATE_DATA:
            dispatch(setTemplateData(data.data_summary));
            break;

          default:
            return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

export const SubmitPost =
  (baseEndPoint, queryParams = {}) =>
  (dispatch) => {
    const baseUrl = getApiUrl(baseEndPoint);
    return postDataApi(baseEndPoint, queryParams, baseUrl)
      .then((response) => response.data)
      .then((data) => {
        console.log("inboxdata", data);
        let newBaseEndPoint = baseEndPoint?.split("?")?.[0];
        switch (newBaseEndPoint) {
          case AUTH:
            return dispatch(setLoginDetails(data));
          case REGISTER:
            return dispatch(registerUser(data));

          case EMAILS_INBOX:
            dispatch(setEmailInbox(data));
            break;
          case EMAILS_OUTBOX:
            dispatch(setEmailOutbox(data));
            break;
          case EMAILS_UNREAD:
            dispatch(setEmailUnread(data));
            break;
          case SENDER_UPLOAD:
            console.log("data>>>>>>", data);
            dispatch(setSenderUpload(data));
            return data;
            break;
          case RECEIVER_UPLOAD:
            dispatch(setReceiverUpload(data));
            break;
          case TEMPLATE_UPLOAD:
            dispatch(setTemplateUpload(data));
            break;
          case SAVE_SENDER_LIST:
            dispatch(setSaveSenderData(data));
            break;
          case CONFIRM_PASSWORD:
            dispatch(setConfirm(data));
            return data;
            break;
          // case SAVE_RECEIVER_LIST:
          //   dispatch(setSaveReceiverData(data));
          //   break;
          case SEND_NOW:
            dispatch(setSendNow(data));
            return data;
            break;
          case SEND_LATER:
            dispatch(setSendLater(data));
            break;
          default:
            return data;
        }
      })
      .catch((error) => {
        console.log("API call error:", error);
        // console.error(error?.response?.data?.message || "Server error!");
        toast.error(error?.response?.data?.message || "Server error!");
        return error.response;
      });
  };

export const setNewInbox = (data) => ({
  type: NEW_INBOX,
  payload: data,
});

export const EmailPost =
  (baseEndPoint, queryParams = {}) =>
  (dispatch) => {
    const baseUrl = getApi(baseEndPoint);
    return postEmail(baseEndPoint, queryParams, baseUrl)
      .then((response) => response.data)
      .then((data) => {
        console.log("inboxdata", data);
        let newBaseEndPoint = baseEndPoint?.split("?")?.[0];
        switch (newBaseEndPoint) {
          case AUTH:
            return dispatch(setLoginDetails(data));

            return dispatch(registerUser(data));
          case EMAILS_INBOX:
            dispatch(setEmailInbox(data));
            console.log("indoxdata", data);
            return data;
            break;
          case EMAILS_OUTBOX:
            dispatch(setEmailOutbox(data));
            break;
          case EMAILS_UNREAD:
            dispatch(setEmailUnread(data));
            break;
          case SENDER_UPLOAD:
            console.log("data>>>>>>", data);
            dispatch(setSenderUpload(data));
            console.log("upload", data);
            return data;
            break;
          case RECEIVER_UPLOAD:
            console.log("data>>>>>>", data);
            dispatch(setReceiverUpload(data));
            return data;
            break;
          case NEW_INBOX:
            dispatch(setNewInbox(data));
            return data;
            break;
          case NEW_OUTBOX:
            dispatch(setNewInbox(data));
            return data;
          case NEW_UNREAD:
            dispatch(setNewInbox(data));
            return data;
            dispatch(setSendLater(data));
            break;
          default:
            return data;
        }
      })
      .catch((error) => {
        console.log("API call error:", error);
        console.error(error.message);
        toast.error(error?.message);
        // return error.response;
      });
  };

export const getEmailPayload =
  (baseEndPoint, queryParams = {}) =>
  (dispatch) => {
    return getEMAILApiUrl(baseEndPoint, queryParams)
      .then((response) => response.data)
      .then((data) => {
        switch (baseEndPoint) {
          case EMAILS_INBOX:
            dispatch(setEmailInbox(data));
            console.log("indoxdata", data);
            return data;
            break;
          case EMAILS_OUTBOX:
            dispatch(setEmailOutbox(data));
            return data;
            break;
          case EMAILS_UNREAD:
            dispatch(setEmailUnread(data));
            return data;
            break;
          case CONFIGURATION:
            dispatch(setConfiguration(data));
            return data;
          case SENDER_DATA:
            dispatch(data.data_summary);
            break;

          case SAVE_RECEIVER_LIST:
            dispatch(setSaveReceiverData(data));
            break;
          case SAVE_SENDER_LIST:
            dispatch(setSaveSenderData(data));
            break;
          default:
            return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
