import React, { useState } from "react";
import { API } from "../../ApiWrapper";
import { toast } from "react-toastify";
import { ProgressBar } from "react-loader-spinner";

const DbData = [
  {
    id: 1,
    user: "realestate_wp_prod",
    database_name: "realestate_wp_prod",
    password: "Hn$f&uQed76iUp",
    host: "192.168.1.40:3307",
  },
  {
    id: 2,
    user: "service_provider_api",
    database_name: "service_provider_api",
    password: "Hn!dRcUm&4fdd",
    host: "192.168.0.11",
  },
  {
    id: 3,
    user: "direct_booking",
    database_name: "direct_booking",
    password: "Hb!&hk87jy&hn",
    host: "192.168.1.40",
  },
  {
    id: 4,
    user: "llmusecase_email",
    database_name: "llmusecase_email",
    password: "gh4RFjjG9&S4o1xcn",
    host: "192.168.0.11",
  },
];

const DbConnector = ({ close, onDataSources }) => {
  const [selectedDb, setSelectedDb] = useState();
  const [isLoading, setIsLoading] = useState(null);

  const handleSubmit = async () => {
    if (!selectedDb) return;
    const db = DbData.filter((db) => db.id == selectedDb);
    try {
      setIsLoading(true);

      const payload = {
        url: "/sql_connection",
        method: "POST",
        body: db[0],
        formData: false,
      };
      const response = await API(payload);

      toast.success(response?.payload);
      onDataSources([
        { type: "URL/db", filename: db[0].user, file_path: `${db[0].user}.db` },
      ]);
      close();
      setIsLoading(false);
    } catch (err) {
      toast.warning("something went wrong");
      setIsLoading(false);
    }
  };

  return (
    <>
      <h5>Database Details</h5>
      <div className="url-container">
        <select
          name="db"
          id="db"
          value={selectedDb}
          onChange={(e) => setSelectedDb(e.target.value)}
        >
          <option value="">Select a database</option>
          {DbData.map((db, index) => (
            <option value={db.id} key={index}>
              {db.user}
            </option>
          ))}
        </select>
      </div>
      <div className="file-submit">
        {isLoading ? (
          <div className="file-loading-bar">
            <ProgressBar
              height={40}
              width={40}
              barColor="#000"
              borderColor="#000"
            />
            <h6>Processing</h6>
          </div>
        ) : (
          <button onClick={handleSubmit}>Submit</button>
        )}
      </div>
    </>
  );
};

export default DbConnector;
