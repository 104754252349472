import React from "react";
import RequestCard from "./RequestCard";

function RightSidebar() {
  return (
    <>
      <RequestCard />
    </>
  );
}

export default RightSidebar;
