import React from "react";
import ImportSender from "./ImportSender";

function Sender() {
  return (
    <>
      <ImportSender />
    </>
  );
}

export default Sender;
