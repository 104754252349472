import React, { useState } from "react";
import { toast } from "react-toastify";

const Payment = () => {
  const [quantity, setQuantity] = useState(1); // State to manage quantity
  const [totalPrice, setTotalPrice] = useState(100); // State to manage total price, starting with $100

  const addPaymentGateway = async () => {
    try {
      const payload = {
        product_name: "Custom T-shirt",
        price: totalPrice,
        quantity: quantity,
      };

      // Replace your fetch logic here
      await fetch(
        "https://us2.sourcesoftsolutions.com:8062/create-checkout-session",
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          // Redirect to the payment URL
          window.location.href = data.url;
        });
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to initiate payment");
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1); // Increment quantity by 1
    setTotalPrice(totalPrice + 100); // Increase total price by $100 per quantity
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1); // Decrement quantity by 1, but not below 1
      setTotalPrice(totalPrice - 100); // Decrease total price by $100 per quantity
    }
  };

  return (
    <>
      <section className="whyChosefeatures" id="why">
        <div className="container">
          <h2 className="featuresh2 tabheading">Payment Plans</h2>
          <h3 className="text-center">Payment Plans</h3>
        </div>
      </section>
      <div className="pricecontainer">
        <div className="price-row d-flex justify-content-center">
          <div className="price-col col-sm-5">
            <p>Basic Plans</p>
            <hr />
            <h3>
              <span>${totalPrice}</span>
            </h3>
            {/* <p>
              Ideal for small businesses and startups looking to get started
              with AI-powered customer engagement. Features include intelligent
              responses, basic integration with business systems, and essential
              support.
            </p> */}
            <div className="quantity-controls">
              <button onClick={decreaseQuantity}>-</button>
              <span>Quantity: {quantity}</span>
              <button onClick={increaseQuantity}>+</button>
            </div>
            <button onClick={addPaymentGateway}>Payment</button>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default Payment;
