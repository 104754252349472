import React, { useState, useEffect } from "react";
import { RiMailSendLine } from "react-icons/ri";
import Dropdown from "react-bootstrap/Dropdown";
import { MdOutlineModeEdit, MdOutlineAddToPhotos } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  EmailPost,
  SubmitPost,
  getEmailPayload,
  setConfiguration,
  setPopup,
  setSelectedConfig,
} from "../../../../Store/Action";
import {
  CONFIGURATION,
  NEW_INBOX,
  NEW_OUTBOX,
  NEW_UNREAD,
  SEND_NOW,
} from "../../../../Store/Action/Type";
import CkEditiorListing from "../../../../Components/MarketResearch/CkEditiorListing";
import { Oval } from "react-loader-spinner";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);


function Compose() {
  //   const template = localStorage.getItem("template");
  const newdata = localStorage.getItem("data");
  const [data, setData] = useState(JSON.parse(newdata) || {});
  const UserId = useSelector((state) => state.all?.auth?.data?._id);
  const emailId = useSelector((state) => state?.all?.auth?.data?.email);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [show, setshow] = useState(false);
  const [template, setTemplate] = useState("");
  const [configData, setConfigData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [configrationselect, setConfigrationselect] = useState("");
  const [editSendershow, setEditSendershow] = useState(false);
  const [configid, setConfigid] = useState(false);
  const configuration = useSelector((state) => state?.all?.configuration);
  const [sechdule, setSchedule] = useState([]);
  const [timezone, setTimezone] = useState("");

  // const [Data, setdata] = useState("");
  const [editiordata, setEditiordata] = useState("");

  console.log(editiordata,"editiordataeditiordata")
  useEffect(() => {
    const storedTemplate = localStorage.getItem("template");
    console.log("storedTemplate", storedTemplate);

    // setTemplate(JSON.parse(storedTemplate));

    const storedData = localStorage.getItem("data");
    if (storedData) {
      setData(JSON.parse(storedData));
    }
  }, []);

  const fetchData = async () => {
    try {
      const payload = {
        user_id: UserId,
        email_id: "echobyte76@gmail.com",
        criteria: "newest",
      };
      await dispatch(EmailPost(NEW_INBOX, payload));
      await dispatch(EmailPost(NEW_OUTBOX, payload));
      await dispatch(EmailPost(NEW_UNREAD, payload));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    localStorage.setItem("data", JSON.stringify(data));
  }, [data]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!template) {
      toast.error("Please select a template.");
      return;
    }

    if (!data.subject) {
      toast.error("Subject is required.");
      return;
    }

    const emailBody =
      "<!DOCTYPE html><html><body>" +
      // template?.body +
      editiordata +
      "</body></html>";
    if (emailBody === "<!DOCTYPE html><html><body></body></html>") {
      toast.error("Email body cannot be empty.");
      return;
    }
    setLoading(true);

    try {
      if (sechdule?.send_type === "1") {
        const payload = {
          user_id: UserId,
          configuration_id: configid,
          subject: data?.subject,
          body: emailBody,
          timezone: timezone,
        };
        const response = await fetch(
          "https://us2.sourcesoftsolutions.com:8062/SendNow",
          {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log("responseData", responseData);

        if (responseData?.status) {
          setLoading(false);
          toast.success(responseData?.message);
          navigate("/");
          localStorage.removeItem("template");
          localStorage.removeItem("data");
          fetchData();
        }
      } else {
        const scheduleDate = new Date(sechdule?.schedule);

        const pad = (num) => num.toString().padStart(2, "0");

        const formattedSchedule = `${scheduleDate.getUTCFullYear()}-${pad(
          scheduleDate.getUTCMonth() + 1
        )}-${pad(scheduleDate.getUTCDate())}T${pad(
          scheduleDate.getUTCHours()
        )}:${pad(scheduleDate.getUTCMinutes())}:${pad(
          scheduleDate.getUTCSeconds()
        )}.000+00:00`;

        const payload = {
          user_id: UserId,
          configuration_id: configid,
          subject: data?.subject,
          body: emailBody,
          schedule: formattedSchedule,
          status: "pending",
          timezone: timezone,
        };

        console.log("payloadpayloadpayloadpayloadpayload", emailBody);

        const response = await fetch(
          "https://us2.sourcesoftsolutions.com:8062/createScheduleMail",
          {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log("responseData", responseData);

        if (responseData?.status) {
          setLoading(false);
          toast.success(responseData?.message);
          navigate("/");
          localStorage.removeItem("template");
          localStorage.removeItem("data");
          fetchData();
        }
      }
    } catch (error) {
      toast.error("Error sending email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubjectChange = (e) => {
    setData({ ...data, subject: e.target.value });
  };

  const Loader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          zIndex: "1000",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}
      >
        <Oval
          visible={true}
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="oval-loading"
        />
      </div>
    );
  };

  const HandleModal = () => {
    console.log("call");
    // setshow(true);
    dispatch(setPopup({ modalType: "SENDER", showModal: true }));
  };

  const getConfig = async () => {
    try {
      dispatch(getEmailPayload(`${CONFIGURATION}?user_id=${UserId}`)).then(
        (data) => {
          console.log("data?.data", data?.data);
          dispatch(setConfiguration(data?.data));
          setConfigData(data?.data);
        }
      );
    } catch {}
  };

  useEffect(() => {
    getConfig();
  }, []);

  const getConfigData = async (id) => {
    try {
      const response = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/configuration-detail?user_id=${UserId}&configuration_id=${id}`
      );
      const ConfigData = await response.json();

      setTimezone(ConfigData?.data?.configuration?.timezone);
      setConfigid(id);
      localStorage.setItem("Configdata", JSON.stringify(ConfigData));
      setSchedule(ConfigData?.data?.configuration);
      console.log("ConfigData?.data?.template", ConfigData?.data);
      setTemplate(ConfigData?.data?.template);
      setDropdownOpen(false);
    } catch {}
  };
  // console.log("sechdule>>>>>>>", template?.body);

  const handleDelete = async (id) => {
    const payload = {
      is_deleted: true,
    };
    try {
      const dataremove = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/updateConfiguration?configuration_id=${id}&user_id=${UserId}`,
        {
          method: "PUT",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const response = await dataremove.json();
      if (response) {
        toast.success(response?.message);
        getConfig();
      }
    } catch {}
  };

  const handleEdit = async (id) => {
    console.log("calling");
    console.log(id, "sbjhshsjhsh");
    try {
      const response = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/configuration-detail?user_id=${UserId}&configuration_id=${id}`
      );
      const ConfigData = await response.json();

      // localStorage.setItem("EditConfigdata", JSON.stringify(ConfigData));
      dispatch(setSelectedConfig(ConfigData));
      setDropdownOpen(false);
      dispatch(setPopup({ modalType: "SENDER", showModal: true }));
    } catch {}
  };

  const handleClone = async (id) => {
    console.log("calling");
    console.log(id, "sbjhshsjhsh");
    try {
      const response = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/configuration-detail?user_id=${UserId}&configuration_id=${id}`
      );
      const ConfigData = await response.json();
      console.log("ConfigData", ConfigData);
      if (ConfigData) {
        const scheduleDate = new Date(
          ConfigData?.data?.configuration?.schedule
        );

        const pad = (num) => num.toString().padStart(2, "0");

        const formattedSchedule = `${scheduleDate.getUTCFullYear()}-${pad(
          scheduleDate.getUTCMonth() + 1
        )}-${pad(scheduleDate.getUTCDate())}T${pad(
          scheduleDate.getUTCHours()
        )}:${pad(scheduleDate.getUTCMinutes())}:${pad(
          scheduleDate.getUTCSeconds()
        )}.000+00:00`;
        const payload = {
          user_id: UserId,
          name: `${ConfigData?.data?.configuration?.name} copy`,
          senders_id: ConfigData?.data?.configuration?.senders_id,
          receivers_id: ConfigData?.data?.configuration?.receivers_id,
          schedule: formattedSchedule,
          send_type: ConfigData?.data?.configuration?.send_type,
          template_id: ConfigData?.data?.configuration?.template_id,
        };
        const createConfig = await fetch(
          "https://us2.sourcesoftsolutions.com:8062/configurations",
          {
            method: "POST",
            body: JSON?.stringify(payload),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseClone = await createConfig.json();
        if (responseClone) {
          toast.success(responseClone?.message);
          dispatch(getEmailPayload(`${CONFIGURATION}?user_id=${UserId}`)).then(
            (data) => {
              console.log("data?.data", data?.data);
              dispatch(setConfiguration(data?.data));
              // setConfigData(data?.data);
              // getConfigData();
            }
          );
        }
      }

      setDropdownOpen(false);
    } catch {}
  };

  const confirmDelete = (id) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: "Do you really want to Delete this Configuration?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
     
      }
    })
  }

  return (
    <>
      <section className="sendEmail p-4 d-flex">
        {Loading ? (
          <Loader />
        ) : (
          <button className="sendBtn" onClick={submitHandler}>
            <RiMailSendLine className="icon" /> <p>Send</p>
          </button>
        )}
        <div className="ms-4 w-100">
          <div className="formGroup d-flex align-items-center mb-3">
            <label className="m-0">Configuration:</label>
            <Dropdown
              className="myConfig"
              show={dropdownOpen}
              onToggle={(isOpen) => setDropdownOpen(isOpen)}
            >
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="btn schudule"
              >
                {configrationselect
                  ? configrationselect
                  : "Configurations List"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="creatNew">
                  <button onClick={HandleModal}>
                    + Create New Configuration
                  </button>
                </div>
                {configuration &&
                  configuration?.length > 0 &&
                  configuration
                    //   ?.filter((ele) => !ele.is_deleted)
                    ?.map((ele) => {
                      console.log("ele", ele);

                      return (
                        <>
                          <div
                            key={ele._id}
                            className="listItm d-flex gap-3 align-items-center border-bottom py-2 px-3"
                            onClick={() => {
                              getConfigData(ele?._id);
                              setConfigrationselect(ele?.name);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {ele?.name}
                            <a
                              className="ms-auto"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <MdOutlineModeEdit
                                className="icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEdit(ele?._id);
                                }}
                              />
                            </a>
                            <a>
                              <MdOutlineAddToPhotos
                                className="icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleClone(ele?._id);
                                }}
                              />
                            </a>
                            <a
                              onClick={(e) => {
                                e.stopPropagation();
                                confirmDelete(ele?._id);
                              }}
                            >
                              <RiDeleteBin6Line className="icon" />
                            </a>
                          </div>
                        </>
                      );
                    })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="formGroup d-flex align-items-center">
            <label className="m-0">Subject:</label>
            <input
              className="m-0 form-control"
              type="text"
              value={data.subject}
              onChange={handleSubjectChange}
            />
          </div>
        </div>
      </section>

      <CkEditiorListing contentdata={template?.body} setdata={setEditiordata} />
    </>
  );
}

export default Compose;
