import React, { useEffect, useState } from "react";
import {
  BsStar,
  BsClock,
  BsCollection,
  BsSoundwave,
  BsHeadset,
  BsTrash,
  BsFillChatLeftTextFill,
  BsFileEarmarkTextFill,
  BsPersonPlus,
  BsList,
  BsX,
} from "react-icons/bs";
// import "./style,.css";

import { useNavigate } from "react-router-dom";

import Labs from "./Labs";

function AITool() {
  const [category, setCategory] = useState({
    _id: "",
    name: "dashboard",
  });

  const [showLeftMenu, setShowLeftMenu] = useState(false);
  const [active, setActive] = useState(1);

  const navigate = useNavigate();

  const handleClick = (ele) => {
    console.log("ele", ele);
    setActive(ele);
  };

  return (
    <section className={`profileMain ${showLeftMenu ? "show" : ""}`}>
      {/* <Header  /> */}
      <div className="menuSectionLeft ">
        {console.log("category dadaddada", category)}
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className={category?.name === "ChatBot" && "col-lg-6 col-xl-8"}>
            {category?.name === "dashboard" ? (
              // <div className="maindash"></div>
              <Labs />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AITool;
