import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getEmailPayload,
  getPayload,
  setPopup,
  setSaveReceiverData,
  setSelectedConfig,
  setSelectedReceviers,
  setSelectedSenders,
  SubmitPost,
} from "../../../../Store/Action";
import { useDispatch } from "react-redux";
import {
  RECEIVER_DATA,
  SAVE_RECEIVER_LIST,
} from "../../../../Store/Action/Type";
import "../Listing/listing.css";

import { ToastContainer } from "react-toastify";

import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

import SelectTemplate from "./SelectTemplate";
import { FaAngleLeft, FaAngleRight, FaListCheck } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";

function ReceiverSelect() {
  const {
    // ReceiverData,
    unique,
  } = useSelector((state) => state.all);

  // const Receiverlist = localStorage.getItem("allrecevier");
  // const ReceiverData = JSON.parse(Receiverlist);

  const dispatch = useDispatch();

  const [show, setshow] = useState(true);
  const [showTemplate, setshowTemplat] = useState(false);
  const popupReducer = useSelector((state) => state?.all);
  const { showModal } = popupReducer?.popupReducer?.modal || {};
  const [showA, setShowA] = useState({});

  const [showB, setShowB] = useState({});

  const [active, setActive] = useState([]);
  const [rightActive, setRightActive] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  // useEffect(()=>{
  //   const fetchReciverDataredux = async () => {

  let ReceiverData = useSelector((state) => state.all?.RECEIVER_LIST);

  console.log(ReceiverData, "ReceiverDataReceiverData000000");

  const selectedItems = useSelector((state) => state?.all?.selectedCategory);
  var result = selectedItems?.map((obj) => ({ [obj.Name]: [obj.Email] }));
  var singleObject = {};
  result?.forEach((obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        singleObject[key] = [obj[key][0]];
      }
    }
  });

  console.log("select business", selectedItems);

  const senderData = localStorage.getItem("EditConfigdata");
  const configData = JSON.parse(senderData);
  console.log("configData", configData);
  const selectedReceviers = useSelector((state) => state?.all);
  console.log("selectedSenders", selectedReceviers?.selectedReceviers);
  // useEffect(() => {
  //   FetchApi();
  // }, [selectedCategory]);

  const selectedConfig = useSelector((state) => state?.all?.selectedConfig);

  useEffect(() => {
    setShowA(ReceiverData);
    if (selectedConfig && selectedConfig.data) {
      const configData = selectedConfig.data.configuration;
      const selectConfig = selectedConfig?.data;

      setShowB(
        ReceiverData?.filter((recevier) =>
          selectedConfig.data.configuration.receivers_id.includes(recevier._id)
        )
      );
    }
    if (selectedReceviers?.selectedReceviers?.length > 0) {
      setShowB(selectedReceviers?.selectedReceviers);
    }

    // console.log("ReceiverDataReceiverData", ReceiverData);
  }, [ReceiverData]);

  const FetchApi = () => {
    // let categoryParam = selectedItems
    //   ? "?category=Business"
    //   : "?category=Marketing";

    const endpoint = `${RECEIVER_DATA}`;

    console.log("Calling API with endpoint:", endpoint);
    console.log("Calling API with selectedCategory:", selectedCategory);
    if (selectedCategory == null) {
      dispatch(getPayload(endpoint));
    } else {
      dispatch({ type: RECEIVER_DATA, payload: singleObject });
    }
    // dispatch(getPayload(endpoint));
    // dispatch({type: RECEIVER_DATA,payload: singleObject});
  };

  const selectShowA = () => {
    let arr = showA;

    if (showA.length === active.length) {
      setActive([]);
    } else {
      setActive(arr);
    }
  };

  const selectShowB = () => {
    let arr = showB;

    if (showB.length === rightActive.length) {
      setRightActive([]);
    } else {
      setRightActive(arr);
    }
  };

  // const handleClickLeft = (val) => {
  //   console.log(val, "val");
  //   let arr = [...active];
  //   let idx = arr.findIndex((ele) => ele === val);
  //   if (idx >= 0) {
  //     arr.splice(idx, 1);
  //   } else {
  //     arr.push(val);
  //   }
  //   setActive(arr);
  // };

  const handleClickLeft = (val) => {
    let arr = [...active];

    let idx = arr.findIndex((ele) => ele === val);
    if (idx >= 0) {
      arr.splice(idx, 1);
    } else {
      arr.push(val);
    }
    console.log("arrhandleClickLeft", arr);
    setActive(arr);
  };

  console.log("activerec", active);
  const handleClickRight = (val) => {
    let arr = [...rightActive];
    let idx = arr.findIndex((ele) => ele === val);
    if (idx >= 0) {
      arr.splice(idx, 1);
    } else {
      arr.push(val);
    }
    setRightActive(arr);
  };

  const setDataRight = () => {
    if (!active.length) {
      return;
    }

    let leftData = [...active];
    // let rightData = { ...showB };

    console.log("leftData", leftData);
    // console.log("rightData", rightData);
    console.log("active>>>>>>>>>>", active);
    // active.forEach((item) => {
    //   leftData.map((single, index) => {
    //     console.log("item", item);
    //     if (single[item]) {
    //       rightData[item] = single[item];
    //     }
    //   });
    // });

    setShowB(active);
  };
  console.log(showB);

  const setDataLeft = () => {
    console.log("callingleft");
    console.log("rightActive", rightActive);
    if (!rightActive.length) {
      return;
    }

    let rightData = [...showB];

    console.log("rightData", rightActive);

    const filteredData = rightData.filter((ele) => {
      return !rightActive.some((item) => item._id === ele._id);
    });

    setShowB(filteredData);
    console.log("rightData", rightData);
    setRightActive([]);
  };

  const onSubmit = async () => {
    // if (active.length === 0) {
    //   toast.error("Please Select Receivers from the left list");
    //   return;
    // }
    console.log("calll");
    if (Object.keys(showB).length < 0) {
      toast.error("Please Select Receivers from the right list");
      return;
    }

    // await new Promise((resolve, reject) => {
    //   try {
    let arr = [];
    for (const iterator of showB) {
      // if (ReceiverData[iterator]) {
      //   arr = [
      //     ...arr,
      //     ...(Array.isArray(showB[iterator]) ? showB[iterator] : []),
      //   ];
      console.log("iterator", iterator);
      console.log("iterator?._id", iterator?._id);

      arr.push(iterator?._id);
      // navigate("/templatelist");
      console.log("arrrecevier", arr);
    }

    const data = localStorage.getItem("data");
    const datanew = JSON.parse(data);
    const newData = { ...datanew, receivers_id: arr };

    //   console.log("newData", newData);

    localStorage.setItem("data", JSON.stringify(newData));
    dispatch(setSelectedReceviers(showB));

    //   // setShowRecevier(true);
    console.log("newData>>>>>", newData);
    dispatch(setPopup({ modalType: "TEMPLATE", showModal: true }));
    // resolve(arr);
    //   } catch (error) {
    //     reject(error);
    //   }
    // }).then((data) => {
    //   dispatch(
    //     SubmitPost(SAVE_RECEIVER_LIST, {
    //       receivers: data,
    //       unique_id: unique,
    //     })
    //   );

    // dispatch(
    //   SubmitPost(SAVE_RECEIVER_LIST, {
    //     receivers: ["vs1996raj2@gmail.com"],
    //     unique_id: unique,
    //   })
    // );
    // });
  };

  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput.split("").length > 1) {
      const filterreceiver = Object.keys(showA || {}).filter((item) =>
        item.toLowerCase().includes(searchInput.toLowerCase())
      );

      console.log(filterreceiver);
      setShowA(filterreceiver.reduce((a, v) => ({ ...a, [v]: v }), {}));
    }
  };

  useEffect(() => {
    setSelectedCategory(selectedItems);
  }, [selectedItems]);

  const handleClose = () => {
    dispatch(setSelectedReceviers());
    dispatch(setSelectedSenders());
    dispatch(setSelectedConfig());
    dispatch(setPopup({ modalType: "", showModal: false }));
  };

  const handleBack = () => {
    dispatch(setPopup({ modalType: "SENDER", showModal: true }));
  };

  const handleUplaod = () => {
    dispatch(setPopup({ modalType: "UPLOAD_RECEVIER", showModal: true }));
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        size="xl"
        className="emailPoup"
      >
        <div className="px-3">
          <div className="row justify-content-center">
            <div className="col-xl-2 border-end">
              <div className="stepsList pt-5">
                <ul className="listOpt">
                  <li className="active">
                    <span>1</span>Chose Senders
                  </li>
                  <li className="active">
                    <span>2</span>Chose Receivers
                  </li>
                  <li>
                    <span>3</span>Chose Template
                  </li>
                  <li>
                    <span>4</span>Chose Time
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-10 ps-4">
              <h3 className="popTitle my-4">
                <FaListCheck className="me-2" /> Create Your Configuration
              </h3>
              <div className="choseSender my-4">
                <h3 className="d-flex justify-content-between mb-1">
                  Choose Receivers
                  {/* <Link to="/receiver"> */}
                  {/* <img
                    className="img-fluid me-2"
                    src="Images/import-accounts-icon-small.svg"
                    alt="imageaccount"
                    onClick={handleUplaod}
                  /> */}
                  {/* </Link> */}
                  <button onClick={handleUplaod}>Import Receivers</button>
                </h3>
                <p>Select receivers from left side and move them to right.</p>
              </div>

              <div className="row">
                <div className="dual-list list-left col-md">
                  <div className="well text-right">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <div className="btn-group checkBoxSize">
                          <input
                            type="checkbox"
                            className="checkBox m-0"
                            onClick={selectShowA}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="serchBox m-0">
                          <input
                            type="text"
                            name="SearchDualList"
                            className="form-control m-0"
                            placeholder="search"
                            onChange={(e) => searchItems(e.target.value)}
                          />
                          <button>
                            <IoSearch />
                          </button>
                        </div>
                      </div>
                    </div>
                    <ul className="list-group">
                      {showA && Array.isArray(showA) && showA.length > 0 ? (
                        showA.map((entry, index) => {
                          let id = entry;
                          return (
                            <li
                              className={`list-group-item ${
                                active.includes(entry) && "active"
                              }`}
                              key={index}
                              onClick={() => handleClickLeft(entry)}
                            >
                              {`${entry?.first_name}  ${entry?.last_name} `}
                            </li>
                          );
                        })
                      ) : (
                        <li>No Receivers</li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="list-arrows col-auto text-center d-flex flex-column justify-content-center align-items-center">
                  <button
                    className="btn btn-default btn-sm move-right"
                    onClick={setDataRight}
                  >
                    <i className="far fa-arrow-square-right" />
                  </button>
                  <button
                    className="btn btn-default btn-sm move-left"
                    onClick={setDataLeft}
                  >
                    <i className="far fa-arrow-square-left" />
                  </button>
                </div>

                <div className="dual-list list-right col-md">
                  <div className="well">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <div className="btn-group checkBoxSize">
                          <input
                            type="checkbox"
                            className="checkBox m-0"
                            onClick={selectShowB}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="serchBox m-0">
                          <input
                            type="text"
                            name="SearchDualList"
                            className="form-control m-0"
                            placeholder="search"
                          />
                          <button>
                            <IoSearch />
                          </button>
                        </div>
                      </div>
                    </div>
                    <ul className="list-group">
                      {/* {selectedReceviers?.selectedReceviers &&
                      selectedReceviers?.selectedReceviers?.length > 0
                        ? selectedReceviers?.selectedReceviers?.map(
                            (show, index) => {
                              return (
                                <>
                                  <li
                                    className={`list-group-item ${
                                      rightActive.includes(show) && "active"
                                    }`}
                                    key={index}
                                    onClick={() => handleClickRight(show)}
                                  >
                                    {`${show?.first_name}  ${show?.last_name} `}
                                  </li>
                                </>
                              );
                            }
                          )
                        :  */}
                      {showB &&
                        Array.isArray(showB) &&
                        showB.map((show, index) => (
                          <li
                            className={`list-group-item ${
                              rightActive.includes(show) && "active"
                            }`}
                            key={index}
                            onClick={() => handleClickRight(show)}
                          >
                            {`${show?.first_name}  ${show?.last_name} `}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                {/* <ul className="list-group">
                  {showB &&
                    Array.isArray(showB) &&
                    showB.map((show, index) => {
                      if (!show) return null;
                      return (
                        <li
                          className={`list-group-item ${
                            rightActive.includes(show) && "active"
                          }`}
                          key={index}
                          onClick={() => handleClickRight(show)}
                        >
                          {`${show?.first_name}  ${show?.last_name} `}
                        </li>
                      );
                    })}
                </ul> */}
              </div>
            </div>

            <div className="d-flex justify-content-end btnBackNext">
              <button className="btn buttonBack" onClick={handleBack}>
                <FaAngleLeft className="me-1 mb-1" /> Back
              </button>
              <button
                type="button"
                className="btn buttonNext"
                onClick={onSubmit}
              >
                Next <FaAngleRight className="ms-1 mb-1" />
              </button>
            </div>
          </div>
          <ToastContainer />
        </div>
      </Modal>
    </>
  );
}

export default ReceiverSelect;
