import React, { useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { ProgressBar } from "react-loader-spinner";
import { toast } from "react-toastify";
import { API } from "../../ApiWrapper";

const FilesUploader = ({ onDataSources, close, update }) => {
  const [droping, setDroping] = useState(false);
  const [chosenFiles, setChosenFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const existingSessionId = sessionStorage.getItem("sessionId");
  const handleSubmit = async () => {
    try {
      const formDataPost = new FormData();
      for (let i = 0; i < files.length; i++) {
        // if (files[i].type === "application/pdf") {
        formDataPost.append("file", files[i]);
        console.log("files[i]", files[i]);
        // }
      }
      formDataPost.append("user_id", existingSessionId);

      setIsLoading(true);
      await API({
        url: "/upload",
        method: "POST",
        body: formDataPost,
        formData: true,
      })
        // fetch("http://192.168.20.89:8000/upload", {
        //   method: "POST",
        //   body: formDataPost,
        // })
        // .then((response) => response.json())
        .then((data) => {
          // onDataSources(data?.file_path);
          update();
          toast.success(data?.message);
          setIsLoading(false);
          close();
        });
    } catch (err) {
      setIsLoading(false);
      toast.warning("Please try again");
      console.log(err);
    }
  };

  const handleFileChange = (files) => {
    // Convert the FileList to an array and filter out unsupported files
    const fileArray = Array.from(files).filter(
      (file) =>
        file.size < 12000000 &&
        (file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "application/pdf" ||
          file.type === "text/csv")
    );

    // Map the filtered files to an array of file objects
    const fileObjects = fileArray.map((file) => ({
      _id: file.name,
      img: getFileIcon(file.type),
      data: file,
      title: file.name,
      type: file.type,
      desc: `The filename is ${file.name}.This is a ${file.type} file.`,
    }));

    setChosenFiles(fileObjects);
    console.log("files", files);
    setFiles(files);
  };
  console.log("setChosenFiles", setChosenFiles);
  const getFileIcon = (fileType) => {
    switch (fileType) {
      case "image/jpeg":
      case "image/png":
        return "https://icon-library.com/images/jpg-file-icon/jpg-file-icon-7.jpg";
      case "application/pdf":
        return "https://icon-library.com/images/pdf-icon/pdf-icon-11.jpg";
      case "text/csv":
        return "https://icon-library.com/images/csv-file-icon/csv-file-icon-8.jpg";
      default:
        return ""; // Default icon or handle unsupported types
    }
  };

  const removeSelectedFile = (index) => {
    setChosenFiles(chosenFiles.filter((_, i) => i !== index));
  };

  const onDrop = (event) => {
    event.preventDefault();
    setDroping(false);
    handleFileChange(event.dataTransfer.files);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setDroping(true);
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setDroping(false);
  };

  return (
    <>
      <h4>Upload Your files here</h4>
      <div className="file-uploader">
        {chosenFiles.length === 0 ? (
          <label
            className={`file-upload-container ${droping ? "droping" : ""}`}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
          >
            <input
              type="file"
              onChange={(e) => handleFileChange(e.target.files)}
              hidden
              multiple
            />
            <div className="upload-icon">
              <MdOutlineFileUpload />
            </div>
            <p>Drop your files here</p>
          </label>
        ) : (
          <div className="file-preview-container">
            {chosenFiles.map((file, index) => (
              <div key={index} className="file-preview">
                <img src={file.img} alt="preview" />
                <button
                  className="remove-file"
                  onClick={() => removeSelectedFile(index)}
                >
                  ✕
                </button>
                <div className="file-name">{file.title}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="file-submit">
        {isLoading ? (
          <div className="file-loading-bar">
            <ProgressBar
              height={40}
              width={40}
              barColor="#000"
              borderColor="#000"
            />
            <h6>Processing</h6>
          </div>
        ) : (
          <button onClick={handleSubmit}>Submit</button>
        )}
      </div>
    </>
  );
};

export default FilesUploader;
