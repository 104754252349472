import React, { useState } from "react";
import { AccordionItem, Col, Container, Row } from "react-bootstrap";

import Accordion from "react-bootstrap/Accordion";

function Documentation() {
  const [active, setActive] = useState(1);
  const handleClick = (index) => {
    setActive(index);
  };

  return (
    <>
      <section className="topBanner">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <h1 className="text-center">How can we help? </h1>
              <div className="searchBox mt-4 py-3">
                <input type="text" placeholder="Search" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <Container>
          <Row>
            <Col lg={3}>
              <div className="leftCategory">
                <ul>
                  <li
                    className={active === 1 ? "active" : ""}
                    onClick={() => handleClick(1)}
                  >
                    <a href="#Authentication">
                      Adding Two-Factor Authentication
                    </a>
                  </li>
                  <li
                    className={active === 2 ? "active" : ""}
                    onClick={() => handleClick(2)}
                  >
                    <a href="#App_Password">Generating an App Password</a>
                  </li>
                  <li
                    className={active === 3 ? "active" : ""}
                    onClick={() => handleClick(3)}
                  >
                    <a href="#importFromWeb">How to use Import from Web</a>
                  </li>

                  <li>
                    <a>How to Test</a>
                  </li>
                  <ul className="subList">
                    <li
                      className={active === 4.0 ? "active" : ""}
                      onClick={() => handleClick(4.0)}
                    >
                      <a href="#LoginExtension">How to Login Extension</a>
                    </li>
                    <li
                      className={active === 4.1 ? "active" : ""}
                      onClick={() => handleClick(4.1)}
                    >
                      <a href="#Extension">How to use Extension</a>
                    </li>
                    <li
                      className={active === 4.2 ? "active" : ""}
                      onClick={() => handleClick(4.2)}
                    >
                      <a href="#Configure_mails">How to configure Mails</a>
                    </li>
                    <li
                      className={active === 4.3 ? "active" : ""}
                      onClick={() => handleClick(4.3)}
                    >
                      <a href="#Send_mails">How to send mails</a>
                    </li>
                    <li
                      className={active === 4.4 ? "active" : ""}
                      onClick={() => handleClick(4.4)}
                    >
                      <a href="#crmAdminPanel">How to use crm admin panel</a>
                    </li>

                    <li
                      className={active === 5.5 ? "active" : ""}
                      onClick={() => handleClick(5.5)}
                    >
                      <a href="#test_creatives">How to test Creatives</a>
                    </li>
                  </ul>

                  <li
                    className={active === 5 ? "active" : ""}
                    onClick={() => handleClick(5)}
                  >
                    <a href="#chatbot_chirpchat">
                      How to create chatbot from chirpchat
                    </a>
                  </li>

                  <li
                    className={active === 7 ? "active" : ""}
                    onClick={() => handleClick(7)}
                  >
                    <a href="#AI_Agent">
                      How to use the chatbot to ask queries(AI and Agent)
                    </a>
                  </li>

                  <li
                    className={active === 6 ? "active" : ""}
                    onClick={() => handleClick(6)}
                  >
                    <a href="#HelpVideo">Help Video</a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={9}>
              <aside className="py-5" id="Authentication">
                <div class="titleId">
                  <h2 className="m-0">Adding Two-Factor Authentication</h2>
                </div>
                <Accordion className="docAccordian" defaultActiveKey="0" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Sign in to your Google Account:
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>Go to myaccount.google.com.</li>
                        <li>
                          Click "Sign in" in the upper right corner and enter
                          your Google credentials.
                        </li>
                      </ul>
                      <img
                        className="img-fluid"
                        src="../Images/google-account.png"
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Access Security Settings:
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          In the left-hand navigation panel, select "Security".
                        </li>
                      </ul>
                      <img className="img-fluid" src="../Images/security.png" />
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Enable 2-Step Verification:
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          In the "Signing in to Google" section, locate "2-Step
                          Verification" and click on it.
                        </li>
                        <li>Click on the "Get started" button.</li>
                      </ul>
                      <img
                        className="img-fluid"
                        src="../Images/2-Step-Verification.png"
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Verify Your Identity:</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>Enter your Google account password if prompted.</li>
                        <li>
                          Google will then guide you through the steps to set up
                          2FA, which typically includes:
                        </li>
                        <li>
                          <b>Adding a phone number:</b> Google will send a
                          verification code to your phone via text message or
                          call.
                        </li>
                        <li>
                          <b>Entering the verification code:</b> Enter the code
                          you received on your phone to verify.
                        </li>
                      </ul>
                      <img
                        className="img-fluid my-4"
                        src="../Images/2-Step-Verification2.png"
                      />
                      <img
                        className="img-fluid"
                        src="../Images/2-Step-Verification3.png"
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Choose a Second Verification Step:
                    </Accordion.Header>
                    <Accordion.Body>
                      <h4 className="mb-2">
                        After verifying your phone, you can choose a second
                        method for 2FA:
                      </h4>
                      <ul className="aocList">
                        <li>
                          <b>Google Prompt:</b> Get a prompt sent to your
                          device.
                        </li>
                        <li>
                          <b>Authy/Google Authenticator:</b> Use an app to
                          generate verification codes.
                        </li>
                        <li>
                          <b>Backup codes:</b> Get a list of codes you can use
                          if you lose your phone.
                        </li>
                        <li>
                          <b>Security key:</b> Use a physical security key
                          (e.g., YubiKey).
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Complete the Setup:</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>Follow the instructions for the chosen method.</li>
                        <li>
                          Once done, 2FA will be enabled on your Google account.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </aside>

              <aside className="py-5" id="App_Password">
                <div class="titleId">
                  <h2 className="m-0">Generating an App Password</h2>
                </div>
                <Accordion className="docAccordian" defaultActiveKey="1" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Go to App Passwords:</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          After enabling 2FA, return to the "Security" section
                          in your Google account settings.
                        </li>
                        <li>
                          Under the "Signing in to Google" section, find "App
                          passwords" and click on it.
                        </li>
                        <li>
                          You may need to sign in again for security purposes.
                        </li>
                      </ul>
                      <img
                        className="img-fluid"
                        src="../Images/app-password.png"
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Select the App and Device:
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          In the "Select app" dropdown, choose the app you want
                          to generate a password for. If it's not listed, select
                          "Other (Custom name)" and type in the app's name.
                        </li>
                        <li>
                          In the "Select device" dropdown, choose the device
                          you're using. If it's not listed, select "Other
                          (Custom name)" and type in the device's name.
                        </li>
                      </ul>
                      <img
                        className="img-fluid"
                        src="../Images/app-dropdown.png"
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Generate the App Password:
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>Click on the "Generate" button.</li>
                        <li>
                          A 16-character password will appear on the screen.
                        </li>
                      </ul>
                      <img
                        className="img-fluid"
                        src="../Images/generate-app-password.png"
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Use the App Password:</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>Copy the app password.</li>
                        <li>
                          Enter this password into the app or device instead of
                          your regular Google account password.
                        </li>
                      </ul>
                      <img
                        className="img-fluid"
                        src="../Images/copy-password.png"
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Done:</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Your app or device should now be able to access your
                          Google account using the app password you generated.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </aside>

              <aside className="py-5" id="importFromWeb">
                <div class="titleId">
                  <h2 className="m-0">How to use Import from Web</h2>
                </div>
                <Accordion className="docAccordian" defaultActiveKey="3" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Step-1 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Select the Data Sources tab from the top nav bar of
                          the screen
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Step-2 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Then select the import from web from the left sidebar
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Step-3 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>Then click on create a model will open</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Step-4 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Select the type of data sources from which you want to
                          enter the url{" "}
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Step-5 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>Then enter the url</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Step-6 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>Then click on submit button</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Step-7 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Your request will be processed and the data will be
                          shown after that
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </aside>

              <aside className="py-5" id="LoginExtension">
                <div class="titleId">
                  <h2 className="m-0">How to Login Extension</h2>
                </div>
                <Accordion
                  className="docAccordian"
                  defaultActiveKey="4.1"
                  flush
                >
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Step-1 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          First login to the extension website link -:
                          <a
                            target="_blank"
                            href="https://us2.sourcesoftsolutions.com:5074"
                            alt=""
                          >
                            https://us2.sourcesoftsolutions.com:5074
                          </a>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Step-2 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Now on the dashboard u can see the user Preference
                          option
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Step-3 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Click on edit button now u can edit the user
                          Preference and add your keys of groq and openai. click
                          on save and your Preferences have been saved
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Step-4 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Open your extension on the same tab where the website
                          is logged in and click on the reload option on the top
                          of the extension. Now u can change the tab use the
                          extension according to your need.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </aside>

              <aside className="py-5" id="Extension">
                <div class="titleId">
                  <h2 className="m-0">How to use Extension</h2>
                </div>
                <Accordion
                  className="docAccordian"
                  defaultActiveKey="4.1"
                  flush
                >
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Step-1 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Download the zip file into your pc at your favorable
                          location
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Step-2 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Unpack the zip file and extract it in that directory
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Step-3 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Go to the chrome browser and select the manage
                          extension option which will appear as u click on the
                          extensions icons almost at the top of the left of the
                          navbar
                        </li>
                      </ul>
                      .
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Step-4 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Enable the Developer mode if not enabled which will be
                          a toggle button appearing at the left of the manage
                          extension page
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Step-5 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Click on the load unpacked option on the right corner
                          of the same page and select your extension folder
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Step-6 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Now u can come back to the chrome browser open a new
                          tab
                        </li>
                      </ul>
                      .
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Step-7 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Click again on the extension icon at top left corner
                          and select your extension from the below extensions
                        </li>
                      </ul>
                      .
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>Step-8 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>Now as per choice u can select the search type </li>
                        <li>
                          <b>LLM - </b>It will give u emails phone numbers and
                          and urls of the websites that will appear according to
                          your search query{" "}
                        </li>
                        <li>
                          <b>Google Search - </b>It will give you emails from
                          the websites that will appear according to your search
                          query{" "}
                        </li>
                        <li>
                          <b>Current Tab - </b>It will also return you emails
                          but from the particular or current tab that u are on{" "}
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </aside>

              <aside className="py-5" id="Configure_mails">
                <div class="titleId">
                  <h2 className="m-0">How to configure Mails</h2>
                </div>
                <Accordion
                  className="docAccordian"
                  defaultActiveKey="4.2"
                  flush
                >
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Step-1:</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Login to the website and if u don't have an account u
                          can create a new account by providing the necessary
                          credentials.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Step-2:</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Now for sending the mails go to the mailbox section
                          which will appear as u click on the mailbox option on
                          the header
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Step-3:</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Now u will be seeing an empty screen now left sidebar
                          click on the upload icon now u can configure and
                          upload sender emails according to your choice but for
                          the correct format download the sample file csv or
                          excel as per your choice.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Step-4:</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Edit the sender details like name, email and password
                          according to the correct details.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Step-5:</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Now upload that file with the logical name that u want
                          to give
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Step-6:</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Now u can see the details of that mail ids i.e. the
                          send, inbox and unread mails on the left sidebar
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </aside>

              <aside className="py-5" id="Send_mails">
                <div class="titleId">
                  <h2 className="m-0">How to send mails</h2>
                </div>
                <Accordion
                  className="docAccordian"
                  defaultActiveKey="4.3"
                  flush
                >
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Step-1 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          On the same mailbox page click on the compose icon
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Step-2 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Click on the configuration list and add a new
                          configuration with a new name
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Step-3 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Select the senders according to to your choice from
                          which u want to send the mail{" "}
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Step-4 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Similarly select the receivers and if not any u can
                          download the sample receiver file and edit it
                          according to the number of receivers u want and in the
                          same format.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Step-5 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Now select the desired template and next u can select
                          that if u need to send it now or schedule it for
                          later.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Step-6 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          If u select the send now option then select the gap u
                          u want to be in between the consecutive emails and
                          also the number of emails a particular sender can
                          send.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Step-7 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          If u select the send later option then select the time
                          zone, date and time at which u wanna send the email
                          and also the same time gap between two mails and the
                          count of emails a particular sender can send.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>Step-8 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          After all the configurations have been done submit the
                          configuration button and select the configuration from
                          the dropdown of the configuration list.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>Step-9 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Now u can change the body of the email as per your
                          choice and also on right click on the editor u can
                          generate images and content with a simple prompt.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>Step-10 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Click on the send option on the left corner and the
                          mail will be sent at the chosen time.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </aside>

              <aside className="py-5" id="crmAdminPanel">
                <div class="titleId">
                  <h2 className="m-0">How to use crm admin panel</h2>
                </div>
                <Accordion
                  className="docAccordian"
                  defaultActiveKey="4.3"
                  flush
                >
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Step-1 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Go to the url{" "}
                          <a href="https://us2.sourcesoftsolutions.com:8029/home">
                            https://us2.sourcesoftsolutions.com:8029/home
                          </a>{" "}
                          and login with the below credentials
                          <p className="mt-3 mb-0">
                            <b>email</b> : admincrm@yopmail.com
                          </p>
                          <p>
                            <b>password</b> : dmin@1234
                          </p>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Step-2 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Go to user listing and click on the edit icon for the
                          user you want to assign the team lead
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Step-3 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Now go to the crm dasboard or the mailbox section and
                          now you will be able to see the team member u added
                          under your team and access their mailbox.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </aside>

              <aside className="py-5" id="test_creatives">
                <div class="titleId">
                  <h2 className="m-0">How to use CK editor </h2>
                </div>
                <Accordion
                  className="docAccordian"
                  defaultActiveKey="4.3"
                  flush
                >
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Step-1 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Go to the url{" "}
                          <a href="https://www.dalaalstreet.com/">
                            Url -: https://www.dalaalstreet.com/
                          </a>{" "}
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Step-2 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>Now click on the creative on the top navbar</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Step-3 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Now click on the blog section if you want to create
                          the whole blog with content and images
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Step-4 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          If you only have to generate image you can do that too
                          in the generate images section below the blogs
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Step-5 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Save the blog generated by adding the title and
                          clicking on the save icon
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Step-6 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          You can check the details of the generated content and
                          images in the user data section
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </aside>

              <aside className="py-5" id="chatbot_chirpchat">
                <div class="titleId">
                  <h2 className="m-0">How to create chatbot from chirpchat</h2>
                </div>
                <Accordion className="docAccordian" defaultActiveKey="5" flush>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Step-1 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Go to the chirpchat website if u already have an
                          account login to that or else create a new account.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Step-2 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          You will see a dashboard with the already created
                          dashboard and if u haven't created yet let's create a
                          new one.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Step-3 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Click on the create chatbot option at the top right
                          corner of the table and and enter the website link for
                          which u need to create the chatbot and click on the
                          fetch links button.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Step-4 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          After a while it will fetch all the links from that
                          website link now click on the create button and the
                          chatbot will be created and u will get the script
                          which u can add the end of any website's index.html
                          page below the body tag closed.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </aside>

              <aside className="py-5" id="AI_Agent">
                <div class="titleId">
                  <h2 className="m-0">
                    How to use the chatbot to ask queries(AI and Agent)
                  </h2>
                </div>
                <h4 className="ps-5 mb-3">For AI</h4>
                <Accordion
                  className="docAccordian"
                  defaultActiveKey="6.1"
                  flush
                >
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Step-1 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Click on the chatbot icon that will be displayed on
                          the bottom right corner of the website.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Step-2 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Now u can ask the chatbot about anyth9ng that u want
                          to know from the chatbot and about that particular
                          website.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <h4 className="ps-5 mb-3">For Talk To Agent</h4>
                <Accordion
                  className="docAccordian"
                  defaultActiveKey="6.2"
                  flush
                >
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Step-1 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          Click on the connect to agent option that will be on
                          the top of the chatbot and u will receive a message
                          saying we will connect with u soon.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Step-2 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          So now the ticket has been raised and u can ask the
                          agent any question that u want to ask.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Step-3 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          The agent will reply from the other side and it will
                          be visible on your screen.
                        </li>
                        <li>And so the conversation can go on till u want.</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Step-4 :</Accordion.Header>
                    <Accordion.Body>
                      <ul className="aocList">
                        <li>
                          If u get satisfied with the agents response then u can
                          again switch u connect to AI at the same top bar.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </aside>

              <aside className="py-5" id="HelpVideo">
                <div class="titleId">
                  <h2 className="m-0">Help Video</h2>
                </div>
                <h3 className="mt-4 mb-3">Adding Two-Factor Authentication</h3>
                <div className="videoBox">
                  <video controls>
                    <source
                      src="https://us2.sourcesoftsolutions.com:8062/sender_files/step1.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <h3 className="mt-5 mb-3">Generating an App Password</h3>
                <div className="videoBox">
                  <video controls>
                    <source
                      src="https://us2.sourcesoftsolutions.com:8062/sender_files/step2.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>

                <h3 className="mt-5 mb-3">How to use Extension</h3>
                <div className="videoBox">
                  <video controls>
                    <source
                      src="https://us2.sourcesoftsolutions.com/email_client_mongo_dev/docs/Extension_vid"
                      type="video/mp4"
                    />
                  </video>
                </div>

                <h3 className="mt-5 mb-3">How to configure Mails</h3>
                <div className="videoBox">
                  <video controls>
                    <source
                      src="https://us2.sourcesoftsolutions.com/email_client_mongo_dev/docs/sender_mail_config"
                      type="video/mp4"
                    />
                  </video>
                </div>

                <h3 className="mt-5 mb-3">How to send mails</h3>
                <div className="videoBox">
                  <video controls>
                    <source
                      src="https://us2.sourcesoftsolutions.com/email_client_mongo_dev/docs/send_now"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className="videoBox mt-4">
                  <video controls>
                    <source
                      src="https://us2.sourcesoftsolutions.com/email_client_mongo_dev/docs/schedule_mail"
                      type="video/mp4"
                    />
                  </video>
                </div>

                <h3 className="mt-5 mb-3">
                  How to create chatbot from chirpchat
                </h3>
                <div className="videoBox">
                  <video controls>
                    <source
                      src="https://us2.sourcesoftsolutions.com/email_client_mongo_dev/docs/create_chatbot"
                      type="video/mp4"
                    />
                  </video>
                </div>

                <h3 className="mt-5 mb-3">
                  How to use the chatbot to ask queries(AI and Agent)
                </h3>
                <div className="videoBox">
                  <video controls>
                    <source
                      src="https://us2.sourcesoftsolutions.com/email_client_mongo_dev/docs/Chirpchat_ai_response"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className="videoBox mt-4">
                  <video controls>
                    <source
                      src="https://us2.sourcesoftsolutions.com/email_client_mongo_dev/docs/chirpchat_agent_vid"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <h3 className="mt-5 mb-3">
                  How to use the CRM Admin dashboard
                </h3>
                <div className="videoBox">
                  <video controls>
                    <source
                      src="https://us2.sourcesoftsolutions.com/email_client_mongo_dev/docs/Admin_cr.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>

                <h3 className="mt-5 mb-3">How to test Creatives</h3>
                <div className="videoBox">
                  <video controls>
                    <source
                      src="https://us2.sourcesoftsolutions.com/email_client_mongo_dev/docs/editor_tutorial.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </aside>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Documentation;
