import React from "react";
import ReceiverListing from "../../Listing/ReceiverListing";

function Email() {
  return (
    <>
      <ReceiverListing />
    </>
  );
}

export default Email;
