/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  SubmitPost,
  getEmailPayload,
  setPopup,
  setSaveSenderData,
} from "../../Store/Action";
import {
  AUTH,
  FORGET_PASSWORD,
  REGISTER,
  SAVE_SENDER_LIST,
} from "../../Store/Action/Type";
import { useNavigate } from "react-router-dom";
import LoginForm from "../Login/Login";
import RegisterForm from "../Register/Register";
import { toast } from "react-toastify";
import ForgotPasswordForm from "../ForgetPassword";
import {
  validateRegister,
  validateLogin,
  validateForgetEmail,
} from "../Validations/Validation";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
import { useGoogleLogin } from "@react-oauth/google";
import { allApi } from "../../ApiWrapper";
import axios from "axios";
import { FaFacebook, FaFacebookF } from "react-icons/fa6";
import FacebookLogin from "react-facebook-login";

const CommonLoginRegisterModal = ({ show, onHide }) => {
  const [isLoginForm, setIsLoginForm] = useState(true);

  const [isForgotPasswordForm, setIsForgotPasswordForm] = useState(false);
  const [emailData, setEmailData] = useState({ email: "" });
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [loginErrors, setLoginErrors] = useState({});
  const [registerErrors, setRegisterErrors] = useState({});
  const [forgotPasswordErrors, setForgotPasswordErrors] = useState({});
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [user, setUser] = useState(null);

  const [registerData, setRegisterData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showForgotPasswordForm = () => {
    setIsForgotPasswordForm(true);
  };

  const onChange = (event) => {
    if (isLoginForm) {
      setLoginErrors({});
      setLoginData({ ...loginData, [event.target.name]: event.target.value });
    } else {
      setRegisterErrors({});
      setRegisterData({
        ...registerData,
        [event.target.name]: event.target.value,
      });
    }
    if (isForgotPasswordForm) {
      setForgotPasswordErrors({});
      setEmailData({ ...emailData, [event.target.name]: event.target.value });
    }
  };
  const fetchAllSender = async (id) => {
    try {
      dispatch(getEmailPayload(`${SAVE_SENDER_LIST}?user_id=${id}`)).then(
        (data) => {
          console.log("SAVE_SENDER_LIST", data?.data);
          dispatch(setSaveSenderData(data?.data));
        }
      );
    } catch (error) {
      console.log(error, "error on SAVE_SENDER_LIST");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isForgotPasswordForm) {
        const errors = validateForgetEmail(emailData);
        if (errors.email) {
          setForgotPasswordErrors(errors);
          return;
        }
        dispatch(SubmitPost(FORGET_PASSWORD, emailData)).then((response) => {
          // console.log("response000000>>>>>", response.status);
          if (response && response.status === true) {
            dispatch(
              setPopup({
                modalType: "VERIFY",
                showModal: true,
                email: emailData,
              })
            );
            toast.success(response.message);
            onHide();
            setIsForgotPasswordForm(false);
            setEmailData({});
          } else {
            toast.error(response?.message);
            setIsForgotPasswordForm(false);
          }
        });
      } else if (isLoginForm) {
        const errors = validateLogin(loginData);
        if (Object.values(errors).some((error) => error)) {
          setLoginErrors(errors);
          return;
        }
        await dispatch(SubmitPost(AUTH, loginData)).then((response) => {
          if (response && response?.payload?.status === true) {
            toast.success(response?.payload?.message);
            console.log("responseforlogin", response);
            onHide();
            setLoginData({});
            fetchAllSender(response?.payload?.data?._id);
          }
        });
      } else {
        const errors = validateRegister(registerData);
        if (Object.values(errors).some((error) => error)) {
          setRegisterErrors(errors);
          return;
        }
        dispatch(SubmitPost(REGISTER, registerData)).then((response) => {
          console.log("response12154656", response);

          if (response && response?.payload?.status === true) {
            // setRegisterSuccess(true);
            toast.success(response?.payload?.message);
            onHide();
            setRegisterData([]);
          }
          // toast.success(response.payload.message);
          setIsLoginForm(true);
          onHide();
        });
      }
    } catch (error) {
      console.log("abc");
      toast.error(error);
      setIsForgotPasswordForm(false);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit(e);
    }
  };

  // useEffect(() => {
  //   if (registerSuccess) {
  //     onHide();
  //     resetFormStates();
  //     // navigate("/");
  //     // toast.success("Registration successful");
  //   }
  // }, [registerSuccess]);

  const switchCard = () => {
    setIsLoginForm(!isLoginForm);

    setLoginData({
      email: "",
      password: "",
    });
    setRegisterData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      password: "",
    });
  };

  const auth = useSelector((state) => state.all.auth) || {};

  // useEffect(() => {
  //   if (auth.session === true) {
  //     onHide();
  //     // resetFormStates();
  //     // navigate("/");
  //   }
  // }, [auth.session, auth.status, auth.message, navigate]);

  // const resetFormStates = () => {
  //   setIsLoginForm(true);
  //   setIsForgotPasswordForm(false);
  //   setLoginData({
  //     email: "",
  //     password: "",
  //   });
  //   setRegisterData({
  //     firstName: "",
  //     lastName: "",
  //     email: "",
  //     mobile: "",
  //     password: "",
  //   });
  //   setEmailData({ email: "" });
  // };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => setUser(tokenResponse),
  });
  const fetchData = async () => {
    try {
      const userInfoRes = await axios.get(
        `https://www.googleapis.com/oauth2/v2/userinfo?access_token=${user.access_token}`,
        {
          headers: {
            // Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        }
      );

      const obj = userInfoRes?.data;

      console.log(obj);

      const payload = {
        first_name: obj?.given_name,
        last_name: obj?.family_name,
        email: obj?.email,
        Image: obj?.picture,
        // GId: obj?.id,
        method: "GOOGLE",
      };
      console.log(payload, "data");
      dispatch(SubmitPost(REGISTER, payload)).then((response) => {
        if (response && response.status === true) {
          setRegisterSuccess(true);
        }
        toast.success(response.payload.message);
        setIsLoginForm(true);
        onHide();
      });
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  const responseFacebook = async (response) => {
    console.log(response, "facebook data");

    const payload = {
      name: response?.name,
      email_id: response?.email,
      // SocialId: response?.id,
      Method: "FACEBOOK",
    };

    dispatch(SubmitPost(REGISTER, payload)).then((response) => {
      if (response && response.status === true) {
        setRegisterSuccess(true);
      }
      toast.success(response.payload.message);
      setIsLoginForm(true);
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        // resetFormStates();
        onHide();
        setIsForgotPasswordForm(false);
      }}
    >
      <Modal.Body>
        <div className="allModal">
          <h2>
            {isLoginForm
              ? isForgotPasswordForm
                ? "Forgot Password"
                : "Login"
              : "Create Account"}
          </h2>
          {isLoginForm ? (
            isForgotPasswordForm ? (
              <ForgotPasswordForm
                emailData={emailData}
                onChange={(event) =>
                  setEmailData({
                    ...emailData,
                    [event.target.name]: event.target.value,
                  })
                }
                onSubmit={onSubmit}
                forgotPasswordErrors={forgotPasswordErrors}
              />
            ) : (
              <LoginForm
                loginData={loginData}
                onChange={onChange}
                onSubmit={() => {
                  onSubmit();
                }}
                loginErrors={loginErrors}
                onKeyDown={handleKeyDown}
              />
            )
          ) : (
            <RegisterForm
              registerData={registerData}
              onChange={onChange}
              onSubmit={onSubmit}
              registerErrors={registerErrors}
            />
          )}
          <div className="button-group">
            <Button
              variant="secondary"
              onClick={() => {
                onHide();
                setIsForgotPasswordForm(false);
              }}
              className="custom-cancel-button"
            >
              Cancel
            </Button>

            <div className="login-container">
              <button type="submit" onClick={onSubmit}>
                {isForgotPasswordForm
                  ? "Reset"
                  : isLoginForm
                  ? "Login"
                  : "Register"}
              </button>

              {isLoginForm && !isForgotPasswordForm && (
                <div className="forgot-password-link">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      showForgotPasswordForm();
                    }}
                  >
                    Forgot Password?
                  </a>
                </div>
              )}
            </div>
          </div>

          <div className="switch">
            {isLoginForm ? (
              <>
                Don't have an account? &nbsp;
                <a href="#" onClick={switchCard}>
                  Register here
                </a>
              </>
            ) : (
              <>
                {" "}
                Already have an account? &nbsp;
                <a href="#" onClick={switchCard}>
                  Login here
                </a>
              </>
            )}
          </div>

          {/* <button className="gmBtn" onClick={() => googleLogin()}>
            <span>
              <FcGoogle />
            </span>{" "}
            Google
          </button>
          <FacebookLogin
            appId="415692071070334"
            autoLoad={false}
            fields="id,name,email"
            callback={responseFacebook}
            scope="email,pages_read_engagement,pages_manage_posts,pages_manage_metadata,pages_read_user_content,business_management,instagram_basic,instagram_content_publish"
            cssClass="faBtn"
            icon={<FaFacebookF />}
            textButton=" Continue with Facebook"
          /> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CommonLoginRegisterModal;
