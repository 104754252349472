import { Link } from "react-router-dom";

export function Logo() {
  return (
    <div className="logo">
      <Link to="/">
        <img src="/Images/logo.png" alt="logo" />
      </Link>
    </div>
  );
}
