import React from "react";
import SidebarDashbaord from "./SidebarDashbaord";
import "./dashboard.css";
import RightSidebar from "./RightSidebar";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
  const location = useLocation();
  // console.log(location.pathname);

  return (
    <>
      <div className="d-flex mainDashboard">
        <div className="sidebarDashbaord">
          <SidebarDashbaord />
        </div>
        <div className="sidebarRigh p-5 w-100">
          <RightSidebar />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
