/* eslint-disable import/no-anonymous-default-export */
import {
  EMAILS_INBOX,
  EMAILS_OUTBOX,
  EMAILS_UNREAD,
  SELECTED_EMAIL,
  INDEX_EMAIL,
  EMAILS_LOADING,
  SENDER_UPLOAD,
  RECEIVER_UPLOAD,
  SENDER_DATA,
  SENDER_EMAIL,
  RECEIVER_EMAIL,
  CHOOSE_EMAIL,
  FILTER_SENDER,
  RECEIVER_DATA,
  TEMPLATE_DATA,
  UNIQUE_ID,
  SEND_NOW,
  SEND_LATER,
  SAVE_SENDER_LIST,
  FILTER_RECEIVER,
  FILTER_BODY,
  CLEAR_SENDER_DATA,
  SET_SELECTED_EMAIL_DATA,
  AUTH,
  REGISTER,
  LOG_OUT,
  FORGET_PASSWORD,
  SET_SELECTED_CATEGORY,
  CLEAR_CATEGORY_DATA,
  MARK_MESSAGE_AS_DISPLAYED,
  RESET_SELECTED_EMAIL,
  CONFIGURATION,
  SAVE_RECEIVER_LIST,
  POPUP,
  SET_SELECTED_SENDERS,
  SET_SELECTED_RECEVIERS,
  SET_SELECTED_CONFIG,
  SET_LOADER,
  CONFIRM_PASSWORD,
} from "../Action/Type";

const initialState = {
  auth: { session: false },
  inbox: {},
  outbox: {},
  unread: {},
  selectemail: { email: "", type: "" },
  indexemail: { index: 0 },
  unique: {},
  sender: {},
  senderemail: { Email: "" },
  emailsloading: false,
  receiveremail: { receiveremail: [] },
  defaultcategory: { criteria: "newest" },
  filtersender: {},
  filterreceiver: {},
  filterbody: {},
  receiver: {},
  SenderData: {},
  ReceiverData: {},
  TemplateData: {},
  savesenderlist: {},
  sendnow: {},
  sendlater: {},
  setShowDate: {},
  selectedEmailData: {},
  selectedCategory: null,
  popupReducer: {
    modal: { modalType: "", showModal: false, buyModal: false },
  },
  selectedSenders: [],
};

export default (state = initialState, { type, payload }) => {
  console.log("type232", type);
  console.log("payload111", payload);
  switch (type) {
    case AUTH:
      return { ...state, auth: { ...state.auth, ...payload } };

    case REGISTER:
      return { ...state, registeruser: payload };

    case FORGET_PASSWORD:
      return { ...state, forgetpass: payload };

    case LOG_OUT:
      return {
        ...state,
        auth: {
          ...state.auth,
          session: false,
          data: null,
        },
      };
    //==========================
    // Reducers for Email Client
    //==========================

    case EMAILS_INBOX:
      return { ...state, inbox: payload };
    case EMAILS_OUTBOX:
      return { ...state, outbox: payload };
    case EMAILS_UNREAD:
      return { ...state, unread: payload };
    case CONFIGURATION:
      return { ...state, configuration: payload };
    case SAVE_RECEIVER_LIST:
      return { ...state, RECEIVER_LIST: payload };
    case SAVE_SENDER_LIST:
      return { ...state, SENDER_LIST: payload };

    case SELECTED_EMAIL:
      return {
        ...state,
        selectemail: { ...(state?.selectemail || {}), ...payload },
      };

    case INDEX_EMAIL:
      return {
        ...state,
        indexemail: payload,
      };

    case UNIQUE_ID:
      return { ...state, unique: payload };

    case SENDER_UPLOAD:
      return { ...state, sender: payload };
    case RECEIVER_UPLOAD:
      return { ...state, receiver: payload };
    case SENDER_DATA:
      return { ...state, SenderData: payload, filtersender: payload };

    case SENDER_EMAIL:
      return { ...state, senderemail: payload };

    case RECEIVER_EMAIL:
      return { ...state, receiveremail: payload };

    case CHOOSE_EMAIL:
      return { ...state, defaultcategory: payload };

    case FILTER_SENDER:
      return { ...state, filtersender: payload };
    case FILTER_RECEIVER:
      return { ...state, filterreceiver: payload };
    case FILTER_BODY:
      return { ...state, filterbody: payload };
    case RECEIVER_DATA:
      return { ...state, ReceiverData: payload };
    case TEMPLATE_DATA:
      return { ...state, TemplateData: payload };

    case SEND_NOW:
      return { ...state, sendnow: payload };

    case SEND_LATER:
      return { ...state, sendlater: payload };

    case SAVE_SENDER_LIST:
      return { ...state, savesenderlist: payload };

    case EMAILS_LOADING:
      return { ...state, emailsloading: payload };

    case CLEAR_SENDER_DATA:
      return {
        ...state,
        senders: [],
        unique_id: {},
      };

    case SET_SELECTED_EMAIL_DATA:
      return {
        ...state,
        selectedEmailData: payload,
      };

    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: payload,
      };

    case CLEAR_CATEGORY_DATA:
      return {
        ...state,
        selectedCategory: null,
      };

    case MARK_MESSAGE_AS_DISPLAYED:
      return {
        ...state,
        isMessageDisplayed: true,
      };

    case RESET_SELECTED_EMAIL:
      return {
        ...state,
        selectedEmailData: null,
      };

    case POPUP:
      return {
        ...state,
        popupReducer: {
          ...state.popupReducer,
          modal: payload,
        },
      };
    case SET_LOADER:
      return {
        ...state,
        loader: payload,
      };
    case SET_SELECTED_SENDERS:
      return {
        ...state,
        selectedSenders: payload,
      };
    case SET_SELECTED_RECEVIERS:
      return {
        ...state,
        selectedReceviers: payload,
      };
    case SET_SELECTED_CONFIG:
      return {
        ...state,
        selectedConfig: payload,
      };
    case CONFIRM_PASSWORD:
      return {
        ...state,
        confirmpassword: payload,
      };
    default:
      return state;
  }
};
