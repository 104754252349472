import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { useSelector } from "react-redux";

function Filter({ show, handleClose }) {
  const [jList, setJList] = useState([]);
  const [ratingList, setRatingList] = useState([]);
  const [zipCodeList, setZipCodeList] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [subcategory, setSubCat] = useState({});
  const [category, setCategory] = useState([]);
  const data = useSelector((state) => state.apiDataSlice);
  const [filter, setFilter] = useState([]);

  const fetchJsonData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_GOOGLE_MAP_DATA_URL}/listMapData`
      );

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const data = await res.json();

      if (data.response && data.response.filterData) {
        setJList(data.response.filterData.categoryData);
        setRatingList(data.response.filterData.ratingData);
        setZipCodeList(data.response.filterData.zipCodeData);
        setCategory(data.response.filterData.categoryData);
      } else {
        console.error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchJsonData();
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  return (
    <div>
      <Modal show={show} size="xl" onHide={handleClose}>
        <div className="modal-content filerModal" onSubmit={submitHandler}>
          <div className="modal-header">
            <h5 className="modal-title">Filters your search</h5>
            <button
              type="button"
              className="close bg-transparent"
              data-dismiss="modal"
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>

          <div className="modal-body p-4">
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="filterList">
                  <h4>Rating</h4>
                  <Multiselect
                    options={ratingList}
                    showArrow
                    showCheckbox={true}
                    closeOnSelect={true}
                    displayValue="Rating"
                    placeholder="Select Rating"
                    emptyRecordMsg="No Rating Found"
                    className="multiSelectContainer"
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="filterList">
                  <h4>Zip Code</h4>
                  <Multiselect
                    options={zipCodeList}
                    showArrow
                    showCheckbox={true}
                    displayValue="PinCode"
                    placeholder="Select PinCode"
                    emptyRecordMsg="No Pincode Found"
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="filterList">
                  <h4>Category</h4>
                  <Multiselect
                    options={category}
                    showArrow
                    showCheckbox={true}
                    displayValue="BusinessCategory"
                    placeholder="Select Category"
                    emptyRecordMsg="No Category Found"
                  />
                </div>
              </div>
              {Object.keys(subcategory).length ? (
                <div className="col-md-6 col-lg-4 mb-4">
                  <div className="filterList">
                    <h4>{subcategory.BusinessCategory}</h4>
                    <select>
                      {subcategory.SubCategories.map((item) => (
                        <option>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : null}
            </div>
            <button className="btn-dark text-white  fw-bold btn" type="submit">
              Apply Filters
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Filter;
