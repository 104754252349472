import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import CommonLoginRegisterModal from "../../../../Components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { LOG_OUT } from "../../../../Store/Action/Type";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import PendingRequestCard from "../../../../Components/Notifications/PendingRequestCard";
import CompletedRequestCard from "../../../../Components/Notifications/CompletedRequestCard";
import { useNavigate } from "react-router-dom";

export function Sidelogo() {
  const [showModal, setShowModal] = useState(false);
  const auth = useSelector((state) => state.all.auth) || {};
  const UserId = useSelector((state) => state.all?.auth?.data?._id);
  const { registeruser } = useSelector((state) => state.all);
  const authuser = registeruser?.data?._id;
  console.log("authuser", authuser);
  const dispatch = useDispatch();
  const [tab, setShowTab] = useState(1);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const apiUrl = `${process.env.REACT_APP_GOOGLE_MAP_DATA_URL}/notification`;

  const token = useSelector((state) => state.all?.auth?.data?.token);
  console.log(token, "token");
  const navigate = useNavigate();

  const handleLoginClick = () => {
    if (auth?.session) {
      localStorage.removeItem("Token");
      dispatch({ type: LOG_OUT });
    } else {
      setShowModal(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("Token");
    dispatch({ type: LOG_OUT });
    navigate("/");
  };

  const handleDashboard = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    if (!auth.session) {
      setData(null);
    }
  }, [auth.session]);

  const userFullName = `${auth?.data?.firstName} ${auth?.data?.lastName}`;

  useEffect(() => {
    if (!token) return;

    fetch(apiUrl, {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((responseData) => {
        setData(responseData.data);
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
        setError(error.message);
      });
  }, [token]);

  return (
    <div className="col-auto">
      <div className="d-flex">
        {auth?.session && (
          <Dropdown className="notify">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <AiOutlineUsergroupAdd></AiOutlineUsergroupAdd>
              Request ({data?.completedCount + data?.pendingCount || 0})
              {/* ({data?.totalCount || 0}) */}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="mainNotify">
                <div class="d-flex justify-content-between align-items-center mb-lg-4 mb-3">
                  <h2>Requests</h2>
                  <a href="/">See All</a>
                </div>
                <div class="d-flex penToggal mb-4">
                  <button
                    class={tab === 1 ? "active" : ""}
                    onClick={() => setShowTab(1)}
                  >
                    Pending ({data?.pendingCount || 0})
                  </button>
                  <button
                    class={tab === 2 ? "active" : ""}
                    onClick={() => setShowTab(2)}
                  >
                    Completed ({data?.completedCount || 0})
                  </button>
                </div>
                <div className="innMainNotfy">
                  <div className={tab === 1 ? "pending d-block" : "d-none"}>
                    {data?.pendingData?.map((request) => (
                      <PendingRequestCard key={request._id} data={request} />
                    ))}
                  </div>
                  <div className={tab === 2 ? "complete d-block" : "d-none"}>
                    {data?.completedData?.map((request) => (
                      <CompletedRequestCard key={request._id} data={request} />
                    ))}
                  </div>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <div className="welcome">
          {
            // auth?.session ||
            UserId ? (
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-basic"
                  className="custom-dropdown-toggle loginNameLimit"
                >
                  {authuser
                    ? `${auth?.data?.firstName} ${auth?.data?.lastName}`
                    : userFullName}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item
                    href="/dashboard"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDashboard();
                    }}
                  >
                    Profile
                  </Dropdown.Item> */}

                  <Dropdown.Item
                    href="/UserPrefrence"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   handleDashboard();
                    // }}
                  >
                    User Preference
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/Notification"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   handleDashboard();
                    // }}
                  >
                    Notification
                  </Dropdown.Item>
                  <Dropdown.Item href="/my_files">My Files</Dropdown.Item>
                  <Dropdown.Item href="/payment">Payment</Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      e.preventDefault();
                      handleLogout();
                    }}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Button variant="primary" onClick={handleLoginClick}>
                Login
              </Button>
            )
          }
          <CommonLoginRegisterModal
            show={showModal}
            onHide={handleModalClose}
          />
        </div>
      </div>
    </div>
  );
}
