import React from "react";
import "../Modal/modal.css";

const RegisterForm = ({ registerData, onChange, onSubmit, registerErrors }) => {
  return (
    <form onSubmit={onSubmit}>
      <div class="inputData mt-4">
        <input
          type="text"
          name="email"
          id="email"
          // placeholder="Enter Email"
          value={registerData.email}
          onChange={onChange}
          required
        />
        <label htmlFor="email">Email</label>
        <div class="underline"></div>
      </div>
      {registerErrors.email && (
        <div className="error">{registerErrors.email}</div>
      )}
      <div class="inputData mt-4">
        <input
          type="password"
          name="password"
          id="password"
          // placeholder="Enter Password"
          value={registerData.password}
          onChange={onChange}
          required
        />
        <label htmlFor="password">Password</label>
        <div class="underline"></div>
      </div>
      {registerErrors.password && (
        <div className="error">{registerErrors.password}</div>
      )}
      <div class="inputData mt-4">
        <input
          type="text"
          name="firstName"
          id="firstName"
          // placeholder="Enter First name"
          value={registerData.firstName}
          onChange={onChange}
          required
        />
        <label htmlFor="firstName">First Name</label>
        <div class="underline"></div>
      </div>
      {registerErrors.firstName && (
        <div className="error">{registerErrors.firstName}</div>
      )}

      <div class="inputData mt-4">
        <input
          type="text"
          name="lastName"
          id="lastName"
          // placeholder="Enter Last name"
          value={registerData.lastName}
          onChange={onChange}
          required
        />
        <label htmlFor="lastName">Last Name</label>
        <div class="underline"></div>
      </div>
      {registerErrors.lastName && (
        <div className="error">{registerErrors.lastName}</div>
      )}
      <div class="inputData mt-4">
        <input
          type="number"
          name="mobile"
          id="mobile"
          // placeholder="Enter Mobile Number"
          value={registerData.mobile}
          onChange={onChange}
          required
        />
        <label htmlFor="mobile">Mobile</label>
        <div class="underline"></div>
      </div>
      {registerErrors.mobile && (
        <div className="error">{registerErrors.mobile}</div>
      )}
    </form>
  );
};

export default RegisterForm;
