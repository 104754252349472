// import React from "react";
import Sidebar from "./leftsidebar";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   allData,
//   searchData,
// } from "../../../../Store/Reducers/gmapsDataReducer";
import { allData, searchData } from "../../../Store/Reducers/gmapsDataReducer";
import { toast } from "react-toastify";
// import { clearCategoryData, setCategory } from "../../../../Store/Action";

import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Puff } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { BiX } from "react-icons/bi";

// import FilterSearch from "../../Search/FilterSearch";
import Multiselect from "multiselect-react-dropdown";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  // MarkerClusterer,
} from "@react-google-maps/api";
import MarkerClusterer from "@googlemaps/markerclustererplus";

import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";
// import XLSX from "xlsx";
import * as XLSX from "xlsx";
import { clearCategoryData, setCategory } from "../../../Store/Action";
import FilterSearch from "../../../Page/Maps/components/Search/FilterSearch";
import { makeSearchString } from "../../../ApiWrapper";

// const url = `${process.env.REACT_APP_GOOGLE_MAP_DATA_URL}/listMapData?pageNo=`;
const url = `${process.env.REACT_APP_GOOGLE_MAP_DATA_URL}`;

const RPA = () => {
  const [show, setShow] = useState(true);
  const [hideMap, setHideMap] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [checkedAll, setCheckAll] = useState(false);
  const [checked, setChecked] = useState([]);
  const [isEmailListing, setIsEmailListing] = useState(false);
  const [isEmailListingt, setIsEmailListingt] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  console.log(hoveredItem, "hoveredItem");
  const [filterShow, setFilterShow] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [isFilterVisible, setIsFilterVisible] = useState(true);

  const [zipcodedata, setZipcodedata] = useState();
  const [citydata, SetCityData] = useState([]);

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  console.log(isEmailListing, checked, "isEmailListingisEmailListing");

  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  const defaultZoom = 15;
  const focusedZoom = 20;

  const [zoom, setZoom] = useState(defaultZoom);

  const ToggleSidebar = () => {
    setFilterShow(!filterShow);
  };

  const { data } = useSelector((state) => state.apiDataSlice);
  const query = useSelector((state) => state.apiDataSlice.query);

  const UserId = useSelector((state) => state.all?.auth?.data?._id);

  const [rating, setRating] = useState([]);
  const [zipcode, setZipcode] = useState([]);
  const [category1, setCategory1] = useState([]);

  const [exporting, setExporting] = useState(false);

  console.log("zipcode", zipcode);

  const exportToExcel = () => {
    setExporting(true);

    const selectedData =
      checked.length > 0
        ? data.response.mapData.filter((item) =>
            checked.find((checkedItem) => checkedItem._id === item._id)
          )
        : [];

    // Create a deep copy of selectedData to ensure mutability
    const selectedDataCopy = JSON.parse(JSON.stringify(selectedData));

    // Truncate text exceeding the limit
    selectedDataCopy.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === "string" && item[key].length > 32767) {
          item[key] = item[key].substring(0, 32767);
        }
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(selectedDataCopy);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const fileName = "exported_data.xlsx";

    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      fileName
    );

    setExporting(false);
  };

  console.log(data, "datadatadata");
  console.log(category1, "category1");
  const handleRatingChange = (selectedList, selectedItem) => {
    setRating(selectedList);
    fetchApiData(selectedList, zipcode, category1);
  };

  const handleZipcodeChange = (selectedList, selectedItem) => {
    console.log("callllll", selectedList);
    setZipcode(selectedList);
    fetchApiData(rating, selectedList, category1);
  };

  const handleZipcodeChangedata = () => {
    setZipcode([]);
  };
  const handleCategoryChange = (selectedList, selectedItem) => {
    setCategory1(selectedList);
    fetchApiData(rating, zipcode, selectedList);
  };

  const dispatch = useDispatch();
  const [polylinePath, setPolylinePath] = useState([]);
  const [mapKey, setMapKey] = useState(0);
  const page = currentPage;

  const fetchApiData = async (
    searchData = null,
    // page = currentPage,
    selectedRatings = [],
    selectedCategories = [],
    selectedZipCodes = [],
    selectedSubCategories = []
  ) => {
    console.log(
      selectedZipCodes,
      selectedCategories,
      selectedSubCategories,
      selectedRatings,
      "selectedZipCodesselectedZipCodes"
    );
    const emailParam = isEmailListing ? "&email=true" : "";
    setLoading(true);

    let dataQueryParam = "";
    if (selectedZipCodes?.length > 0) {
      dataQueryParam = {
        zipcode: selectedSubCategories.length
          ? selectedSubCategories.join(",")
          : "",
        category: selectedZipCodes.length ? selectedZipCodes.join(",") : "",
        // zipcode    : selectedZipCodes.length?selectedZipCodes.join(","): "",
        pageNo: currentPage,
      };
    } else {
      dataQueryParam = {
        subcategory: category1[0]?.SubCategories.map((item) => item),
        category: category1?.map((item) => item?.BusinessCategory),
        zipcode: zipcode?.map((item) => item?.PinCode),
        pageNo: currentPage,
        searchkey: searchData,
      };
    }
    let urlnew = `listMapData`;

    const searchString = makeSearchString(dataQueryParam);
    console.log(searchString, "searchStringhome");
    urlnew = searchString ? `${urlnew}?${searchString}` : urlnew;

    const constructedURL = `${url}/${urlnew}`;

    //  const constructedURL = `${url}${page}${searchKeyParam}${emailParam}${ratingParam}${categoryParam}${zipcodeParam}${subcategoryParam}`;
    if (isEmailListing) {
      try {
        const response = await fetch(
          `${url}/listMapData?email=true&${searchString}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // console.log(data,page,"pagevale")
        if (page === currentPage) {
          dispatch(allData(data));
        }

        if (filterShow) {
          ToggleSidebar();
        }

        toggleFilterVisibility();
        setMapKey((prevKey) => prevKey + 1);
      } catch (error) {
        // console.error("Error fetching data:", error.message);
        if (page === currentPage) {
          dispatch(allData({ response: [] }));
        }
        toast.error("503 Server Error.");
      } finally {
        if (page === currentPage) {
          setLoading(false);
        }
      }
    } else {
      try {
        const response = await fetch(constructedURL);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // console.log(data,page,"pagevale")
        if (page === currentPage) {
          dispatch(allData(data));
        }

        if (filterShow) {
          ToggleSidebar();
        }

        toggleFilterVisibility();
        setMapKey((prevKey) => prevKey + 1);
      } catch (error) {
        // console.error("Error fetching data:", error.message);
        if (page === currentPage) {
          dispatch(allData({ response: [] }));
        }
        toast.error("503 Server Error.");
      } finally {
        if (page === currentPage) {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    fetchApiData();
  }, [category1, zipcode, currentPage, isEmailListing, query]);

  const handleCategorySelection = (category) => {
    dispatch(clearCategoryData());
    dispatch(setCategory(category));
  };
  const createPayload = () => {
    return {
      rpa_data: checked.map((item) => ({
        Name: item.Name,
        Email: item.Email,
      })),
    };
  };

  const sendSelectedData = async () => {
    if (isEmailListing && checked.length > 0) {
      const payload = createPayload();
      const backendURL = `${process.env.REACT_APP_BACKEND_URL}/Upload/ReceiverUpload?category=rpa`;

      try {
        const response = await fetch(backendURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Failed to send data");
        }
        const responseData = await response.json();
        // console.log(responseData);
      } catch (error) {
        console.error("There was an error:", error);
      }
    }
  };

  const handleSendEmail = (e) => {
    if (!UserId) {
      toast.error("Please Login First");
      e.preventDefault();
      return;
    }
    if (isEmailListing) {
      if (checked.length === 0) {
        toast.error(
          "Please select at least one checkbox before sending the email."
        );
        e.preventDefault();
        return;
      }
      dispatch(setCategory(checked));
      // console.log(checked, "newchecked");
      const selectedEmails = checked.map((item) => item.Email);
      // console.log("Sending emails to: HomePage", selectedEmails);
      sendSelectedData();
    } else {
      dispatch(clearCategoryData());
    }
  };

  useEffect(() => {
    fetchApiData(query);
  }, [currentPage, query, isEmailListing]);

  function handlePageClick({ selected: selectedPage }) {
    const newPage = selectedPage + 1;
    setCurrentPage(newPage);
  }

  const pageCount = Math.ceil(data?.totalCount / data?.perPage);

  useEffect(() => {
    console.log("checked", checked);
  }, [checked]);

  const handleChange = (event, value) => {
    console.log("handleChange triggered", event.target.checked, value);

    if (event.target.checked) {
      setChecked((prev) => [...prev, value]);
    } else {
      setChecked((prev) => prev.filter((item) => item._id !== value._id));
    }
  };

  const selectAll = () => {
    if (!checkedAll) {
      setChecked(data?.response?.mapData || []);
    } else {
      setChecked([]);
    }
    setCheckAll((prev) => !prev);
  };

  function handleListingType(type) {
    if (type === "all") {
      setIsEmailListing(false);
      setChecked([]);
    } else if (type === "email") {
      setIsEmailListing(true);
    }
    setCurrentPage(1);
  }

  const ratingData = data?.response?.filterData?.ratingData;
  const categoryData = data?.response?.filterData?.categoryData;
  const zipCodeData = data?.response?.filterData?.zipCodeData;
  console.log(zipCodeData, "zipCodeDatazipCodeDatazipCodeData");
  // console.log("ratingData", ratingData);

  const handleFilterSearch = (
    selectedCategories,
    selectedZipCodes,
    selectedSubCategories
  ) => {
    // console.log("Calling onApplyFilters with:.....", selectedCategories, selectedZipCodes, selectedSubCategories);

    fetchApiData(
      null,
      1,
      [],
      selectedCategories,
      selectedZipCodes,
      selectedSubCategories
    );
    SetCityData([]);
    setZipcodedata();
  };

  const mapContainerStyle = {
    width: "1000px",
    height: "850px",
  };

  const handleItemClick = (item) => {
    const lat = Number(item.latitude);
    const lng = Number(item.longitude);

    if (!isNaN(lat) && !isNaN(lng)) {
      setSelectedLocation({ lat, lng });
      setZoom(focusedZoom);
    } else {
      console.error("Invalid coordinates:", item.latitude, item.longitude);
    }
  };

  useEffect(() => {
    if (data?.response?.mapData && data?.response?.mapData.length > 0) {
      const firstItem = data?.response?.mapData[0];
      const lat = Number(firstItem.latitude);
      const lng = Number(firstItem.longitude);

      if (!isNaN(lat) && !isNaN(lng)) {
        setCenter({ lat, lng });
        setZoom(focusedZoom);
      }
    }
  }, [data?.response?.mapData]);

  const polygonCoordinatesArray = useSelector(
    (state) => state.apiDataSlice.data?.response?.polygonCoordinates
  );
  const markerData = useSelector(
    (state) => state.apiDataSlice.data?.response?.mapData
  );
  const polygonCoordinates = polygonCoordinatesArray
    ? polygonCoordinatesArray[0]
    : [];

  // console.log("Polygon Coordinates: data", polygonCoordinates);
  // console.log("markerData:", markerData);

  useEffect(() => {
    if (polygonCoordinates && polygonCoordinates.length > 0) {
      setCenter(polygonCoordinates[0]);
    }
  }, [polygonCoordinates]);
  let polygonCoordinatesdata = [];

  const poly = [];
  poly.push(polygonCoordinates);

  // console.log(poly, "poly............");

  if (polygonCoordinates && polygonCoordinates.features) {
    polygonCoordinates?.features?.forEach((element) => {
      element?.geometry.coordinates?.forEach((ele) => {
        ele?.forEach((ele) => {
          polygonCoordinatesdata.push({
            lat: ele[1],
            lng: ele[0],
          });
        });
      });
      poly.push(polygonCoordinatesdata);
      polygonCoordinatesdata = [];
    });
  }

  useEffect(() => {
    const map = new window.google.maps.Map(
      document.getElementById("mapdata"),
      {
        center: { lat: center.lat, lng: center.lng },
        zoom: 13,
      },
      [polygonCoordinates]
    );

    poly.forEach((polygonCoordinates) => {
      const polygon = new window.google.maps.Polygon({
        paths: polygonCoordinates,
        strokeColor: "blue",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "lightblue",
        fillOpacity: 0.35,
      });

      polygon.setMap(map);
    });

    const markers = markerData?.map((location) => {
      return new window.google.maps.Marker({
        position: new window.google.maps.LatLng(
          parseFloat(location.latitude),
          parseFloat(location.longitude)
        ),
        label: location.Name,
      });
    });

    new MarkerClusterer(map, markers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });
  }, [poly]);
  const handlecheckzipcodefilter = (zipCode) => {
    // console.log(zipCode, "zipCodebelwal");
    let object = { ...zipcodedata };
    delete object[zipCode];
    setZipcodedata({ ...object });
  };
  const handlecheckcityfilter = (ele, index) => {
    const newCityData = citydata.filter((_, i) => i !== index);
    // console.log(newCityData, "newCityData");
    SetCityData(newCityData);
  };
  const MangeAllState = () => {
    SetCityData([]);
    setZipcodedata();
    setZipcode([]);
    zipCodeData();
  };

  const [alldata, setAllData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.dalaalstreet.com/getAllData");
        console.log(response, "resssss");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setAllData(data?.response);

        console.log(data?.response, "<<<<<<<<<<<<<<alldataalldata");
        // exportAllToExcel(data?.response);
      } catch (error) {}
    };
    fetchData();
  }, []);

  console.log("alldata", alldata);

  const exportAllToExcel = () => {
    console.log("calling");

    if (!alldata) {
      console.error("Data is not yet fetched");
      return;
    }

    // Extracting relevant data for export
    // console.log("aalldata", alldata);
    console.log("alldata>>>>>>>>>>>>>>>>>>>>", alldata);
    const selectedData =
      Array.isArray(alldata) &&
      alldata.map((item) => ({
        id: item?._id,
        name: item?.Category,
        email: item?.Email,
        ImageLink: item?.ImageLink,
        Location: item?.Name,
        Name: item?.ImageLink,
        PhoneNumber: item?.PhoneNumber,
        Rating: item?.Rating,
        Review: item?.Review,
        Screenshot: item?.Screenshot,
        Website: item?.Website,
        ZipCode: item?.ZipCode,
        locationID: item?.locationID,
      }));

    // Create a deep copy of selectedData to ensure mutability
    const selectedDataCopy = JSON.parse(JSON.stringify(selectedData));
    console.log("selectedDataCopy", selectedDataCopy);

    // Truncate text exceeding the limit
    selectedDataCopy?.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === "string" && item[key].length > 32767) {
          item[key] = item[key].substring(0, 32767);
        }
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(selectedDataCopy);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const fileName = "exported_data.xlsx";

    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      fileName
    );
  };

  const clearFilter = () => {
    handleZipcodeChange([]);
    setZipcodedata();
    setCategory1([]);
    setRating([]);
  };

  return (
    <>
      {filterShow && (
        <div className={`sidebar ${filterShow === true ? "active" : ""}`}>
          <div className="filterTitle">
            {" "}
            <h4>All Filter</h4>
            {citydata &&
              citydata.map((ele, index) => (
                <>
                  <span className="">
                    {" "}
                    {ele}{" "}
                    <BiX onClick={() => handlecheckcityfilter(ele, index)} />
                  </span>
                </>
              ))}
            {zipcodedata &&
              Object.keys(zipcodedata).map((zipCode) => (
                <>
                  <span className="ms-4">
                    {" "}
                    {zipCode}{" "}
                    <BiX onClick={() => handlecheckzipcodefilter(zipCode)} />
                  </span>
                </>
              ))}
            <button onClick={ToggleSidebar}>
              {" "}
              <BiX onClick={MangeAllState} />
            </button>
          </div>
          {/* {isFilterVisible && ( */}
          <FilterSearch
            onApplyFilters={handleFilterSearch}
            onHide={toggleFilterVisibility}
            center={center}
            setCenter={setCenter}
            setHideMap={setHideMap}
            setShow={setShow}
            setZipcodedata={setZipcodedata}
            // zipcodedata={zipcodedata}
            citydata={citydata}
            SetCityData={SetCityData}
          />
          {/* )} */}
        </div>
      )}
      <div
        className={`sidebar-overlay ${filterShow === true ? "active" : ""}`}
        onClick={ToggleSidebar}
      ></div>
      <section className="salePropertyList1">
        <Sidebar />
        <div className="all_filter w-100">
          <div className="d-flex gap-3">
            <div className="d-flex gap-3 filter_List">
                <Multiselect
                  options={ratingData}
                  showArrow
                  selectedValues={rating}
                  showCheckbox={true}
                  closeOnSelect={true}
                  displayValue="Rating"
                  placeholder="Select Rating"
                  emptyRecordMsg="No Rating Found"
                  className="multiSelectContainer"
                  onSelect={handleRatingChange}
                  onRemove={handleRatingChange}
                />
                <Multiselect
                  options={zipCodeData}
                  showArrow
                  selectedValues={zipcode}
                  showCheckbox={true}
                  displayValue="PinCode"
                  placeholder="Select PinCode"
                  emptyRecordMsg="No Pincode Found"
                  onSelect={handleZipcodeChange}
                  onRemove={handleZipcodeChangedata}
                />
                <Multiselect
                  options={categoryData}
                  selectedValues={category1}
                  showArrow
                  showCheckbox={true}
                  displayValue="BusinessCategory"
                  placeholder="Select Category"
                  emptyRecordMsg="No Category Found"
                  onSelect={handleCategoryChange}
                  onRemove={handleCategoryChange}
                />
            </div>
            <ul className="allFlButton">
              <li><button type="button" onClick={ToggleSidebar}>All Filters</button></li>
              <li><button type="button" className="flSearch">Save Search</button></li>
              <li><button type="button" className="clearFilter" onClick={() => clearFilter()}>Clear Filters</button></li>
            </ul>
          </div>

          <div className="d-flex gap-4 mt-2 pt-2 border-top">
            <div className="ms-auto">
              <button
                onClick={exportToExcel}
                disabled={loading || exporting}
                className="px-3"
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  padding: "8px 20px 8px 15px",
                  fontSize: "14px",
                  borderRadius: "5px",
                  border: "none",
                  fontWeight: "500",
                }}
              >
                <FontAwesomeIcon icon={faFileExcel} />
                &nbsp; Export to Excel
              </button>
              {exporting && <Puff color="#4fa94d" ariaLabel="puff-loading" />}
            </div>

            <div>
              {alldata?.length > 0 && (
                <button
                  onClick={() => exportAllToExcel()}
                  disabled={loading || exporting}
                  style={{
                    backgroundColor: "#007bff",
                    color: "white",
                    padding: "8px 20px 8px 15px",
                    fontSize: "14px",
                    borderRadius: "5px",
                    border: "none",
                    fontWeight: "500",
                  }}
                >
                  <FontAwesomeIcon icon={faFileExcel} />
                  &nbsp; Export to All Excel
                </button>
              )}

              {/* {exporting && <Puff color="#4fa94d" ariaLabel="puff-loading" />} */}
            </div>

            <div className="togglaBtn d-flex align-items-center">
              <p>Hide Map</p>
              <div>
                <input
                  onClick={() => {
                    setShow((show) => !show);
                    setHideMap((hideMap) => !hideMap);
                  }}
                  type="checkbox"
                  id="switch"
                />
                <label htmlFor="switch">Toggle</label>
              </div>
            </div>
          </div>


        </div>

        <div className="d-flex w-100 ">
          <div className={`prodertyList w-50 p-4 ${show ? "showNotify" : ""}`}>
            <div className="tabs">
              <ul class>
                <li
                  onClick={() => handleListingType("all")}
                  onMouseEnter={() => setHoveredItem("all")}
                  onMouseLeave={() => setHoveredItem(null)}
                  style={{
                    backgroundColor:
                      !isEmailListing || hoveredItem === "all"
                        ? "#004170"
                        : "#fff",
                    color:
                      isEmailListing || hoveredItem === "all"
                        ? "black"
                        : "white",
                  }}
                >
                  All
                </li>
                <li
                  onClick={() => handleListingType("email")}
                  onMouseEnter={() => setHoveredItem("email")}
                  onMouseLeave={() => setHoveredItem(null)}
                  // style={{
                  //   backgroundColor:
                  //     !isEmailListingt || hoveredItem === "email"
                  //       ? "#004170"
                  //       : "black",
                  //   color:
                  //     isEmailListingt || hoveredItem === "email"
                  //       ? "black"
                  //       : "white",
                  // }}
                  style={{
                    backgroundColor:
                      isEmailListing || hoveredItem === "email"
                        ? "#004170"
                        : "initial",
                  }}
                >
                  Email Listing
                </li>
                {/* {isEmailListing && ( */}
                <li>
                  <input
                    name="checkall"
                    type="checkbox"
                    checked={checkedAll}
                    onChange={selectAll}
                  />
                  &nbsp;
                  <label>Select All</label>
                </li>
                {/* )} */}
                <li>
                  <i>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </i>
                  &nbsp;
                  <Link to="/senderlisting" onClick={(e) => handleSendEmail(e)}>
                    {isEmailListing
                      ? "Send Business Email"
                      : "Send Marketing Mail"}
                  </Link>
                </li>
              </ul>
            </div>
            {/* <PropertyList /> */}
            {!loading ? (
              <div className="proSearchList">
                {data?.response?.mapData
                  ? data?.response?.mapData?.map((value, index) => {
                      return (
                        <>
                          <div
                            onClick={() => handleItemClick(value)}
                            key={index}
                          >
                            {/* {isEmailListing && ( */}
                            <input
                              type="checkbox"
                              checked={checked.some(
                                (item) => item._id === value._id
                              )}
                              onChange={(e) => handleChange(e, value)}
                            />
                            {/* )} */}

                            <div className="listBox">
                              {/* <figure>
                                <img
                                  className="img-fluid"
                                  //   src="./images/pro_img1.jpg"
                                  src={value.ImageLink}
                                  alt={value.Name}
                                />
                              </figure> */}
                              <div className="p-4">
                                <h4>{value.Name}</h4>
                                <p className="my-3">{value.Location}</p>
                                <Link
                                  className="btn"
                                  to={`/businessDetail/${value._id}`}
                                >
                                  View
                                </Link>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Puff
                  height="80"
                  width="80"
                  radius={1}
                  color="#4fa94d"
                  ariaLabel="puff-loading"
                  visible={true}
                />
              </div>
            )}
          </div>

          <div className={`map_2 w-50 ${hideMap ? "hideMap" : ""}`}>
            <div className="responsive-map-container">
              {/* <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              >
                <GoogleMap
                  key={mapKey}
                  mapContainerStyle={mapContainerStyle}
                  center={selectedLocation || center}
                  zoom={zoom}
                >
                  {selectedLocation && <Marker position={selectedLocation} />}
                  {polygonCoordinates && (
                    <Polyline
                      path={polygonCoordinates}
                      options={{
                        strokeColor: "#0000FF",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#0000FF",
                        fillOpacity: 0.35,
                        clickable: false,
                        draggable: false,
                        editable: false,
                        visible: true,
                        radius: 30000,
                        zIndex: 1,
                      }}
                    />
                  )}
                </GoogleMap>
              </LoadScript> */}
              <div
                id="mapdata"
                style={{ height: "900px", width: "100%" }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <div className="paginationCst d-flex justify-content-center pb-5">
        <ReactPaginate
          previousLabel={"← prev"}
          breakLabel="......"
          breakClassName={"break-me"}
          pageRangeDisplayed={5}
          renderOnZeroPageCount={null}
          marginPagesDisplayed={0}
          nextLabel={"next →"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link__disabled"}
          activeClassName={"pagination__link__active"}
        />
      </div>
    </>
  );
};

export default RPA;
