import React from "react";
import Header from "../components/Header/Header";

function Layout() {
  return (
    <>
      <Header />
    </>
  );
}

export default Layout;
