import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Contentgenrator from "./Contentgenrator";
import "./style.css";

const MarketingResearchListing = ({ answer, setData }) => {
  const [selectedTitle, setSelectedTitle] = useState(null);

  const [data, setdata] = useState(false);

  const navigate = useNavigate();

  const handleNext = async () => {
    setdata(true);
  };

  console.log(selectedTitle, "selectedTitle");

  const handleBack = () => {
    // Navigate to the previous page
    navigate(-1);
  };

  return (
    <>
      {data && data === true ? (
        <>
          <Contentgenrator selectedTitle={selectedTitle} />
        </>
      ) : (
        <>
          {/* <div className="page-container">
        <div className="page-content">
          <div className="steps"></div>
          <h2>Select a Research Title</h2>
          <p className="para">
            Below is the list of title and SEO keywords ideas suggested based on
            your search. As a general rule of thumb, look for keywords and
            titles with a high monthly volume paired with the low difficulty to
            rank.
          </p>
          <table className="page2-tbl">
            <thead className="t-head">
              <tr>
                <th className="">Title/Keyword</th>
                <th>value</th>
              </tr>
            </thead>
            <tbody className="t-body">
              {answer?.top?.map((title, index) => (
                <tr
                  key={title._id}
                  onClick={() => setSelectedTitle(title._id)}
                  className={index % 2 != 0 ? "dr-bg" : ""}
                >
                  <td className="title-box">
                    <input
                      type="radio"
                      name="selectedTitle"
                      checked={selectedTitle === title._id}
                      onChange={() => setSelectedTitle(title._id)}
                    />
                    <div className="title-row-box">
                      <h4>{title.topic?.title}</h4>
                    </div>
                  </td>
                  <td>{title?.extracted_value}</td>
                  <td>{title.diff_to_rank}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="steps-btn">
        <button type="button" className="back" 
        >
          Back
        </button>
        <button type="button" className="next" 
        onClick={handleNext}
        >
          Next
        </button>
      </div> */}

          <div className="page-container">
            <table className="page2-tbl">
              <thead className="t-head">
                <tr>
                  <th className="">Title/Keyword</th>
                  <th>value</th>
                </tr>
              </thead>
              <tbody className="t-body">
                {answer?.top?.map((title) => (
                  <tr key={title.topic?.title}>
                    <td className="title-box">
                      <input
                        type="radio"
                        name="selectedTitle"
                        checked={selectedTitle === title.topic?.title}
                        onChange={() => setSelectedTitle(title.topic?.title)}
                      />
                      <div className="title-row-box">
                        <h4>{title.topic?.title}</h4>
                      </div>
                    </td>
                    <td>{title?.extracted_value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="steps-btn">
            <button
              type="button"
              className="back btn btn-primary"
              onClick={handleBack}
            >
              Back
            </button>
            <button type="button" className="next" onClick={handleNext}>
              Next
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default MarketingResearchListing;
