import React from "react";

function Contact() {
  return (
    <>
      <h1>Contact Us Page</h1>
    </>
  );
}

export default Contact;
