import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";

import {
  setReceiverEmail,
  setSelectedEmailData,
} from "../../../../Store/Action";
import { INDEX_EMAIL } from "../../../../Store/Action/Type";
import ReactPaginate from "react-paginate";
import { Hourglass } from "react-loader-spinner";
import { IoSearch, IoSettingsOutline } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsClock } from "react-icons/bs";
import { FaBan } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Sidebar2({
  currentPage,
  setCurrentPage,
  selectedDay,
  setSelectedDay,
}) {
  const [loading, setLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [emailsList, setEmailsList] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  // const [selectedDay, setSelectedDay] = useState(1);
  const { all } = useSelector((state) => state);
  const { type } = all.selectemail;
  const emailData = all?.[type];
  console.log("emailData", emailData);
  const loader = useSelector((state) => state?.all?.loader);
  const sender = useSelector((state) => state?.all?.SENDER_LIST);
  console.log("senderkljklkjilhyttrgfcfgv cvbcbchgvcghv", sender);
  console.log("loader", loader);
  // console.log("emailData", all?.[type]);
  // console.log("emailDataemailData", emailData);

  useEffect(() => {
    // if (loader === true) {
    setLoading(loader);
    // }
  }, [loader]);

  const pageCount = Math.ceil(
    emailData?.totalCount / emailData?.limit_per_page
  );
  console.log("pageCount", pageCount);

  const dispatch = useDispatch();

  useEffect(() => {
    // setLoading(true);
    const updatedEmailsList = [];
    for (let emailKey in emailData) {
      const currentEmailData = emailData[emailKey][selectedDay];
      if (Array.isArray(currentEmailData)) {
        updatedEmailsList.push(...currentEmailData);
      } else {
      }
    }
    setEmailsList(updatedEmailsList);
    // setIsLoading(false);
  }, [emailData, selectedDay]);

  // useEffect(() => {
  // setLoading(true);
  // setLoading(loader);
  // setTimeout(() => {
  //   setLoading(false);
  // }, 5000);
  // }, [currentPage]);

  // useEffect(() => {
  //   if (!selectedEmail) {
  //     setLoading(true);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [selectedEmail]);

  const handleReceiver = (receiveremail) => {
    dispatch(setReceiverEmail({ receiveremail }));
  };

  console.log("emailsList", emailsList);

  const days = (day) => {
    switch (day) {
      case 1:
        return "";

      case 2:
        return "Today";

      case 3:
        return "Yesterday";

      case 4:
        return "Last Week";

      case 5:
        return "Earlier This Month";

      default:
        return "new";
    }
  };

  const handleEmailClick = (message, index) => {
    console.log("message?.mail_body >>>>>>>>>>>>>>>>>>>", message?.mail_body);
    setSelectedEmail(message);
    dispatch({ type: INDEX_EMAIL, payload: index });
    dispatch(setSelectedEmailData(message));
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  useEffect(() => {
    setSelectedEmail(null);
  }, [type]);

  console.log("emailsList", emailsList);

  const emailsPerPage = 10;

  console.log("Loading state:", loading);

  return (
    <div className="msgBox">
      <div className="filterBar">
        <div className="filterDay">
          <select
            value={selectedDay}
            onChange={(e) => setSelectedDay(Number(e.target.value))}
            className="form-select"
          >
            <option value={1}>All Data</option>
            <option value={2}>{days(2)}</option>
            <option value={3}>{days(3)}</option>
            <option value={4}>{days(4)}</option>
            <option value={5}>{days(5)}</option>
          </select>
        </div>
        <div className="search_2">
          <input
            type="text"
            className="form-control m-0"
            placeholder="Search Here"
          />
          <button>
            <IoSearch />
          </button>
        </div>
        <Link to="/my_files/3">
          <button>
            <IoSettingsOutline className="icon" /> My Configurations
          </button>
        </Link>

        <Link to="/my_files/2">
          <button lassName="btn config ms-auto">
            <BsClock className="icon me-2" />
            My Schedules
          </button>
        </Link>
        {/* <Dropdown>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="btn schudule"
          >
          
            <BsClock className="icon me-2"  onClick={handlleConfiWithShedule}/>
            My Schedules
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="d-flex gap-3 align-items-center border-bottom py-2 px-3">
              14 June 2024 at 2:15 PM
              <a className="ms-auto" href="#">
                <MdOutlineModeEdit />
              </a>
              <a href="#">
                <FaBan />
              </a>
              <a href="#">
                <RiDeleteBin6Line />
              </a>
            </div>
            <div className="d-flex gap-3 align-items-center py-2 px-3">
              21 July 2024 at 2:15 PM
              <a className="ms-auto" href="#">
                <MdOutlineModeEdit />
              </a>
              <a href="#">
                <FaBan />
              </a>
              <a href="#">
                <RiDeleteBin6Line />
              </a>
            </div>
          </Dropdown.Menu>
        </Dropdown> */}
      </div>
      <div className="d-flex justify-content-between receivedMsg">
        <div className="d-flex align-items-center">
          <h4>From</h4>
          <h5>Subject</h5>
        </div>
        <div className="recvd">Received</div>
      </div>
      <div className="demo1 sidebar2-container" data-simplebar="">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {/* <Loader type="ball-pulse-sync" color="#000000" /> */}
            <Hourglass
              visible={true}
              height="80"
              width="80"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={["#306cce", "#72a1ed"]}
            />
          </div>
        ) : (
          sender?.length !== 0 && (
            <ul className="msg_detail">
              {
                // emailData && Object.keys(emailData)?.length > 0 ? (
                //   Object.entries(emailData).flatMap(([email, emailsArray]) => {
                //     return Array.isArray(emailsArray)
                //       ?
                emailData &&
                  emailData?.length > 0 &&
                  emailData.map((emailDetails, index) => {
                    // const cleanFrom = emailDetails?.From?.replace(
                    //   /<[^>]+>/,
                    //   ""
                    // ).trim();
                    console.log(
                      "emailDetails?.mail_body",
                      emailDetails?.mail_body
                    );
                    return (
                      <li
                        key={`${emailDetails}-${index}`}
                        onClick={() => handleEmailClick(emailDetails)}
                        className={
                          emailDetails === selectedEmail ? "active" : ""
                        }
                      >
                        <div className="msgDetail unRead">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <h6>{emailDetails?.email_from}</h6>
                              <h3>{emailDetails?.email_to}</h3>
                              {/* <h2 className="updateTitle">
                            {emailDetails?.email_subject || ""}
                          </h2> */}
                            </div>
                            <div>
                              <span>
                                {new Date(
                                  emailDetails?.email_date
                                ).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true,
                                })}
                              </span>
                            </div>
                          </div>
                          <p>{emailDetails?.mail_body.slice(0, 100)}</p>
                        </div>
                      </li>
                    );
                  })
              }
            </ul>
          )
        )}
      </div>
      <div className="pagination-wrapper">
        <ReactPaginate
          previousLabel={"← prev"}
          breakLabel="......"
          breakClassName={"break-me"}
          pageRangeDisplayed={5}
          renderOnZeroPageCount={null}
          marginPagesDisplayed={0}
          nextLabel={"next →"}
          pageCount={pageCount}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link__disabled"}
          activeClassName={"pagination__link__active"}
        />
      </div>
    </div>
  );
}

export default Sidebar2;
