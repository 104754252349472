import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { allApi } from "../../ApiWrapper";
import axios from "axios";

const UserPrefrence = () => {
  const User = useSelector((state) => state?.all?.auth?.data);
  const [openai, setOpenai] = useState("");
  const [serper, setSerper] = useState("");
  const [hugging_face, setHugging_face] = useState("");
  const [tavily, setTavily] = useState("");
  const [modelWeb, setModelWeb] = useState("");
  const [modelImage, setModelImage] = useState("");
  const [modelBlog, setModelBlog] = useState("");
  const [preferencedata, setPreferencedata] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [agentServices, setAgentServices] = useState([]);
  const [messageError, SetMessageError] = useState("");
  const [grok, SetGrok] = useState("");
  const [llmModel, SetllmModel] = useState("");
  const [language, SetLanguage] = useState("");
  const userID = useSelector((state) => state?.all?.auth?.data?._id);

  useEffect(() => {
    async function fetchdata() {
      try {
        const response = await allApi({
          url: `${process.env.REACT_APP_API_BACKEND_AGENT_URL}/agenttypes`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          formData: false,
        });

        console.log("agenttypes", response?.data);
        setAgentServices(response?.data);
      } catch (err) {
        toast.error(err);
        console.log(err);
      }
    }
    fetchdata();
  }, []);

  async function fetchmodeluserpreference() {
    try {
      const response = await allApi({
        url: `https://us2.sourcesoftsolutions.com:8072/get_configs/${userID}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        formData: false,
      });

      console.log("fetchmodeluserpreference", response?.data);
      setPreferencedata(response?.data);
      setOpenai(response?.data?.openai);
      setSerper(response?.data?.serper);
      setHugging_face(response?.data?.hugging_face);
      setTavily(response?.data?.tavily);
      SetMessageError(response?.data?.message);
      SetllmModel(response?.data?.model_extension);
      SetGrok(response?.data?.groq);
      SetLanguage(response?.data?.language);
      setModelWeb(response?.data?.model_content_gen);
      setModelImage(response?.data?.model_image_gen);
    } catch (err) {
      toast.error(err);
      console.log(err);
    }
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    fetchmodeluserpreference();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      model_content_gen: modelWeb,
      openai: openai,
      serper: serper,
      hugging_face: hugging_face,
      tavily: tavily,
      groq: grok,
      model_image_gen: modelImage,
      tool_content_gen: "tavily",
      tool_image_gen: "dalle",
      content_agent_task_prompt:
        "you are a web searcher search the web and provide content",
      image_agent_task_prompt:
        "you are image generator generate the images according to user input",
      message: messageError,
      language: language,
      model_extension: llmModel,
    };

    try {
      const response = await fetch(
        `https://us2.sourcesoftsolutions.com:8072/update_config/${userID}`,
        {
          method: "PUT",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (data?.status) {
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.error("Error while saving preferences:", error);
    }
  };

  return (
    <>
      <div className="container-xl">
        <div className="row">
          <div className="col-xl-12">
            <div className="loginModal">
              <h3 className="socalTitle mt-5">Select AI Model & Keys</h3>
              {isEditing ? (
                // <button className="btn btn-primary" onClick={handleSubmit}>
                //   Update
                // </button>
                ""
              ) : (
                <button className="btn btn-primary" onClick={handleEditClick}>
                  Edit
                </button>
              )}

              <div className="row mt-4">
                <div className="col-lg-6 pe-5">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group my-3">
                        <label>Model For Content Generation</label>

                        <div className="d-flex gap-3 mt-1 pt-2">
                          {agentServices &&
                            agentServices?.length > 0 &&
                            agentServices?.map((ele) => (
                              <div
                                className="d-flex align-items-center gap-1"
                                key={ele?.name}
                              >
                                <input
                                  className="form-check-input m-0"
                                  type="radio"
                                  name="ContentGeneration"
                                  value={ele?.name}
                                  onChange={(e) => setModelWeb(e.target.value)}
                                  disabled={!isEditing}
                                  checked={modelWeb === ele?.name}
                                  id={ele?.name}
                                />
                                <label
                                  className="form-check-label m-0"
                                  htmlFor={ele?.name}
                                >
                                  {ele?.name} ({ele?.bill_type})
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-4">
                        <div className="form-group my-3">
                          <label>Model For Extensions (LLM)</label>
                          <div className="d-flex gap-3 mt-1 pt-2">
                            {agentServices &&
                              agentServices?.length > 0 &&
                              agentServices?.map((ele) => (
                                <div
                                  className="d-flex align-items-center gap-1"
                                  key={ele?.name}
                                >
                                  <input
                                    className="form-check-input m-0"
                                    type="radio"
                                    name="LLM Extensions"
                                    value={ele?.name}
                                    onChange={(e) => SetllmModel(e.target.value)}
                                    disabled={!isEditing}
                                    checked={llmModel === ele?.name}
                                    id={ele?.name}
                                  />
                                  <label className="form-check-label m-0" htmlFor={ele?.name}>
                                    {ele?.name} ({ele?.bill_type})
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div> */}

                    <div className="col-lg-6">
                      <div className="inputData mt-5">
                        <input
                          type="text"
                          // placeholder="Openai Key"
                          // className="form-control"
                          id="openai"
                          value={openai}
                          onChange={(e) => setOpenai(e.target.value)}
                          required
                          disabled={!isEditing}
                        />
                        <label htmlFor="openai">Openai Key</label>
                        <div class="underline"></div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="inputData mt-5">
                        <input
                          type="text"
                          // placeholder="Serper Key"
                          // className="form-control"
                          id="serper"
                          value={serper}
                          onChange={(e) => setSerper(e.target.value)}
                          required
                          disabled={!isEditing}
                        />
                        <label htmlFor="serper">Serper Key</label>
                        <div class="underline"></div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="inputData mt-5">
                        <input
                          type="text"
                          // placeholder="Hugging Face Key"
                          // className="form-control"
                          id="hugging_face"
                          value={hugging_face}
                          onChange={(e) => setHugging_face(e.target.value)}
                          required
                          disabled={!isEditing}
                        />
                        <label htmlFor="hugging_face">Hugging Face Key</label>
                        <div class="underline"></div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="inputData mt-5">
                        <input
                          type="text"
                          // placeholder="Tavily Key"
                          // className="form-control"
                          id="tavily"
                          value={tavily}
                          onChange={(e) => setTavily(e.target.value)}
                          required
                          disabled={!isEditing}
                        />
                        <label htmlFor="tavily">Tavily Key</label>
                        <div class="underline"></div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="inputData mt-5">
                        <input
                          type="text"
                          // placeholder="Grok Key"
                          // className="form-control"
                          id="Grok"
                          value={grok}
                          onChange={(e) => SetGrok(e.target.value)}
                          required
                          disabled={!isEditing}
                        />
                        <label htmlFor="Grok">Grok</label>
                        <div class="underline"></div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="inputDataSelect mt-4">
                        <label>Language</label>
                        <select
                          // className="form-select"
                          value={language}
                          onChange={(e) => SetLanguage(e.target.value)}
                          disabled={!isEditing}
                        >
                          <option>Select</option>
                          <option value="English">English</option>
                          <option value="spanish">Spanish</option>
                          <option value="hindi">Hindi</option>
                          <option value="urdu">Urdu</option>
                          <option value="bengali">Bengali</option>
                        </select>
                      </div>
                    </div>

                    {/* <div className="col-lg-12">
                        <div className="form-group my-3">
                          <label htmlFor="tavily">message</label>
                          <textarea
                            className="form-control  h-auto"
                            value={messageError}
                            onChange={(e) => SetMessageError(e.target.value)}
                            rows={5}
                            disabled={!isEditing}
                          ></textarea>
                        </div>
                      </div> */}
                  </div>
                </div>

                <div className="col-lg-6 ps-5 border-start">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group my-3">
                        <label>Model For Image Generation (Paid)</label>
                        <div className="d-flex align-items-center gap-1 mt-1 pt-2">
                          <input
                            className="form-check-input m-0"
                            type="radio"
                            name="imageGeneration"
                            value="OpenAI"
                            onChange={(e) => setModelImage(e.target.value)}
                            disabled={!isEditing}
                            checked={modelImage === "OpenAI"}
                            id="OpenAI"
                          />
                          <label
                            className="form-check-label m-0"
                            htmlFor="OpenAI"
                          >
                            Open AI (Paid)
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      {/* <div className="form-group my-3"> */}
                      <div className="inputData mt-4">
                        <input
                          type="text"
                          // placeholder="Openai Key"
                          // className="form-control"
                          id="openai"
                          value={openai}
                          onChange={(e) => setOpenai(e.target.value)}
                          required
                          disabled={!isEditing}
                        />
                        <label htmlFor="openai">Openai Key</label>
                        <div class="underline"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mx-auto pt-4">
                  {/* <div className="form-group mt-5 my-3"> */}
                  <div className="inputData textareaData mt-5">
                    <textarea
                      // className="form-control  h-auto"
                      value={messageError}
                      onChange={(e) => SetMessageError(e.target.value)}
                      rows={5}
                      disabled={!isEditing}
                      required
                    ></textarea>
                    <label htmlFor="tavily">Message</label>
                    <div class="underline"></div>
                  </div>
                </div>
                <div className="col-lg-12 mt-4">
                  <div className="d-flex mt-5 justify-content-center twoBothBtn">
                    <button className="btn cancel px-xl-5">Cancel</button>
                    <button
                      className="btn btnSuccess ms-4 px-xl-5"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                  <hr className="mt-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPrefrence;
