import React from "react";

function ImportContact() {
  return (
    <>
      <div className="container mb-4">
        <div className="container-wrap mt-5">
          <div className="row justify-content-between align-items-stretch">
            <div className="col-sm-4 col-md-5">
              <div className="step_asdie">
                <div className="left-side">
                  <div className="steps-content">
                    <h3>Import Contacts</h3>
                    <p>form CSV or xlsx</p>
                  </div>
                  <ul className="progress-bars">
                    <li className="active">Uplaod File</li>
                    <li>Map Fields</li>
                    <li>Import Setting</li>
                    <li>Review List</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-8 col-md-7">
              <div className="step_forms-wrap py-5 h-100">
                <form className="" action="" method="">
                  <div className="form-group">
                    <div className="file-upload">
                      <div className="image-upload-wrap text-center">
                        <input
                          className="file-upload-input"
                          type="file"
                          onchange="readURL(this);"
                          accept="image/*"
                        />
                        <div className="drag-text">
                          <h3>
                            Drop files here
                            <span>Supported formats: CSV/Excel File</span>
                          </h3>
                        </div>
                        <div className="block-or">OR</div>
                        <button
                          className="file-upload-btn"
                          type="button"
                          onclick="$('.file-upload-input').trigger( 'click' )"
                        >
                          Browse files
                        </button>
                      </div>
                      <div className="file-upload-content">
                        <img
                          className="file-upload-image"
                          src="#"
                          alt="yourimage"
                        />
                        <div className="image-title-wrap">
                          <button
                            type="button"
                            onclick="removeUpload()"
                            className="remove-image"
                          >
                            Remove{" "}
                            <span className="image-title">Uploaded Image</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImportContact;
