import React, { useEffect, useState } from "react";
import DataIngest from "./DataIngest";
import ManageModel from "./ManageModel";
import ActionLayer, { getFileTypeFromExtension } from "./ActionLayer";
import { FaRegPlayCircle } from "react-icons/fa";
import { API } from "../../ApiWrapper";
 import "./style,.css";

const Labs = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [messages, setMessages] = useState([
    {
      text: "Hey, this is the implementation of LLM usecases. Ask what you would like to know!",
      sender: "bot",
    },
  ]);

  // const existingSessionId = sessionStorage.getItem("sessionId");
  console.log("SelectedFileqwert", selectedFiles);

  const update = async () => {
    try {
      await API({
        url: `/files/RandomString`,
        method: "GET",
      })
        // fetch(`http://192.168.20.89:8000/files/${existingSessionId}`, {
        //   method: "GET",
        // })
        //   .then((response) => response.json())
        .then((data) => {
          const files = data?.files.filter(
            (item, index, self) =>
              index ===
              self.findIndex((t) =>
                Object.keys(item).every((key) => item[key] === t[key])
              )
          );
          console.log("data", files);
          setDataSources(files);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    update();
  }, []);

  const [tab, setTab] = useState(dataSources?.length === 0 ? "ingest" : "ask");

  // console.log("dataSources", dataSources.length);
  const handleFileSelection = (fileUrl) => {
    setSelectedFiles([...selectedFiles, fileUrl]);
    const fileType = getFileTypeFromExtension(fileUrl);
    setDataSources(
      dataSources.filter(
        (data) => getFileTypeFromExtension(data.file_path) == fileType
      )
    );
  };

  const handleRemoveFileSelection = (fileUrl) => {
    if (selectedFiles && selectedFiles.length == 1) update();
    setSelectedFiles(selectedFiles.filter((url) => url !== fileUrl));
  };

  // const storedArrayBuffer = JSON.parse(sessionStorage.getItem("file"));
  // const blob = new Blob([new Uint8Array(storedArrayBuffer)]);
  // console.log("blob", blob);

  return (
    <>
      <div className="lab-container">
        <h2>Labs</h2>

        <div className="tab-container">
          <div className="tabs">
            <button
              type="button"
              onClick={() => setTab("ingest")}
              className={"left-corner " + (tab == "ingest" ? "active-tab" : "")}
            >
              Data Sources({dataSources?.length})
            </button>
            <button
              type="button"
              onClick={() => setTab("model")}
              className={tab == "model" ? "active-tab" : ""}
            >
              Models
            </button>
            <button
              type="button"
              onClick={() => setTab("ask")}
              className={"right-corner " + (tab == "ask" ? "active-tab" : "")}
            >
              Ask Queries
            </button>
          </div>
          <button className="see-demo">
            <FaRegPlayCircle /> <span>See Demo</span>
          </button>
        </div>

        <div className="lab-content">
          {tab == "ingest" ? (
            <DataIngest
              dataSources={dataSources}
              onDataSources={(source) =>
                setDataSources([...dataSources, ...source])
              }
              onNextStep={() => setTab("model")}
              update={update}
              setSelectedFiles={handleFileSelection}
              removeSelectedFile={handleRemoveFileSelection}
              selectedFiles={selectedFiles}
            />
          ) : tab == "model" ? (
            <ManageModel
              onBackStep={() => setTab("ingest")}
              onNextStep={() => setTab("ask")}
            />
          ) : (
            <ActionLayer
              messages={messages}
              onSetMessages={(message) =>
                setMessages((mess) => [...mess, message])
              }
              dataSources={selectedFiles}
              onBackStep={() => setTab("model")}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Labs;
