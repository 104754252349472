import React from "react";
import { Link } from "react-router-dom";
import {
  BiMap,
  BiEnvelope,
  BiTime,
  BiSolidSchool,
  BiLogoDribbble,
  BiSolidPhoneCall,
  BiMapPin,
} from "react-icons/bi";

export default function BusinessImage({ detailedData }) {
  console.log("detailedData", detailedData);

  return (
    <div>
      <div className="listBox bsDetail">
        <div>
          <img
            className="img-fluid"
            src={`${process.env.REACT_APP_GOOGLE_MAP_DATA_URL}/images/${detailedData?.Screenshot_Image}`}
            alt=""
          />
          <img
            src={detailedData?.ImageLink}
            alt={detailedData?.Name}
            style={{ width: "54%", height: "auto" }}
          />
        </div>
        <div className="p-4">
          <h4>{detailedData?.Name}</h4>
          <p>{detailedData?.Location}</p>
          <hr className="mt-4" />
          <div className="mt-4">
            <ul className="addList">
              <li>
                <figure>
                  <BiMapPin />
                </figure>{" "}
                {detailedData?.ZipCode}
              </li>
              <li>
                <figure>
                  <BiMap />
                </figure>{" "}
                {detailedData?.Location}
              </li>
              <li>
                <figure>
                  <BiEnvelope />
                </figure>{" "}
                {detailedData?.Email}
              </li>
              <li>
                <figure>
                  <BiTime />
                </figure>{" "}
                {detailedData?.Hours || "NA"}
              </li>
              <li>
                <figure>
                  <BiSolidSchool />
                </figure>{" "}
                {detailedData?.Category}
              </li>
              <li>
                <figure>
                  <BiLogoDribbble />
                </figure>{" "}
                {detailedData?.Website}
              </li>
              <li>
                <figure>
                  <BiSolidPhoneCall />
                </figure>{" "}
                {detailedData?.PhoneNumber}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
