import { FaFile } from "react-icons/fa";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { Link } from "react-router-dom";
import "./datasources.css";
import { Button, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";
import { allApi } from "../../../ApiWrapper";

import Sidebar from "./leftsidebar";

const boxes = [
  {
    Name: "Pine Street School",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "Kiddlez",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "Beyond Sushi",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "Dunkin'",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "Buenas Nachos",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "Gregorys Coffee",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "LENWICH",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
  {
    Name: "Neapolitan Express",
    Location: "25 Pine St, New York, NY 10005, United States",
  },
];

const LLM = () => {
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [llmInput, setLlmInput] = useState("");
  const [llmresponse, setllmresponse] = useState("");
  const [error, setError] = useState("");
  const showModal = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    if (!llmInput.length) {
      setError("Field can not be empty");
    }
    try {
      console.log(llmInput);
      setloading(true);
      const response = await allApi({
        //url: "http://192.168.2.171:5002/process-query",
        url: "http://us2.sourcesoftsolutions.com:5073/process_query",
        method: "POST",
        body: {
          query: llmInput,
        },

        formData: false,
        headers: { "Content-Type": "application/json" },
      });
      console.log(response);
      //setllmresponse("https://us2.sourcesoftsolutions.com:5073/" + response?.data);
      setllmresponse(
        `http://us2.sourcesoftsolutions.com:5073/${response.data}`
      );
      setError("");
      setLlmInput("");
      setOpen(!open);
    } catch (err) {
      console.log(err);
      toast.error("something went wrong");
      setError("Failed to process the query");
    }
    setloading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Sidebar />
      <div className="main-datasources-container">
        <Modal
          centered
          open={open}
          confirmLoading={loading}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleSubmit}
            >
              Submit
            </Button>,
          ]}
        >
          <label htmlFor="llm-input" className="llm-label">
            LLM Input
          </label>
          <TextArea
            id="llm-input"
            rows={7}
            placeholder="LLM Input"
            value={llmInput}
            onChange={(e) => setLlmInput(e.target.value)}
            style={{
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              fontSize: "1.2rem",
            }}
          />
          {error && <p className="error-message">{error}</p>}
        </Modal>
        <div className="heading-button">
          <h3>LLM</h3>
          <button className="primary-custom-btn" onClick={showModal}>
            <FaPlus />
            create
          </button>
        </div>
        {llmresponse && (
          <div className="response-area">
            {/* <FaFile /> {llmresponse} */}
            <FaFile />{" "}
            <a href={llmresponse} target="_blank" rel="noopener noreferrer">
              {llmresponse}
            </a>
          </div>
        )}
        <div className="proSearchList">
          {boxes.map((value) => (
            <div className="listBox" key={value}>
              <div className="p-4">
                <h4>{value.Name}</h4>
                <p className="my-3">{value.Location}</p>
                <Link className="btn" to={`/`}>
                  View
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LLM;

//code for showing the data in the file

// import { FaFile } from "react-icons/fa";
// import React, { useState } from "react";
// import { FaPlus } from "react-icons/fa6";
// import { Link } from "react-router-dom";
// import "./datasources.css";
// import { Button, Modal } from "antd";
// import TextArea from "antd/es/input/TextArea";
// import { toast } from "react-toastify";
// import { allApi } from "../../ApiWrapper";

// import Papa from "papaparse"; // Importing papaparse for CSV parsing
// import Sidebar from "./leftsidebar";

// const boxes = [
//   {
//     Name: "Pine Street School",
//     Location: "25 Pine St, New York, NY 10005, United States",
//   },
//   {
//     Name: "Kiddlez",
//     Location: "25 Pine St, New York, NY 10005, United States",
//   },
//   {
//     Name: "Beyond Sushi",
//     Location: "25 Pine St, New York, NY 10005, United States",
//   },
//   {
//     Name: "Dunkin'",
//     Location: "25 Pine St, New York, NY 10005, United States",
//   },
//   {
//     Name: "Buenas Nachos",
//     Location: "25 Pine St, New York, NY 10005, United States",
//   },
//   {
//     Name: "Gregorys Coffee",
//     Location: "25 Pine St, New York, NY 10005, United States",
//   },
//   {
//     Name: "LENWICH",
//     Location: "25 Pine St, New York, NY 10005, United States",
//   },
//   {
//     Name: "Neapolitan Express",
//     Location: "25 Pine St, New York, NY 10005, United States",
//   },
// ];

// const LLM = () => {
//   const [open, setOpen] = useState(false);
//   const [loading, setloading] = useState(false);
//   const [llmInput, setLlmInput] = useState("");
//   const [llmresponse, setllmresponse] = useState("");
//   const [error, setError] = useState("");
//   const showModal = () => {
//     setOpen(true);
//   };

//   const handleSubmit = async () => {
//     if (!llmInput.length) {
//       setError("Field can not be empty");
//     }
//     try {
//       console.log(llmInput);
//       setloading(true);
//       const response = await allApi({
//         //url: "http://192.168.2.171:5002/process-query",
//         url:"http://us2.sourcesoftsolutions.com:5073/process_query",
//         method: "POST",
//         body: {
//           query: llmInput,
//         },

//         formData: false,
//       });
//       console.log(response);
//       // //setllmresponse("https://us2.sourcesoftsolutions.com:5073/" + response?.data);
//       // setllmresponse(`http://us2.sourcesoftsolutions.com:5073/${response.data}`);
//       fetch("http://us2.sourcesoftsolutions.com:5073/" + response?.data)
//             .then((response) => response.text())
//             .then((csvText) => {
//                 const parsedData = Papa.parse(csvText, { header: true }).data;
//                 setllmresponse(parsedData);
//             })
//             .catch((error) => console.error("Error fetching CSV data:", error));

//     } catch (err) {
//       console.log(err);
//       toast.error("something went wrong");
//       setError("Failed to process the query");
//     }
//     setloading(false);
//   };

//   const handleCancel = () => {
//     setOpen(false);
//   };

//   return (
//     <>
//     <Sidebar />
//       <div className="main-datasources-container">
//         <Modal
//           centered
//           open={open}
//           confirmLoading={loading}
//           onCancel={handleCancel}
//           footer={[
//             <Button key="back" onClick={handleCancel}>
//               cancel
//             </Button>,
//             <Button
//               key="submit"
//               type="primary"
//               loading={loading}
//               onClick={handleSubmit}
//             >
//               Submit
//             </Button>,
//           ]}
//         >
//           <label htmlFor="llm-input" className="llm-label">
//             LLM Input
//           </label>
//           <TextArea
//             id="llm-input"
//             rows={7}
//             placeholder="LLM Input"
//             value={llmInput}
//             onChange={(e) => setLlmInput(e.target.value)}
//             style={{
//               border: "1px solid #d9d9d9",
//               borderRadius: "4px",
//               fontSize: "1.2rem",
//             }}
//           />
//           {error && <p className="error-message">{error}</p>}
//         </Modal>
//         <div className="heading-button">
//           <h3>LLM</h3>
//           <button className="primary-custom-btn" onClick={showModal}>
//             <FaPlus />
//             create
//           </button>
//         </div>
//         {llmresponse && <div className="response-area">
//           {/* <FaFile /> {llmresponse} */}
//           <FaFile /> <pre>{JSON.stringify(llmresponse, null, 2)}</pre>
//           </div>}
//         <div className="proSearchList">
//           {boxes.map((value) => (
//             <div className="listBox" key={value}>
//               <div className="p-4">
//                 <h4>{value.Name}</h4>
//                 <p className="my-3">{value.Location}</p>
//                 <Link className="btn" to={`/`}>
//                   View
//                 </Link>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

// export default LLM;
