import { createSlice } from "@reduxjs/toolkit";

const apiDataSlice = createSlice({
  name: "apiData",
  initialState: {
    data: {
      response: [],
    },
    query: "",
  },
  reducers: {
    allData: (state, { payload }) => {
      state.data = payload;
    },
    searchData: (state, { payload }) => {
      state.query = payload;
    },
  },
});

export const { allData, searchData } = apiDataSlice.actions;

export default apiDataSlice.reducer;
