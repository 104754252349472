import React, { useState } from "react";
// import "../Modal/style.css";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SubmitPost, setPopup } from "../../../../Store/Action";
import { Button } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CONFIRM_PASSWORD } from "../../../../Store/Action/Type";

function OTPVerify() {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const popupReducer = useSelector((state) => state?.all);
  const { showModal } = popupReducer?.popupReducer?.modal || {};
  const email = popupReducer?.popupReducer?.modal?.email?.email;
  console.log("email", email);

  const handleClose = () => {
    dispatch(setPopup({ modalType: "", showModal: false }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      otp,
      password,
      confirmPassword,
      email,
    };
    console.log("Form Submitted with payload: ", payload);
    // Handle form submission logic here
    dispatch(SubmitPost(CONFIRM_PASSWORD, payload)).then((data) => {
      if (data?.status === true) {
        toast.success(data?.message);
        handleClose();
      }
    });
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleClose}>
        <form onSubmit={handleSubmit}>
          <label htmlFor="otp">OTP</label>
          <input
            type="text"
            name="otp"
            id="otp"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />

          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          <Button type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </form>
      </Modal>
    </div>
  );
}

export default OTPVerify;
