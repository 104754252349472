import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaFileCsv, FaFileExcel } from "react-icons/fa";
import { Button } from "react-bootstrap";

function SenderData() {
  const UserId = useSelector((state) => state.all?.auth?.data?._id);
  const [fileData, setFileData] = useState([]);

  useEffect(() => {
    fetchReciverData();
  }, []);

  const fetchReciverData = async () => {
    try {
      const data = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/getFilePathsSender?user_id=${UserId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const response = await data.json();
      console.log("responserecevierdata", response);
      setFileData(response?.data || []);
    } catch (error) {
      console.error("Error fetching receiver data:", error);
    }
  };

  const getFileName = (filePath) => filePath.split("\\").pop();
  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    if (extension === "csv") {
      return <FaFileCsv size={30} />;
    } else if (extension === "xlsx") {
      return <FaFileExcel size={30} />;
    }
    return null;
  };
  const handleDownload = (filePath) => {
    const url = `https://us2.sourcesoftsolutions.com:8062/${filePath}`;
    const a = document.createElement("a");
    a.href = url;
    a.download = getFileName(filePath);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div>
      <div className="proSearchList">
        {fileData.map((filePath, index) => {
          const fileName = getFileName(filePath);
          return (
            <div className="listBox" key={index}>
              <div className="p-4">
                <h4>{fileName}</h4>
                {/* <p className="my-3">{filePath}</p> */}
                <div className="icon my-3">{getFileIcon(fileName)}</div>
                <Button
                  className="btn"
                  onClick={() => handleDownload(filePath)}
                >
                  Download
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SenderData;
