import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function SelectTemplate() {
  return (
    
    <>
    
        <section className='my-5'>
            <Container>
                <Row>
                    <Col     >
                        <h3 className='pageTitle mb-4'>Set Configuration</h3>
                        <div className='shadowBox'>
                            <h3 className='title mb-4'>Select Template</h3>
                            <div className='tempGrid'>
                                <div className=''>
                                    <figure></figure>
                                    <h3>RealEstate</h3>
                                </div>
                                <div className=''>
                                    <figure></figure>
                                    <h3>Medical</h3>
                                </div>
                                <div className=''>
                                    <figure></figure>
                                    <h3>Fiance</h3>
                                </div>
                                <div className=''>
                                    <figure></figure>
                                    <h3>AI</h3>
                                </div>
                                <div className=''>
                                    <figure></figure>
                                    <h3>RealEstate</h3>
                                </div>
                                <div className=''>
                                    <figure></figure>
                                    <h3>IT Industry</h3>
                                </div>
                                <div className=''>
                                    <figure></figure>
                                    <h3>RealEstate</h3>
                                </div>
                                <div className=''>
                                    <figure></figure>
                                    <h3>Medical</h3>
                                </div>
                                <div className=''>
                                    <figure></figure>
                                    <h3>Fiance</h3>
                                </div>
                                <div className=''>
                                    <figure></figure>
                                    <h3>AI</h3>
                                </div>
                                <div className=''>
                                    <figure></figure>
                                    <h3>RealEstate</h3>
                                </div>
                                <div className=''>
                                    <figure></figure>
                                    <h3>IT Industry</h3>
                                </div>
                            </div>
                        </div>
                        <div className='backNext d-flex justify-content-end gap-4 mt-5'>
                            <button className='btn'>Back</button>
                            <button className='btn'>Next</button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    
    </>

  )
}

export default SelectTemplate