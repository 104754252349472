import React, { useEffect, useState } from "react";
import { MdOutlineAddToPhotos, MdOutlineModeEdit } from "react-icons/md";
import { FaBan } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  getEmailPayload,
  setConfiguration,
  setPopup,
  setSelectedConfig,
} from "../../Store/Action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CONFIGURATION } from "../../Store/Action/Type";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function MyConfigurations() {
  const [confiData, setConfigData] = useState([]);
  const dispatch = useDispatch();
  const UserId = useSelector((state) => state.all?.auth?.data?._id);
  

  const fetchConfigData = async () => {
    try {
      const response = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/allConfigurationsDetails?user_id=${UserId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data?.status == "true") {
        console.log(data?.data, "fetchConfigData");
        setConfigData(data?.data);
      }
    } catch (error) {
      console.log(error, "errorerror");
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/configuration-detail?user_id=${UserId}&configuration_id=${id}`
      );
      const ConfigData = await response.json();

      // localStorage.setItem("EditConfigdata", JSON.stringify(ConfigData));

      console.log(ConfigData, "editdatattatat");
      dispatch(setSelectedConfig(ConfigData));
      dispatch(setPopup({ modalType: "SENDER", showModal: true }));
    } catch {}
  };

  const handleDelete = async (id) => {
    const payload = {
      is_deleted: true,
    };
    try {
      const dataremove = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/updateConfiguration?configuration_id=${id}&user_id=${UserId}`,
        {
          method: "PUT",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const response = await dataremove.json();
      if (response) {
        toast.success(response?.message);
        fetchConfigData();
      }
    } catch {}
  };

  const handleClone = async (id) => {
    try {
      const response = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/configuration-detail?user_id=${UserId}&configuration_id=${id}`
      );
      const ConfigData = await response.json();
      console.log("ConfigData111111", ConfigData);
      if (ConfigData) {
        const scheduleDate = new Date(
          ConfigData?.data?.configuration?.schedule
        );

        const pad = (num) => num.toString().padStart(2, "0");

        const formattedSchedule = `${scheduleDate.getUTCFullYear()}-${pad(
          scheduleDate.getUTCMonth() + 1
        )}-${pad(scheduleDate.getUTCDate())}T${pad(
          scheduleDate.getUTCHours()
        )}:${pad(scheduleDate.getUTCMinutes())}:${pad(
          scheduleDate.getUTCSeconds()
        )}.000+00:00`;
        const payload = {
          user_id: UserId,
          name: `${ConfigData?.data?.configuration?.name} copy`,
          senders_id: ConfigData?.data?.configuration?.senders_id,
          receivers_id: ConfigData?.data?.configuration?.receivers_id,
          schedule: formattedSchedule,
          send_type: ConfigData?.data?.configuration?.send_type,
          template_id: ConfigData?.data?.configuration?.template_id,
        };
        console.log(payload, "clone id");

        const createConfig = await fetch(
          "https://us2.sourcesoftsolutions.com:8062/configurations",
          {
            method: "POST",
            body: JSON?.stringify(payload),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseClone = await createConfig.json();
        if (responseClone) {
          toast.success(responseClone?.message);
          dispatch(getEmailPayload(`${CONFIGURATION}?user_id=${UserId}`)).then(
            (data) => {
              console.log("data?.data", data?.data);
              dispatch(setConfiguration(data?.data));
              fetchConfigData();
            }
          );
        }
      }
    } catch {}
  };



  
const confirmDelete = (id) => {
  MySwal.fire({
    title: 'Are you sure?',
    text: "Do you really want to Delete this Configurations?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      handleDelete(id);
   
    }
  })
}




  useEffect(() => {
    fetchConfigData();
  }, []);

  return (
    <>
      <section className="pageContent">
        <div className="titleId">
          <h2 className="m-0">My Configurations</h2>
        </div>
        <div className="table-responsive">
          <table class="table">
            <thead className="table-info">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Senders</th>
                <th scope="col">Receivers</th>
                <th scope="col">Time Chosen</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {confiData &&
                confiData?.length > 0 &&
                confiData?.map((ele, Index) => (
                  <tr key={Index}>
                    <td>{ele?.configuration?.name}</td>

                    <td>
                      {ele?.senders?.map((sender, senderIndex) => (
                        <div key={senderIndex}>
                          {sender?.first_name} {sender?.last_name}
                        </div>
                      ))}
                    </td>

                    <td>
                      {ele?.receivers?.map((receiver, receiverIndex) => (
                        <div key={receiverIndex}>
                          {receiver?.first_name} {receiver?.last_name}
                        </div>
                      ))}
                    </td>

                    <td>
                      {ele?.configuration?.send_type === "1"
                        ? "Send Now"
                        : ele?.configuration?.schedule}
                    </td>
                    <td>
                      <div className="d-inline-flex gap-4 h5">
                        <MdOutlineModeEdit
                          onClick={() => {
                            handleEdit(ele?.configuration?._id);
                          }}
                        />
                        <MdOutlineAddToPhotos
                          onClick={() => {
                            handleClone(ele?.configuration?._id);
                          }}
                        />
                        <RiDeleteBin6Line
                          onClick={(e) => {
                            confirmDelete(ele?.configuration?._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}

export default MyConfigurations;
