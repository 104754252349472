// Login API
export const AUTH = "user/login";
export const REGISTER = "user";
export const LOG_OUT = "LOG_OUT";
export const FORGET_PASSWORD = "user/reset/mail";

// EMAIL API
export const EMAILS_INBOX = "getInboxData";
export const EMAILS_OUTBOX = "getOutboxData";
export const EMAILS_UNREAD = "getUnreadData";
export const CONFIGURATION = "configurations";
export const NEW_INBOX = "Inbox_new";
export const NEW_OUTBOX = "Outbox_updated";
export const NEW_UNREAD = "Unread";
export const CONFIRM_PASSWORD = "user/verify/mail";

export const SELECTED_EMAIL = "select";
export const SENDER_EMAIL = "senderemail";
export const RECEIVER_EMAIL = "receiveremail";
export const INDEX_EMAIL = "index";
export const CHOOSE_EMAIL = "defaultcategory";
export const EMAILS_LOADING = "emailloading";
export const SENDER_UPLOAD = "senderUpload";
// export const RECEIVER_UPLOAD = "Upload/ReceiverUpload";
export const RECEIVER_UPLOAD = "receiverUpload";
export const TEMPLATE_UPLOAD = "Upload/TemplateUpload";
export const TEMPLATE_DATA = "ShowData/ShowTemplatesData";
export const SENDER_DATA = "ShowData/ShowSendersData?user=User1";
//export const RECEIVER_DATA = "ShowData/ShowReceiversData?category=all";
export const RECEIVER_DATA = "ShowData/ShowReceiversData?category=Marketing";
export const FILTER_SENDER = "filtersender";
export const FILTER_RECEIVER = "filterreceiver";
export const FILTER_BODY = "filterbody";
export const UNIQUE_ID = "ShowData/GetUniqueId";
export const SAVE_SENDER_LIST = "getAllSenders";
// export const SAVE_RECEIVER_LIST = "SaveData/SaveReceiversList";
export const SAVE_RECEIVER_LIST = "getAllReceivers";
export const SEND_NOW = "SendMails/SendNow";
export const SEND_LATER = "SendLater";
export const SET_DESCRIPTION = "setdescription";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const trends = "/trends";

// clear
export const CLEAR_SENDER_DATA = "CLEAR_SENDER_DATA";
export const SET_SELECTED_EMAIL_DATA = "SET_SELECTED_EMAIL_DATA";
export const CLEAR_CATEGORY_DATA = "CLEAR_CATEGORY_DATA";
export const MARK_MESSAGE_AS_DISPLAYED = "MARK_MESSAGE_AS_DISPLAYED";
export const RESET_SELECTED_EMAIL = "RESET_SELECTED_EMAIL";

export const POPUP = "POPUP";
export const SET_SELECTED_SENDERS = "SET_SELECTED_SENDERS";
export const SET_SELECTED_RECEVIERS = "SET_SELECTED_RECEVIERS";
export const SET_SELECTED_CONFIG = "SET_SELECTED_CONFIG";
export const SET_LOADER = "SET_LOADER";

export const TEMPLATE ="TEMPLATE"
