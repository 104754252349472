import React, { useState } from "react";
import { Button, Checkbox, Col, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
// import { allApi } from "./apiwrapper/api";
import { toast } from "react-toastify";
// import apiURL from "./Store/Action/api-url";
import { allApi } from "../../ApiWrapper";
import { ColorRing, ProgressBar } from "react-loader-spinner";
import { formatContent } from "./CkEditiorListing";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddMoreGenerateContentModal = ({
  onClose,
  setNewAddMoreGenerateContent,
  setUserInput
}) => {
  const [text, setText] = useState();
  const [responseText, setResponseText] = useState("");
  const [loading, setLoading] = useState(false);



  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingimage, setIsLoadingImage] = useState(false);
  const [answer, setanswer] = useState();
  const [urls, seturls] = useState();
  const userID = useSelector((state) => state?.all?.auth?.data?._id);
  const [llmModeldata, setLLMmodeldata] = useState({});
  const navigate=useNavigate();


  const LLMmodel = async () => {
    try {
      const response = await allApi({
        url: `https://us2.sourcesoftsolutions.com:8072/get_configs/${userID}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        formData: false,
      });

      console.log("fetchmodeluserpreference", response?.data);
      setLLMmodeldata(response?.data);
      return response?.data;
    } catch (error) {
      console.error("Error fetching LLM model data:", error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const modelData = await LLMmodel();
    console.log("0011110010", modelData);
    const formData = new FormData();

    if(modelData?.tool_content_gen)
      {
    try {
      setLoading(true);


      formData.append("llm_factory",modelData?.model_content_gen);
      formData.append("agent_type", "content");
      formData.append("tool",modelData?.tool_content_gen);
      formData.append(
        "agent_task_prompt",
        modelData?.content_agent_task_prompt
      );
      formData.append("user_prompt", text);
      formData.append("groq_key", modelData?.groq);
      formData.append("openai_key",modelData?.openai);


     


      await allApi({
        url: "https://us2.sourcesoftsolutions.com:5006/create_agent",
        // url: `${process.env.REACT_APP_API_BACKEND_URL}${apiURL.content}`,

        method: "POST",
        body: formData,

        // headers: {
        //     'Content-Type': 'application/json'
        // },

        formData: true,
      }).then((data) => {
        console.log("mewresponse", data);

        setanswer(data?.response);
        setResponseText(data?.response);
        setLoading(false);

        if (data?.status) {
          setIsLoading(false);
          toast.success(data?.message);
        }
        toast.error(data?.error);
      });
    }
    
    
    catch (error) {
    } finally {
      setIsLoading(false);
    }
  }
  else{
    toast.success("add keys..... ")
    navigate('/UserPrefrence')
  }
  };

  const handleInsert = () => {
    // onChangeText(responseText);
    setNewAddMoreGenerateContent(formatContent(responseText));
    setUserInput(text);
    onClose();
  };

  return (
    <>
      <div
        style={{
          background: "white",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
          minWidth: "1100px",
          position: "fixed",
          top: "30px",
          left: "70%",
          transform: "translateX(-50%)",
        }}
      >
        <h2 className="text-center">Add More Content</h2>

        <TextArea
          rows={4}
          cols={50}
          placeholder="Type your prompt here..."
          // value={Array.isArray(responseText) ? responseText?.map(line => line.replace(/\([^()]*\)/g, "")).join('\n') : text}
          value={responseText ? responseText : text}
          onChange={(e) => setText(e.target.value)}
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
            minHeight: "250px",
          }}
        />

        <Row justify="end" style={{ marginTop: "10px" }}>
          <Col>
            <Button
              className="me-5"
              style={{ marginRight: "8px" }}
              onClick={onClose}
            >
              Cancel
            </Button>
            {responseText?.length == "" ? (
              <>
                <Button
                  className="me-5"
                  type="primary"
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={loading}
                >
                  {loading ? "Generating" : "Generate"}
                </Button>
              </>
            ) : (
              <>
                <Button className="m-4" onClick={handleInsert}>
                  Insert
                </Button>
                <Button onClick={handleSubmit}>ReGenerate</Button>
              </>
            )}
          </Col>
        </Row>
        {loading && (
          <div className="content-genrator-loading">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperClass="color-ring-wrapper"
              colors={["#4277ee", "#4a7beb", "#737dee", "#9e7be9", "#c487ef"]}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default AddMoreGenerateContentModal;
