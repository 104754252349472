import React from "react";
import { useSelector } from "react-redux";
import SenderSelect from "./SenderSelect";
import ReceiverSelect from "./RececiverSelect";
import SelectTemplate from "./SelectTemplate";
import ConfigurationForm from "./Schdule";
import UploadSender from "./UploadSender";
import UploadRecevier from "./UploadRecevier";
import SchduleEdit from "./SchduleEdit";
import OTPVerify from "./OTPVerify";
import CreateConfigSender from "./CreateConfigSender";

function OpenModel() {
  const popupReducer = useSelector((state) => state?.all);
  const { modalType = "", showModal = false } =
    popupReducer?.popupReducer?.modal || {};
  console.log(
    popupReducer?.popupReducer,
    "showModalVerifyYYqwertyui",
    showModal
  );

  return (
    <>
      {showModal && (
        <>
          {modalType === "SENDER" && <SenderSelect />}
          {modalType === "RECEVIER" && <ReceiverSelect />}
          {modalType === "TEMPLATE" && <SelectTemplate />}
          {modalType === "SCHEDULE" && <ConfigurationForm />}
          {modalType === "SCHEDULEEDIT" && <SchduleEdit />}
          {modalType === "UPLOAD_SENDER" && <UploadSender />}
          {modalType === "UPLOAD_RECEVIER" && <UploadRecevier />}
          {modalType === "VERIFY" && <OTPVerify />}
          {modalType === "CREATECONFIGSENDER" && <CreateConfigSender />}
        </>
      )}
    </>
  );
}

export default OpenModel;
