import React, { useState } from "react";
import { Button, Checkbox, Col, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
// import { allApi } from "./apiwrapper/api";
import { toast } from "react-toastify";
// import apiURL from "./Store/Action/api-url";
import { allApi } from "../../ApiWrapper";
import { ColorRing } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const GenrateImageModal = ({ onClose, setNewImageurl }) => {
  const [text, setText] = useState();
  const [responseText, setResponseText] = useState("");
  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingimage, setIsLoadingImage] = useState(false);
  const [answer, setanswer] = useState();
  const [urls, seturls] = useState();

  const [imageList, setImageList] = useState([]);
  const [checkedUrls, setCheckedUrls] = useState([]);
  const [llmModeldata, setLLMmodeldata] = useState({});
  const userID = useSelector((state) => state?.all?.auth?.data?._id);

  const navigate=useNavigate();

  const LLMmodel = async () => {
    try {
      const response = await allApi({
        url: `https://us2.sourcesoftsolutions.com:8072/get_configs/${userID}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        formData: false,
      });

      console.log("fetchmodeluserpreference", response?.data);
      setLLMmodeldata(response?.data);
      return response?.data;
    } catch (error) {
      console.error("Error fetching LLM model data:", error);
    }
  };

  console.log(imageList, "imageList11111");
  const handleImageCheck = (index, image) => {
    const newCheckedUrls = [...checkedUrls];
    if (newCheckedUrls.includes(image)) {
      newCheckedUrls.splice(newCheckedUrls.indexOf(image), 1);
    } else {
      newCheckedUrls.push(image);
    }
    setCheckedUrls(newCheckedUrls);
  };

  const handleInsert = () => {
    setNewImageurl(checkedUrls);
    onClose();
  };

  const handleGenerateImage = async () => {


    setIsLoadingImage(true);
    const modelData = await LLMmodel();
    const formData = new FormData();
console.log(modelData,"modelDatamodelData")

if(modelData?.tool_image_gen)
  {
    formData.append("llm_factory", modelData?.model_image_gen);
    formData.append("agent_type", "image");
    formData.append("tool", modelData?.tool_image_gen);
    formData.append("agent_task_prompt", modelData?.image_agent_task_prompt);
    formData.append("user_prompt", text);
    formData.append("openai_key",modelData?.openai);


    try {
      setLoading(true);
      await allApi({
        url:`https://us2.sourcesoftsolutions.com:5006/create_agent`,
        // url: `https://us2.sourcesoftsolutions.com:8076/create_agent`,

        method: "POST",
        body: formData,
        // headers: {
        //   "Content-Type": "application/json",
        // },

        formData: true,
      }).then((data) => {
        console.log(data, "imagedata");
        setanswer(data?.response);
        setImageList(data?.response);
        setResponseText(data?.response);

        if (data?.status) {
          setIsLoadingImage(false);

          toast.success(data?.message);
        }
        toast.error(data?.error);
      });
    } catch (error) {
    } finally {
      setIsLoadingImage(false);
    }
    
  }
  else{
    toast.success("add keys..... ")
    navigate('/UserPrefrence')
  }
   
  };

  return (
    <>
      <div
        style={{
          background: "white",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
          minWidth: "1100px",
          position: "fixed",
          top: "30px",
          left: "70%",
          transform: "translateX(-50%)",
        }}
      >
        <h1 className="text-center">Generate Image</h1>
        <TextArea
          rows={4}
          cols={50}
          placeholder="Type your text here..."
          //   value={Array.isArray(responseText) ? responseText?.map(line => line.replace(/\([^()]*\)/g, "")).join('\n') : text}
          onChange={(e) => setText(e.target.value)}
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
            minHeight: "50px",
          }}
        />
        {imageList && imageList?.length > 0 ? (
          <>
            <div style={{ marginTop: "20px" }}>
              <h3 className="text-center">Select Images</h3>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  minHeight: "200px",
                }}
              >
                {imageList &&
                  imageList?.length > 0 &&
                  imageList?.map((image, index) => (
                    <div
                      key={index}
                      style={{ marginRight: "10px", marginBottom: "10px" }}
                    >
                      <Checkbox
                        id={`check-${index}`}
                        name={`check-${index}`}
                        checked={checkedUrls.includes(image)}
                        onChange={() => handleImageCheck(index, image)}
                      />
                      <img
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          border: checkedUrls.includes(image)
                            ? "2px solid blue"
                            : "2px solid transparent",
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <Row justify="end" style={{ marginTop: "10px" }}>
          <Col>
            <Button
              className="me-5"
              style={{ marginRight: "8px" }}
              onClick={onClose}
            >
              Cancel
            </Button>
            {responseText?.length == "" ? (
              <>
                <Button
                  type="primary"
                  onClick={handleGenerateImage}
                  loading={isLoadingimage}
                  disabled={isLoadingimage}
                >
                  {loading ? "Generating" : "GenerateImage"}
                </Button>
              </>
            ) : (
              <>
                <Button className="m-4" onClick={handleInsert}>
                  Insert
                </Button>

                <Button onClick={handleGenerateImage}>ReGenerate</Button>
              </>
            )}
          </Col>
        </Row>
        {isLoadingimage && (
          <div className="content-genrator-loading">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperClass="color-ring-wrapper"
              colors={["#4277ee", "#4a7beb", "#737dee", "#9e7be9", "#c487ef"]}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default GenrateImageModal;
