import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

import ConfigurationForm from "./Schdule";
import { FaAngleLeft, FaAngleRight, FaListCheck } from "react-icons/fa6";
import {
  setPopup,
  setSelectedConfig,
  setSelectedReceviers,
  setSelectedSenders,
} from "../../../../Store/Action";
function SelectTemplate() {
  const { TemplateData, unique } = useSelector((state) => state.all);
  const [template, setTemplate] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const configuration = localStorage?.getItem("data");
  const ConfigData = JSON.parse(configuration);

  const { quill, quillRef } = useQuill();

  const popupReducer = useSelector((state) => state?.all);
  const { showModal } = popupReducer?.popupReducer?.modal || {};

  const [loading, setLoading] = useState();
  console.log("TemplateData", TemplateData);
  //   useEffect(() => {
  //     setTemplate(TemplateData);
  //   }, [TemplateData]);

  const [data, setdata] = useState({
    tempname: "",
    subject: "",
    description: "",
    unique_id: unique,
  });

  var moment = require("moment-timezone");

  const [time, setTime] = useState({
    time: moment.tz("").format(),
    emailgap: "",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  useEffect(() => {
    if (quill) {
      if (TemplateData[data.tempname] || false) {
        quill.clipboard.dangerouslyPasteHTML(TemplateData[data.tempname][0]);
      } else {
        quill.clipboard.dangerouslyPasteHTML("");
      }
    }
  }, [TemplateData, data.tempname, quill]);

  const dispatch = useDispatch();

  const FetchApi = async () => {
    // if (Object.keys(TemplateData)) {
    //   dispatch(getPayload(TEMPLATE_DATA));
    // }

    try {
      const response = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/templates`
      );
      const fetchresponse = await response.json();
      console.log("fetchresponse", fetchresponse);
      setTemplate(fetchresponse?.data);
    } catch {}
  };

  useEffect(() => {
    FetchApi();
  }, []);

  console.log("template", template);

  const changeHandler = (template) => {
    console.log("template", template);
    setSelectedTemplate(template);

    // const newData = { ...data, tempname: template?.name };
    const newData = { ...ConfigData, template_id: template?._id };
    console.log("newData", newData);

    localStorage.setItem("data", JSON.stringify(newData));

    // if (name === "tempname") {
    localStorage.setItem("template", JSON.stringify(template));
    
    // }
  };

  const handleClose = () => {
    dispatch(setSelectedReceviers());
    dispatch(setSelectedSenders());
    dispatch(setSelectedConfig());
    dispatch(setPopup({ modalType: "", showModal: false }));
  };

  const handleNext = () => {
    dispatch(setPopup({ modalType: "SCHEDULE", showModal: true }));
  };

  const handleBack = () => {
    dispatch(setPopup({ modalType: "", showModal: true }));
    dispatch(setPopup({ modalType: "RECEVIER", showModal: true }));
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        size="xl"
        className="emailPoup"
      >
        <section className="px-3">
          <Row>
            <Col xl={2} className="border-end">
              <div className="stepsList pt-5">
                <ul className="listOpt">
                  <li className="active">
                    <span>1</span>Chose Senders
                  </li>
                  <li className="active">
                    <span>2</span>Chose Receivers
                  </li>
                  <li className="active">
                    <span>3</span>Chose Template
                  </li>
                  <li>
                    <span>4</span>Chose Time
                  </li>
                </ul>
              </div>
            </Col>

            <Col xl={10} className="ps-4">
              {/* <h3 className="pageTitle mb-4">Set Configuration</h3> */}
              <h3 className="popTitle my-4">
                <FaListCheck className="me-2" /> Create Your Configuration
              </h3>

              <div className="choseSender my-4">
                <h3 className="mb-1">Chose Template</h3>
                <p>Select receivers from left side and move them to right.</p>
              </div>

              <div className="">
                {/* <h3 className="title mb-4">Select Template</h3> */}
                <div className="tempGrid">
                  {template?.map((ele, i) => {
                    return (
                      <>
                        <div key={ele.name}>
                          <figure
                            className={`template-item ${
                              selectedTemplate === ele ? "selected" : ""
                            }`}
                            onClick={() => changeHandler(ele)}
                          >
                            <img
                              className="img-fluid"
                              src={ele?.image}
                              alt={ele?.name}
                            />
                          </figure>
                          <h3> {ele.name}</h3>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>

              <div className="d-flex justify-content-end btnBackNext mt-5">
                <button className="btn buttonBack" onClick={handleBack}>
                  <FaAngleLeft className="me-1 mb-1" /> Back
                </button>
                <button className="btn buttonNext" onClick={handleNext}>
                  Next <FaAngleRight className="ms-1 mb-1" />
                </button>
              </div>
            </Col>
          </Row>
        </section>
      </Modal>
      {/* {showconfig && <ConfigurationForm />} */}
    </>
  );
}

export default SelectTemplate;
