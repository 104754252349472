/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink, useNavigate } from "react-router-dom";
import "./stellarnav.css";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SENDER_DATA } from "../../../../Store/Action/Type";
import { getPayload } from "../../../../Store/Action";

import Dropdown from "react-bootstrap/Dropdown";

export function Navbar() {
  const UserId = useSelector((state) => state.all?.auth?.data?._id);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMailboxClick = (event) => {
    event.preventDefault();
    dispatch(getPayload(SENDER_DATA));

    if (UserId) {
      navigate("/email");
    } else {
      toast.error("Please Login First");
    }
  };

  const handleRPAClick = (event) => {
    event.preventDefault();

    if (UserId) {
      navigate("/rpa");
    } else {
      toast.error("Please Login First");
    }
  };

  const handlcreativeClick = (event) => {
    event.preventDefault();

    if (UserId) {
      navigate("/editor_dashboard");
    } else {
      toast.error("Please Login First");
    }
  };

  return (
    <>
      <div className="stellarnav right desktop">
        <a href="#" className="menu-toggle">
          <span className="bars">
            <span></span>
            <span></span>
            <span></span>
          </span>
          Meun
        </a>
        <ul>
          <a href="#" className="close-menu full">
            <span className="icon-close"></span>Close
          </a>
          <li>
            <NavLink end={true} to="/">
              Home
            </NavLink>
          </li>

          <li className="dropDownBtn" onClick={handleRPAClick}>
            {/* <NavLink end={true} to="/rpa">
              Data Sources
            </NavLink> */}
            <a href="/rpa" onClick={handleRPAClick}>
              Data Sources
            </a>
          </li>

          {/* <li><NavLink to="/about">About</NavLink></li>
          <li><NavLink to="/contact">Contact</NavLink></li> */}

          <li>
            {/* <NavLink to="/email">Mailbox</NavLink> */}
            <a href="/email" onClick={handleMailboxClick}>
              Mailbox
            </a>
          </li>
          <li className="dropDownBtn" onClick={handlcreativeClick}>
            {/* <NavLink end={true} to="/editor_dashboard">Creative</NavLink> */}
            <a href="/editor_dashboard" onClick={handlcreativeClick}>
              Creative
            </a>
          </li>
          <li>
            <NavLink to="/MarketingResearch">Market Research</NavLink>
          </li>

          <li>
            <a
              href="https://us2.sourcesoftsolutions.com:8024/"
              target="_blank"
              rel="noopener noreferrer"
            >
              AITool
            </a>
          </li>

          <li>
            <NavLink to="/AgentFramework">Agent Framework</NavLink>
          </li>

          <li>
            <NavLink to="/documentation">Help</NavLink>
          </li>
        </ul>
      </div>
    </>
  );
}
