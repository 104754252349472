import React, { useState } from "react";
 import "./Agent.css";
import AgentSelect from "./AgentSelect";
import AgentDetails from "./AgentDetails";
import AgentServices from "./AgentServices";
import AgentPrompt from "./AgentPrompt";

const Agent = () => {
  const [agentDetails, setAgentDetails] = useState({});
  const [step, setStep] = useState("agent_select");

  return (
    <div className="Agent-container">
      {step == "agent_select" ? (
        <AgentSelect
          setAgentDetails={(llm_details, agent_type) =>
            setAgentDetails({
              ...agentDetails,
              llm_details: llm_details,
              agent_type: agent_type,
            })
          }
          onNext={() => setStep("agent_details")}
        />
      ) : step == "agent_details" ? (
        <AgentDetails
          llm_details={agentDetails.llm_details}
          onNext={() => setStep("agent_services")}
          onBack={() => setStep("agent_select")}
        />
      ) : step == "agent_services" ? (
        <AgentServices
          agentDetails={agentDetails}
          setAgentDetails={(service, tool) =>
            setAgentDetails({
              ...agentDetails,
              agent_service: service,
              agent_tool: tool,
            })
          }
          onNext={() => setStep("agent_prompt")}
          onBack={() => setStep("agent_details")}
        />
      ) : step == "agent_prompt" ? (
        <AgentPrompt
          agentDetails={agentDetails}
          onBack={() => setStep("agent_services")}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Agent;
