import React, { useEffect, useRef, useState } from "react";
import "../Header/stellarnav.css";
import "../Header/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../Filter/Filter";
import { searchData } from "../../../../Store/Reducers/gmapsDataReducer";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Country } from "country-state-city";
import { City } from "country-state-city";
import { State } from "country-state-city";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { validateModalFields } from "../../../../Components/Validations/Validation";
import { Sidelogo } from "../Header/Sidelogo";
import { BiX } from "react-icons/bi";
import FilterSearch from "./FilterSearch";

function Search() {
  const [query, setQuery] = useState("");
  const [timer, setTimer] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const UserId = useSelector((state) => state.all?.auth?.data?._id);
  const [errors, setErrors] = useState({});
  const [requestdata, setrequestdata] = useState(false);
  const navigate = useNavigate();
  const [filterShow, setFilterShow] = useState(false);
  const [zipcodedata, setZipcodedata] = useState();
  const [citydata, SetCityData] = useState([]);

  console.log(zipcodedata, "zipcodedatazipcodedata");

  const ToggleSidebar = () => {
    setFilterShow(!filterShow);
  };
  const getInitialState = () => ({
    country: "",
    state: "",
    city: "",
    zipCode: "",
    message: "",
  });

  const [inpData, setInpData] = useState(getInitialState);
  const timerRef = useRef(null);
  const {
    country = "",
    state = "",
    city = "",
    zipCode = "",
    message = "",
  } = inpData;

  // console.log(zipCode, "zipCodedatabybelwal");
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      dispatch(searchData(query));
    }, 1500);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [query, dispatch]);

  function showFilterModal(e) {
    e.preventDefault();
    setShowFilter((p) => !p);
  }

  const handleClose = () => {
    setShow(false);
    setInpData(getInitialState());
  };

  const handleShow = () => setShow(true);

  const handleChangeCountry = (e) => {
    setInpData({
      ...inpData,
      [e.target.name]: e.target.value,
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInpData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setErrors({});
  };

  const handleSubmit = async (obj) => {
    // const validationResults = validateModalFields(inpData);
    // setErrors(validationResults);

    // const isValid = Object.values(validationResults).every((x) => !x);
    // if (!isValid) return;
    try {
      if (!UserId) {
        toast.error("Please Login First");
        handleClose();
        return;
      }

      const payload = {
        ...obj,
        userId: UserId,
      };
     console.log(payload,"payloadSSSS")
      const endpoint = `${process.env.REACT_APP_GOOGLE_MAP_DATA_URL}/custom/add`;

      const response = await axios.post(endpoint, payload);
      console.log(response?.data?.status, "responseresponse");
  
      if (response?.data?.status === true) {
        toast.success(response?.data?.message);
        navigate("/");
        handleClose();
        setInpData(getInitialState());
        setFilterShow(false);
         window.location.reload();
      } else {
        toast.error(
          "There was an issue submitting the data. Please try again."
        );
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error(
        "There was an error submitting the data. Please check the console for more details."
      );
    }
  };
  const handlecheckzipcodefilter = (zipCode) => {
    let object = { ...zipcodedata };
    delete object[zipCode];
    setZipcodedata({ ...object });
  };
  const handlecheckcityfilter = (ele, index) => {
    const newCityData = citydata.filter((_, i) => i !== index);
    console.log(newCityData, "newCityData");
    SetCityData(newCityData);
  };
  const MangeAllState = () => {
    SetCityData([]);
    setZipcodedata();
  };
  return (
    <>
      {filterShow && (
        <div className={`sidebar ${filterShow ? "active" : ""}`}>
          <div className="filterTitle">
            {" "}
            Customer Search Request
            {/* <span className="ms-4">
              {zipcodedata && Object.keys(zipcodedata)}{" "}
              {zipcodedata ? <BiX onClick={handlecheckzipcodefilter} /> : ""}
            </span> */}
            {citydata &&
              citydata.map((ele, index) => (
                <>
                  <span className="ms-4">
                    {" "}
                    {ele}{" "}
                    <BiX onClick={() => handlecheckcityfilter(ele, index)} />
                  </span>
                </>
              ))}
            {zipcodedata &&
              Object.keys(zipcodedata).map((zipCode) => (
                <>
                  <span className="ms-4">
                    {" "}
                    {zipCode}{" "}
                    <BiX onClick={() => handlecheckzipcodefilter(zipCode)} />
                  </span>
                </>
              ))}
            <hr className="spacer" />
            <button onClick={ToggleSidebar}>
              <BiX onClick={MangeAllState} />
            </button>
          </div>
          {/* {isFilterVisible && ( */}
          <FilterSearch
            center={{ lat: 39.833851, lng: -74.871826 }}
            createReq={true}
            handleCreateReq={(obj) => handleSubmit(obj)}
            setZipcodedata={setZipcodedata}
            zipcodedata={zipcodedata}
            citydata={citydata}
            SetCityData={SetCityData}
          />
          {/* )} */}
        </div>
      )}

      <div className="search">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-7 col-lg-8">
              <div className="position-relative">
                <input className="m-0" type="text" placeholder="Search..." onChange={(e) => setQuery(e.target.value)} />
                <button className="searchFilter p-0"><FontAwesomeIcon icon={faSearch} /></button>
                {/* <div className="col-md-4 col-lg-2 text-center allFilter align-items-center d-flex justify-content-center">
                  <Link to="" data-toggle="modal" data-target="#myModal" onClick={(e) => {showFilterModal(e);}} >
                    All filters <FontAwesomeIcon icon={faSliders} />
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-md-5 col-lg-3">
              <button className="customerSearch" onClick={ToggleSidebar}>
                Customer Search Request
                {/* <FontAwesomeIcon icon={faSearch} /> */}
              </button>
            </div>
          </div>
        </div>
        <Filter
          show={showFilter}
          handleClose={() => setShowFilter((prev) => !prev)}
        />
      </div>
      <Modal show={show} size="md" onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Custom Search Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <label className="form-label mb-1">Country</label>
              <select
                className="form-select"
                name="country"
                value={inpData?.country}
                onChange={handleChange}
              >
                <option value="">Select Country</option>
                {Country?.getAllCountries().map((ele) => (
                  <option key={ele.isoCode} value={ele.isoCode}>
                    {ele.name}
                  </option>
                ))}
              </select>
              {errors.country && (
                <div className="text-danger">{errors.country}</div>
              )}
            </div>
            <div className="col-lg-6 mb-3">
              <label className="form-label mb-1">State</label>
              <select
                className="form-select"
                name="state"
                value={inpData?.state}
                onChange={handleChange}
              >
                <option value="">Select State</option>
                {State?.getStatesOfCountry(inpData?.country).map((ele) => (
                  <option key={ele.isoCode} value={ele.isoCode}>
                    {ele.name}
                  </option>
                ))}
              </select>
              {errors.state && (
                <div className="text-danger">{errors.state}</div>
              )}
            </div>
            <div className="col-lg-6 mb-3">
              <label className="form-label mb-1">City</label>
              <select
                className="form-select"
                name="city"
                value={inpData?.city}
                onChange={handleChange}
              >
                <option value="">Select City</option>
                {City?.getCitiesOfState(inpData?.country, inpData.state).map(
                  (item) => (
                    <option key={item.isoCode} value={item.isoCode}>
                      {item.name}
                    </option>
                  )
                )}
              </select>
              {errors.city && <div className="text-danger">{errors.city}</div>}
            </div>
            <div className="col-lg-6 mb-3">
              <label className="form-label mb-1">Zip Code</label>
              {/* <select className="form-select">
                <option>Select Zip Code</option>
                <option>India</option>
                <option>America</option>
                <option>United State</option>
              </select> */}

              <input
                type="number"
                id="zipCode"
                name="zipCode"
                value={inpData?.zipCode}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-"].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />

              {errors.zipCode && (
                <div className="text-danger">{errors.zipCode}</div>
              )}
            </div>
            <div className="col-12 mb-3">
              <label className="form-label mb-1">Message</label>
              <textarea
                className="form-control"
                type="text"
                id="message"
                name="message"
                value={message}
                rows={5}
                onChange={handleChange}
              ></textarea>
              {errors.message && (
                <div className="text-danger">{errors.message}</div>
              )}
            </div>
          </div>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Search;
