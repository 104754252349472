import React, { useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";
import { API } from "../../ApiWrapper";
// import "./style,.css";

export const getFileTypeFromExtension = (url) => {
  const extension = url.split(".").pop().toLowerCase();
  console.log("extension", extension);
  if (extension === "pdf") {
    return "application/pdf";
  }
  if (extension === "csv") {
    return "text/csv";
  }
  if (extension === "png") {
    return "image/png";
  }
  if (extension === "jpg") {
    return "image/jpeg";
  }
  if (extension === "jpeg") {
    return "image/jpeg";
  }
  if (extension === "db") {
    return "URL/db";
  }
  if (extension === "youtube") {
    return "URL/youtube";
  }
  if (extension === "news") {
    return "URL/news";
  }
  return null;
};

const ActionLayer = ({ onBackStep, messages, onSetMessages, dataSources }) => {
  console.log("dataSources", dataSources);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const chatBoxRef = useRef(null);

  const getFileContentAndType = async (question) => {
    console.log("calling");
    try {
      const fileUrl = dataSources;

      console.log("fileUrl", fileUrl);
      if (!fileUrl) {
        console.error("File URL not found in session storage");
        return { content: null, type: null };
      }
      const fileContent = [];

      fileUrl.forEach(async (fileUrl, index) => {
        const fileType = getFileTypeFromExtension(fileUrl);
        console.log("fileType", fileType);
        if (
          fileType === "application/pdf" ||
          fileType === "text/csv" ||
          fileType === "image/png" ||
          fileType === "image/jpeg"
        ) {
          const response = await fetch(fileUrl);
          console.log("response", response);
          if (!response.ok) {
            console.error(
              `Failed to fetch file content (${fileUrl}): ${response.statusText}`
            );
            return;
          }
          const fileContents = await response.blob();
          fileContent.push(fileContents);
          console.log("fileContent", fileContent);
        }

        // return { content: fileContent, type: fileType };

        let url = "";
        let formData = new FormData();
        let formType = true;

        if (fileType === "application/pdf") {
          console.log("file: ", fileType);
          formData.append("query", question);
          fileContent.forEach((file, index) => {
            formData.append(`files[]`, file);
          });
          url = "/multi_pdf_processing";

          // formData.append("files[]", fileContent);
        } else if (fileType === "text/csv") {
          url = "/upload_csv";
          formData.append("query", question);
          fileContent.forEach((file, index) => {
            formData.append(`files[]`, file);
          });

          // formData.append("files[]", allFiles);
        } else if (fileType === "image/png" || fileType === "image/jpeg") {
          url = "/process-images";
          formData.append("query", question);
          fileContent.forEach((file, index) => {
            formData.append(`images`, file);
          });
          console.log(fileContent);
          // formData.append("files[]", allFiles);
        } else if (fileType === "URL/news") {
          url = "/article_query";
          formData = { query: question };
          formType = false;
        } else if (fileType === "URL/youtube") {
          url = "/youtube_query";
          formData = { query: question };
          formType = false;
        } else if (fileType === "URL/db") {
          url = "/sql_query";
          formData = { query: question };
          formType = false;
        }
        console.log("url: ", url);

        console.log("url", url);
        console.log(formData);

        // const data = await fetch(
        //   `${process.env.REACT_APP_API_BACKEND_URL}${url}`,
        //   {
        //     method: "POST",
        //     body: formData,
        //   }
        // );
        // const jsonData = await data?.json();

        const jsonData = await API({
          url: url,
          method: "POST",
          body: formData,
          formData: formType,
        });

        console.log(jsonData?.payload?.result, "belwal");
        setIsLoading(false);
        if (jsonData?.payload?.result === null) {
          toast.error("not able to get the response");
        } else {
          onSetMessages({
            text:
              jsonData?.payload?.result?.result_answer ||
              jsonData?.payload?.result ||
              jsonData?.payload?.response[0] ||
              jsonData?.results?.content[1] ||
              "Something went wrong! please try again",

            source: jsonData?.payload?.result?.result_sourcer,
            sender: "bot",
          });
        }
      });
    } catch (error) {
      console.error("Error fetching file content and type:", error);
      return { content: null, type: null };
    }
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = async () => {
    if (!userInput.trim()) return;
    const newMessage = { text: userInput, sender: "user" };
    onSetMessages(newMessage);

    try {
      if (!dataSources.length) {
        onSetMessages({
          text: "It Looks like you have not selected any DataSources yet! Please select a datasource before you can ask query.",
          sender: "bot",
        });
        return;
      }
      setIsLoading(true);
      await getFileContentAndType(userInput);

      // const response = await getFileContentAndType(userInput);

      // const reply = {
      //   text:
      //     response?.results?.result_answer ||
      //     response?.result ||
      //     (response?.response &&
      //       "This is the content of the images: " +
      //         response?.response.toString()) ||
      //     response?.results?.content[1],
      //   source: response?.result?.result_source,
      //   sender: "bot",
      // };

      // onSetMessages(reply);
      if (messages) {
        // setIsLoading(false);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setIsLoading(false);
    }
    setUserInput("");
  };

  console.log("isLoading", isLoading);
  return (
    <>
      {/* <h3>Ask your Query</h3> */}
      <div className="chat-container">
        <div className="chat-box" ref={chatBoxRef}>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`message ${message.sender} message-enter`}
            >
              {message.text}
              {message.source ? (
                <>
                  <br />
                  Source: <a href={message?.source}>{message?.source}</a>
                </>
              ) : (
                ""
              )}
            </div>
          ))}
          {isLoading && <div className="message loading-dots">...</div>}
        </div>
        <div className="user-input-container">
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && sendMessage()}
            placeholder="Ask your query..."
          />
          <button onClick={sendMessage}>Send</button>
        </div>
      </div>
      <div className="step-btn-cover"></div>
      <div className="steps-btn">
        <button type="button" className="back" onClick={() => onBackStep()}>
          Back
        </button>
        <div></div>
      </div>
    </>
  );
};

export default ActionLayer;
