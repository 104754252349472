import React, { useEffect, useState } from "react";
import { MdOutlineModeEdit } from "react-icons/md";
import { FaBan } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { setPopup } from "../../Store/Action";
import { useDispatch, useSelector } from "react-redux";

import { FaGooglePlay } from "react-icons/fa";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { format, parse } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

const MySwal = withReactContent(Swal);

function MySchedules() {
  const [sechduleData, setSechduleData] = useState([]);
  const UserId = useSelector((state) => state.all?.auth?.data?._id);
  const dispatch = useDispatch();

  const cleanAndFormatDateString = (dateString) => {
    try {
      // Remove the extra character at the end if it exists
      let cleanedDateString = dateString;
      if (dateString.endsWith("1")) {
        cleanedDateString = dateString.slice(0, -1);
      }

      // Parse the date string to a Date object
      const parsedDate = parse(
        cleanedDateString,
        "EEE, dd MMM yyyy HH:mm:ss 'GMT'",
        new Date()
      );

      // Convert to IST timezone and format the date
      const timeZone = "Asia/Kolkata";
      const formattedDate = formatInTimeZone(
        parsedDate,
        timeZone,
        "EEE dd MMM yyyy hh:mm a"
      );

      // Convert the day to uppercase and format the output
      return formattedDate.replace(/(\w{3})/, (day) => day.toUpperCase());
    } catch (error) {
      console.error("Error parsing or formatting date:", error);
      return dateString; // Return the original date string in case of an error
    }
  };

  const fetchScheduleData = async () => {
    try {
      const response = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/getScheduleMails?user_id=${UserId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data?.status == "true") {
        console.log(data?.data, "fetchScheduleData");
        setSechduleData(data?.data);
      }
    } catch (error) {
      console.log(error, "errorerror");
    }
  };

  const handleDelete = async (id) => {
    try {
      const dataremove = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/deleteSchedule/${UserId}/${id}`,

        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const response = await dataremove.json();
      //   console.log("responsefetchScheduleData",response)
      if (response) {
        toast.success(response?.message);
        fetchScheduleData();
      }
    } catch {}
  };

  useEffect(() => {
    fetchScheduleData();
  }, []);

  const handleEdit = (id) => {
    dispatch(
      setPopup({
        modalType: "SCHEDULEEDIT",
        scheduleEditData: id,
        showModal: true,
      })
    );
  };

  const handleStop = async (id, configid) => {
    const payload = {
      is_stopped: "true",
    };

    try {
      const response = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/updateSchedule/${UserId}/${id}/${configid}`,
        {
          method: "PUT",
          body: JSON?.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      if (responseData?.status == "true") {
        toast.success(responseData?.message);
        fetchScheduleData();
      } else {
        toast.error(responseData?.message);
      }
    } catch (error) {
      console.log(error, "error on sheduledit model");
    }
  };

  const handlePlay = async (id, configid) => {
    const payload = {
      is_stopped: "false",
    };

    try {
      const response = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/updateSchedule/${UserId}/${id}/${configid}`,
        {
          method: "PUT",
          body: JSON?.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      if (responseData?.status == "true") {
        toast.success(responseData?.message);
        fetchScheduleData();
      } else {
        toast.error(responseData?.message);
      }
    } catch (error) {
      console.log(error, "error on sheduledit model");
    }
  };

  const confirmStop = (id, configid) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Do you really want to stop this schedule?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, stop it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleStop(id, configid);
      }
    });
  };

  const confirmPlay = (id, configid) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Do you really want to Reschedule this schedule?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reschedule it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handlePlay(id, configid);
      }
    });
  };

  return (
    <>
      <section className="pageContent">
        <div className="titleId">
          <h2 className="m-0">My Schedules</h2>
        </div>
        <div className="table-responsive">
          <table class="table">
            <thead className="table-info">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Senders</th>
                <th scope="col">Receivers</th>
                <th scope="col">Time Chosen</th>
                <th scope="col">Timezone</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {sechduleData &&
                sechduleData?.length > 0 &&
                sechduleData.map((ele, index) => (
                  <tr key={index}>
                    <td>{ele?.configuration?.name}</td>
                    <td>{ele?.configuration?.senders_id?.length}</td>
                    <td>{ele?.configuration?.receivers_id?.length}</td>
                    <td>
                      {cleanAndFormatDateString(ele?.configuration?.schedule)}
                    </td>
                    <td>{ele?.schedule_email?.timezone}</td>

                    <td>
                      <div className="d-inline-flex gap-4 h5">
                        <MdOutlineModeEdit
                          onClick={() => {
                            handleEdit(ele?.schedule_email);
                          }}
                        />
                        {/* {ele?.schedule_email?.is_stopped == "true" ? (
                          <FaGooglePlay
                            onClick={() => {
                              confirmPlay(
                                ele?.schedule_email?._id,
                                ele?.schedule_email?.configuration_id
                              );
                            }}
                          />
                        ) : (
                          <FaBan
                            onClick={() => {
                              confirmStop(
                                ele?.schedule_email?._id,
                                ele?.schedule_email?.configuration_id
                              );
                            }}
                          />
                        )} */}

                        <RiDeleteBin6Line
                          onClick={() => {
                            handleDelete(ele?.schedule_email?._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}

export default MySchedules;
