import React from "react";
import { Link } from "react-router-dom";

function SidebarDashbaord() {
  return (
    <div className="sideMenu">
      <ul>
        <li>
          <Link to="">
            <img src="./images/dashboard.svg" alt="" /> Profile
          </Link>
        </li>
        <li>
          <Link to="" className="active">
            <img src="./images/result.svg" alt="" /> Your Results
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default SidebarDashbaord;
