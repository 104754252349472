import React, { useEffect, useState } from "react";
import Sidebar1 from "../components/Sidebar/Sidebar1";
import Sidebar2 from "../components/Sidebar/Sidebar2";
import SideBar3 from "../components/Sidebar/Sidebar3";

function EmailLayout({ memberId }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDay, setSelectedDay] = useState(1);

  console.log("currentPage", currentPage);

  useEffect(() => {
    const { body } = document;
    body.style.overflow = "hidden";

    return () => {
      body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <section className="emailBoxMsg">
        <Sidebar1
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          selectedDay={selectedDay}
          memberId={memberId}
        />
        <div className="rightSidebar" style={{ overflow: "hidden" }}>
          <Sidebar2
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
          />
          <SideBar3 />
        </div>
      </section>
    </>
  );
}

export default EmailLayout;
