/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, json } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

import { BsEnvelope } from "react-icons/bs";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line, RiRefreshLine } from "react-icons/ri";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import "react-toastify/dist/ReactToastify.css";
import {
  EmailPost,
  SubmitPost,
  getEmailPayload,
  setEmailInbox,
  setEmailOutbox,
  setEmailUnread,
  setLoader,
  setPopup,
  setSaveReceiverData,
  setSaveSenderData,
  setSelectedEmail,
} from "../../../../Store/Action/index";

import {
  EMAILS_INBOX,
  EMAILS_OUTBOX,
  EMAILS_UNREAD,
  NEW_INBOX,
  NEW_OUTBOX,
  NEW_UNREAD,
  SAVE_RECEIVER_LIST,
  SAVE_SENDER_LIST,
} from "../../../../Store/Action/Type";
import { Oval } from "react-loader-spinner";
import { faTeeth } from "@fortawesome/free-solid-svg-icons";
import { allApi } from "../../../../ApiWrapper";
import { toast } from "react-toastify";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function Sidebar1({ currentPage, setCurrentPage, selectedDay, memberId }) {
  const { all } = useSelector((state) => state);
  const { type } = all.selectemail;
  const { criteria } = all.defaultcategory;
  const { email } = useSelector((state) => all?.selectemail?.email);
  // const[TotalInboxEmail,setTotalInboxEmail]=useState();
  // const[TotalOutboxEmail,setTotalOutboxEmail]=useState();
  // const[TotalUnreadEmail,setTotalUnreadEmail]=useState();
  const [allSender, setAllSender] = useState([]);
  const [hide, sethide] = useState(false);

  console.log(email, "emailemail");
  const TotalInboxEmail = all?.inbox?.totalCount;
  const TotalOutboxEmail = all?.outbox?.totalCount;
  const TotalUnreadEmail = all?.unread?.totalCount;

  // const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [showcount, setShowCount] = useState({});
  const timeloader = localStorage.getItem("settimer");
  const dispatch = useDispatch();
  const UserId = useSelector((state) => state.all?.auth?.data?._id);
  const popupReducer = useSelector((state) => state?.all);
  const { showModal } = popupReducer?.popupReducer?.modal || {};
  // useEffect(() => {
  const fetchAllSender = async () => {
    // try {
    //   // const allSender = fetch allApi
    //   const allSender = await allApi({
    //     url: `${process.env.REACT_APP_BACKEND_NEW_APP}/getAllSenders?user_id=user123`,
    //     method: "GET",
    //     // body: formData,
    //     // formData: formType,
    //   });
    //   console.log("allSender", allSender);
    //   setAllSender(allSender?.data);
    //   localStorage.setItem("allsender", JSON.stringify(allSender?.data));

    //   const allReceiver = await allApi({
    //     url: `${process.env.REACT_APP_BACKEND_NEW_APP}/getAllReceivers?user_id=user123`,

    //     method: "GET",
    //     // body: formData,
    //     // formData: formType,
    //   });
    //   localStorage.setItem("allrecevier", JSON.stringify(allReceiver?.data));
    // } catch (error) {
    //   console.log("error", error);
    // }

    try {
      dispatch(
        getEmailPayload(
          `${SAVE_SENDER_LIST}?user_id=${memberId ? memberId : UserId}`
        )
      ).then((data) => {
        console.log("SAVE_SENDER_LIST", data?.data);
        dispatch(setSaveSenderData(data?.data));
        setAllSender(data?.data);
      });
    } catch (error) {
      console.log(error, "error on SAVE_SENDER_LIST");
    }
  };

  const fetchReciverData = async () => {
    try {
      dispatch(
        getEmailPayload(
          `${SAVE_RECEIVER_LIST}?user_id=${memberId ? memberId : UserId}`
        )
      ).then((data) => {
        console.log("reciveraddafdafdfagaf", data?.data);
        dispatch(setSaveReceiverData(data?.data));
      });
    } catch {}
  };

  useEffect(() => {
    fetchReciverData();
  }, []);

  const dayToFilter = (dayNumber) => {
    switch (dayNumber) {
      case 1:
        return "";
      case 2:
        return "today";
      case 3:
        return "yesterday";
      case 4:
        return "last_week";
      case 5:
        return "this_month";
      default:
        return "";
    }
  };

  const filterString = dayToFilter(selectedDay);

  const FetchApi = useCallback(
    async (mail, type) => {
      console.log("FetchApi called with type:", mail);

      const requestBody = {
        email_id: mail?.email_id,
        user_id: UserId,
        criteria: "newest",
      };

      if (type === "inbox") {
        console.log(type, "type");

        // setIsLoading(true);
        // localStorage.setItem("loader", true);
        dispatch(setLoader(true));
        await dispatch(EmailPost(NEW_INBOX, requestBody));

        dispatch(
          getEmailPayload(
            `${EMAILS_INBOX}?user_id=${memberId ? memberId : UserId}&email_id=${
              mail?.email_id
            }`
          )
        ).then((data) => {
          console.log("datainbox", data);
          dispatch(setLoader(false));
          dispatch(setEmailInbox(data?.data));
        });
      } else if (type === "outbox") {
        dispatch(setLoader(true));
        await dispatch(EmailPost(NEW_OUTBOX, requestBody));

        dispatch(
          getEmailPayload(
            `${EMAILS_OUTBOX}?user_id=${
              memberId ? memberId : UserId
            }&email_id=${mail?.email_id}`
          )
        ).then((data) => {
          dispatch(setLoader(false));
          dispatch(setEmailOutbox(data?.data));
        });
      } else if (type === "unread") {
        dispatch(setLoader(true));
        await dispatch(EmailPost(NEW_UNREAD, requestBody));

        dispatch(
          getEmailPayload(
            `${EMAILS_UNREAD}?user_id=${
              memberId ? memberId : UserId
            }&email_id=${mail?.email_id}`
          )
        ).then((data) => {
          dispatch(setLoader(false));
          dispatch(setEmailUnread(data?.data));
        });
      }
    },
    [
      type,
      dispatch,
      email,
      criteria,
      filterString,
      all.receiveremail.receiveremail,
      currentPage,
      selectedDay,
    ]
  );

  useEffect(() => {
    console.log("Selected day has changed:", selectedDay);
  }, [selectedDay]);

  useEffect(() => {
    //FetchApi();
  }, [
    dispatch,
    all.senderemail.email,
    all.selectemail.email,
    criteria,
    all.receiveremail.receiveremail,
    // FetchApi,
    all.SenderData,
  ]);

  const handleChange = async (email, type) => {
    setIsLoading(true);
    // localStorage.setItem("loader", true);

    try {
      const maildata = await dispatch(setSelectedEmail({ email, type }));
      console.log("maildata>>>>>>", maildata);
      if (maildata?.payload) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error in handleChange:", error);
    }
    // finally {
    // setIsLoading(false);
    // localStorage.removeItem("loader");
    // }
  };

  const senderDataValues = Object.values(all.SenderData);
  console.log("allSender", allSender);

  const combinedData = allSender?.map((obj) => {
    const key = Object.keys(obj)[0];
    const value = obj[key];
    return { name: key, email: value };
  });
  console.log("combinedData", combinedData);
  async function fetchData() {
    if (Object.keys(showcount).length === 0) {
      //
      try {
        setIsLoading(true);
        localStorage.setItem("settimer", true);
        const response = await fetch(
          `https://us2.sourcesoftsolutions.com:8062/getCount?user_id=${
            memberId ? memberId : UserId
          }`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          const data = await response.json();
          console.log(data?.data?.data, ">=showcount");
          setShowCount(data?.data?.data);
          setIsLoading(false);
          localStorage.removeItem("settimer");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  }

  useEffect(() => {
    fetchData();
    fetchAllSender();
    FetchApi();
  }, [UserId, showModal, memberId]);

  //   const combinedData = senderDataValues.map((obj) => {
  //     const key = Object.keys(obj)[0];
  //     const email = obj[key];
  //     const totalInboxCount = obj?.total_inbox_count;
  //     const totalOutboxCount = obj?.total_outbox_count;
  //     const totalUnreadCount = obj?.total_unread_count;

  //     return {
  //         name: key,
  //         email: email,
  //         totalInboxCount: totalInboxCount,
  //         totalOutboxCount: totalOutboxCount,
  //         totalUnreadCount: totalUnreadCount
  //     };
  // });

  // console.log("combinedData", combinedData);
  // console.log("all", all);
  // console.log("type", type);
  // console.log("criteria", criteria);
  // console.log("email", email);

  // console.log(showcount[email].total_inbox_count,"dfjdhdhdhdhhdhdhdh");
  console.log("allSender", allSender);

  async function fetchData() {
    try {
      const response = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/getCount?user_id=${UserId}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data?.data?.data, ">=showcount");
      setShowCount(data?.data?.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [UserId]);

  const handleHide = async (id) => {
    const payload = {
      is_hidden: true,
    };
    try {
      const dataHide = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/updateSender?sender_id=${id}&user_id=${UserId}`,
        {
          method: "PUT",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const response = await dataHide.json();
      if (response) {
        toast.success(response?.message);
        // getConfig();
        fetchAllSender();
      }
    } catch {}
  };

  const ShowHide = async (id) => {
    const payload = {
      is_hidden: false,
    };
    try {
      const dataHide = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/updateSender?sender_id=${id}&user_id=${UserId}`,
        {
          method: "PUT",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const response = await dataHide.json();
      if (response) {
        toast.success(response?.message);
        // getConfig();
        fetchAllSender();
      }
    } catch {}
  };

  const handledelete = async (id) => {
    const payload = {
      is_deleted: true,
    };
    try {
      const dataHide = await fetch(
        `https://us2.sourcesoftsolutions.com:8062/updateSender?sender_id=${id}&user_id=${UserId}`,
        {
          method: "PUT",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const response = await dataHide.json();
      if (response) {
        toast.success(response?.message);
        // getConfig();
        fetchAllSender();
      }
    } catch {}
  };

  const confirmDelete = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Do you really want to Delete this Sender Mail?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handledelete(id);
      }
    });
  };

  const handleOpen = () => {
    dispatch(setPopup({ modalType: "CREATECONFIGSENDER", showModal: true }));
  };
  return (
    <>
      <div className="createMsg">
        <div className="crMsg">
          <div className="emailIds">
            <Link to="#" onClick={(e) => e.preventDefault()}>
              <BsEnvelope className="icon" /> Mailbox
            </Link>
            <div class="hidenIds">
              <input
                className="form-check-input my-0 ms-0 me-1"
                type="checkbox"
                checked={hide}
                id="flexCheckDefault"
                onChange={() => sethide(!hide)}
              />
              <label
                className="form-check-label m-0"
                htmlFor="flexCheckDefault"
              >
                Show Hidden Ids
              </label>
              {/* {!hide ? (
                <>
                  <input
                    class="form-check-input my-0 ms-0 me-1"
                    type="checkbox"
                    value={hide}
                    id="flexCheckDefault"
                    onChange={() => sethide(true)}
                  />
                  <label class="form-check-label m-0" for="flexCheckDefault">
                    Show Hidden Ids
                  </label>
                </>
              ) : (
                <>
                  <input
                    class="form-check-input my-0 ms-0 me-1"
                    type="checkbox"
                    value={hide}
                    id="flexCheckDefault"
                    onChange={() => sethide(true)}
                  />
                  <label class="form-check-label m-0" for="flexCheckDefault">
                    Show Hidden Ids
                  </label>
                </>
              )} */}
            </div>
          </div>
          {/* <div className="row"> */}
          <div className="msgComos d-flex gap-3 justify-content-around">
            {/* <Link to={"/compose"}> */}
            <button className="btn" onClick={handleOpen}>
              <MdOutlineEdit className="icon me-2" /> Upload
            </button>
            {/* </Link> */}

            <Link to={"/compose"}>
              <button className="btn">
                <MdOutlineEdit className="icon me-2" /> Compose
              </button>
            </Link>
          </div>
          {/* </div> */}
        </div>

        <div className="group">
          {/* {isLoading && timeloader ? (
            <Loader />
          ) : ( */}
          {hide ? (
            <Accordion className="acodCst">
              {allSender.length >= 0 ? (
                allSender
                  ?.filter((ele) => ele?.is_hidden === true)
                  .map((item, index) => {
                    return (
                      <>
                        <Accordion.Item eventKey={index}>
                          <ul className="group-nav p-0 m-0">
                            <Accordion.Header
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // fetchData();
                              }}
                            >
                              {`${item.first_name} ${item.last_name}`}
                              <span className="mt-1">({item.email_id})</span>
                              <div className="delHide">
                                <a onClick={(e) => e.stopPropagation()}>
                                  <FaRegEyeSlash
                                    onClick={() => ShowHide(item?._id)}
                                  />
                                </a>{" "}
                                <a onClick={(e) => e.stopPropagation()}>
                                  <RiDeleteBin6Line />
                                </a>
                              </div>
                            </Accordion.Header>

                            {/* {  showcount.map((ele,index)=>( */}
                            {console.log("object>>>>", showcount[item])}
                            <Accordion.Body className="p-0">
                              <ul className="inbox-nav p-0">
                                <li>
                                  <Link
                                    to="/inbox"
                                    // onClick={(e) => {
                                    //   e.preventDefault();
                                    //   handleChange(item, "inbox");
                                    // }}
                                    className="active"
                                  >
                                    <img
                                      src="Images/inbox.png"
                                      alt="imageinbox"
                                    />{" "}
                                    Inbox{" "}
                                    {/* {showcount
                              ? showcount[item.email].total_inbox_count
                              : ""} */}
                                    {/* {ele?(ele?.total_inbox_count):""} */}
                                    {/* {showcount[item.email]?.total_inbox_count || 0} */}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/outbox"
                                    // onClick={(e) => {
                                    //   e.preventDefault();
                                    //   handleChange(item, "outbox");
                                    // }}
                                    className="active"
                                  >
                                    <img
                                      src="Images/send.png"
                                      alt="imagesend"
                                    />{" "}
                                    Sent{" "}
                                    {/* {showcount
                              ? showcount[item.email].total_outbox_count
                              : ""} */}
                                    {/* {showcount[item.email]?.total_outbox_count || 0} */}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/unread"
                                    // onClick={(e) => {
                                    //   e.preventDefault();
                                    //   handleChange(item, "unread");
                                    // }}
                                    className="active"
                                  >
                                    <img
                                      src="Images/unRead.png"
                                      alt="imageunread"
                                    />{" "}
                                    Unread{" "}
                                    {/* {showcount
                              ? showcount[item.email].total_unread_count
                              : ""} */}
                                    {/* {showcount[item.email]?.total_unread_count || 0} */}
                                  </Link>
                                </li>
                              </ul>
                            </Accordion.Body>
                            {/* ))} */}
                          </ul>
                        </Accordion.Item>
                      </>
                    );
                  })
              ) : (
                <Accordion.Header>No API Response</Accordion.Header>
              )}
            </Accordion>
          ) : (
            <Accordion className="acodCst">
              {allSender?.length >= 0 ? (
                allSender
                  // ?.filter((ele) => !ele?.is_hidden && !ele?.is_deleted)
                  ?.map((item, index) => {
                    console.log("item", item);
                    const email = item.email_id;
                    console.log("showcount[email]", showcount[item.email_id]);

                    return (
                      <Accordion.Item eventKey={index}>
                        <ul className="group-nav p-0 m-0">
                          <Accordion.Header
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              // fetchData();
                            }}
                          >
                            {`${item.first_name} ${item.last_name}`}
                            <span className="mt-1">({item.email_id})</span>
                            <div className="delHide">
                              <a onClick={(e) => e.stopPropagation()}>
                                <FaRegEye
                                  onClick={() => handleHide(item?._id)}
                                />
                              </a>{" "}
                              <a onClick={(e) => e.stopPropagation()}>
                                <RiDeleteBin6Line
                                  onClick={() => confirmDelete(item?._id)}
                                />
                              </a>
                            </div>
                          </Accordion.Header>

                          {/* {showcount &&
                          showcount.map((ele, index) => ( */}
                          {console.log("object>>>>", showcount[item?.email])}
                          <Accordion.Body className="p-0">
                            <ul className="inbox-nav p-0">
                              <li className="d-flex">
                                <Link
                                  to="/inbox"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleChange(item, "inbox");
                                    FetchApi(item, "inbox");
                                  }}
                                  className="active"
                                >
                                  <img
                                    src="Images/inbox.png"
                                    alt="imageinbox"
                                  />{" "}
                                  Inbox{" "}
                                  {showcount[item.email_id]
                                    ?.total_inbox_count || 0}
                                  {/* {ele ? ele?.total_inbox_count : ""} */}
                                  {/* {showcount[item.email]?.total_inbox_count || 0} */}
                                </Link>
                                <Link
                                  className="ms-auto my-auto me-5 h5"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleChange(item, "inbox");
                                    FetchApi(item, "inbox");
                                  }}
                                >
                                  <RiRefreshLine />
                                </Link>
                              </li>
                              <li className="d-flex">
                                <Link
                                  to="/outbox"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleChange(item, "outbox");
                                    FetchApi(item, "outbox");
                                  }}
                                  className="active"
                                >
                                  <img src="Images/send.png" alt="imagesend" />{" "}
                                  Sent{" "}
                                  {/* {showcount
                              ? showcount[item.email].total_outbox_count
                              : ""} */}
                                  {showcount[item.email_id]
                                    ?.total_outbox_count || 0}
                                </Link>
                                <Link
                                  className="ms-auto my-auto me-5 h5"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleChange(item, "outbox");
                                    FetchApi(item, "outbox");
                                  }}
                                >
                                  <RiRefreshLine />
                                </Link>
                              </li>
                              <li className="d-flex">
                                <Link
                                  to="/unread"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleChange(item, "unread");
                                    FetchApi(item, "unread");
                                  }}
                                  className="active"
                                >
                                  <img
                                    src="Images/unRead.png"
                                    alt="imageunread"
                                  />{" "}
                                  Unread{" "}
                                  {/* {showcount
                              ? showcount[item.email].total_unread_count
                              : ""} */}
                                  {showcount[item.email_id]
                                    ?.total_unread_count || 0}
                                </Link>
                                <Link
                                  className="ms-auto my-auto me-5 h5"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleChange(item, "unread");
                                    FetchApi(item, "unread");
                                  }}
                                >
                                  <RiRefreshLine />
                                </Link>
                              </li>
                            </ul>
                          </Accordion.Body>
                          {/* ))} */}
                        </ul>
                      </Accordion.Item>
                    );
                  })
              ) : (
                <Accordion.Header>No API Response</Accordion.Header>
              )}
            </Accordion>
          )}

          {/* )} */}
        </div>
      </div>
    </>
  );
}

export default Sidebar1;
