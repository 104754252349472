import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import BusinessImage from "./BusinessImage";
import BusinesAllDetail from "./BusinesAllDetail";
import { useSelector } from "react-redux";

function MainBusinessDetail() {
  const { data } = useSelector((state) => state.apiDataSlice);
  const { id } = useParams();

  console.log("data", data);
  console.log("id", id);

  const [detailedData, setDetailedData] = useState(null);

  useEffect(() => {
    const specificBusiness = data?.response?.mapData?.find(
      (business) => business._id === id
    );

    console.log("specificBusiness", specificBusiness);
    setDetailedData(specificBusiness);
  }, [data, id]);

  return (
    <div className="d-flex">
      <div className="w-50">
        <BusinessImage detailedData={detailedData} />
      </div>
      <div className="w-50">
        <BusinesAllDetail detailedData={detailedData} />
      </div>
    </div>
  );
}

export default MainBusinessDetail;
