import React from "react";
import { Logo } from "./Logo";
import { Navbar } from "./Navbar";
import { Sidelogo } from "./Sidelogo";

function Topheader() {
  return (
    <>
      <header>
        <div className="top-header">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-auto d-flex align-items-center">
                <Logo />
                <Navbar />
              </div>
              <Sidelogo />
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Topheader;
