import React from "react";
import "../Error/pageMisc.css";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="container">
      <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
        <h1>404</h1>
        <h2>The page you are looking for doesn't exist.</h2>
        <Link to="/" className="button">
          Back to home
        </Link>
        <div className="mt-3">
          <img
            src="/Images/page-misc-error-light.png"
            alt="page-misc-error-light"
            width="500"
            className="img-fluid"
            data-app-dark-img="illustrations/page-misc-error-dark.png"
            data-app-light-img="illustrations/page-misc-error-light.png"
          />
        </div>
      </section>
    </div>
  );
}

export default NotFound;
