import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import MyFilesData from "./MyFilesData";
import MySchedules from "./MySchedules";
import MyConfigurations from "./MyConfigurations";
import ReceiverSelect from "../../Page/Email/Pages/Modal/RececiverSelect";
import RecevierData from "./RecevierData";
import SenderData from "./SenderData";

function MyFiles() {
  const { active } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(parseInt(active) || 0);
  const [activeSubTab, setActiveSubTab] = useState(0);

  useEffect(() => {
    setActiveTab(parseInt(active) || 0);
  }, [active]);

  const toggleTab = (index) => {
    if (activeTab === index) {
      //   navigate("/my_files");
      setActiveTab(0);
    } else {
      //   navigate(`/my_files/${index}`);
      setActiveTab(index);
      setActiveSubTab(0); // Reset the sub-tab when changing main tabs
    }
  };

  const toggleSubTab = (subIndex) => {
    setActiveSubTab(subIndex);
  };

  return (
    <>
      <section className="d-flex mainDashboard">
        <div className="sidebarDashbaord">
          <div className="sideMenu">
            <ul>
              <li>
                <Link
                  className={activeTab === 1 ? "active" : ""}
                  onClick={() => toggleTab(1)}
                >
                  My Files
                </Link>
                {activeTab === 1 && (
                  <ul className="submenu">
                    <li>
                      <Link
                        className={activeSubTab === 1 ? "active" : ""}
                        onClick={() => toggleSubTab(1)}
                      >
                        Sender
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={activeSubTab === 2 ? "active" : ""}
                        onClick={() => toggleSubTab(2)}
                      >
                        Receiver
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <Link
                  className={activeTab === 2 ? "active" : ""}
                  onClick={() => toggleTab(2)}
                >
                  My Schedules
                </Link>
              </li>
              <li>
                <Link
                  className={activeTab === 3 ? "active" : ""}
                  onClick={() => toggleTab(3)}
                >
                  My Configurations
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="sidebarRigh p-5 w-100">
          <Row>
            <Col>
              <div className={`tabFiles ${activeTab === 1 ? "active" : ""}`}>
                {activeSubTab === 1 ? (
                  <div>
                    <SenderData />
                  </div>
                ) : activeSubTab === 2 ? (
                  <div>
                    {" "}
                    <RecevierData />
                  </div>
                ) : (
                  //   <MyFilesData />
                  " "
                )}
              </div>
              <div className={`tabFiles ${activeTab === 2 ? "active" : ""}`}>
                <MySchedules />
              </div>
              <div className={`tabFiles ${activeTab === 3 ? "active" : ""}`}>
                <MyConfigurations />
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default MyFiles;
