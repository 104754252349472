import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPayload, SubmitPost } from "../../../../Store/Action";
import { useDispatch } from "react-redux";
import {
  RECEIVER_DATA,
  SAVE_RECEIVER_LIST,
} from "../../../../Store/Action/Type";
import "./listing.css";
import NavLink from "../New/routes/NavLink";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function ReceiverListing({ isEmailListing }) {
  const { ReceiverData, unique } = useSelector((state) => state.all);

  const [showA, setShowA] = useState({});

  const [showB, setShowB] = useState({});

  const [active, setActive] = useState([]);
  const [rightActive, setRightActive] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const selectedItems = useSelector((state) => state.all?.selectedCategory);
  var result = selectedItems?.map((obj) => ({ [obj.Name]: [obj.Email] }));
  var singleObject = {};
  result?.forEach((obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        singleObject[key] = [obj[key][0]];
      }
    }
  });

  // singleObject = {"Vikram Sharma": "vs1996raj2@gmail.com"};

  console.log("select business", selectedItems);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    FetchApi();
  }, [selectedCategory]);

  useEffect(() => {
    setShowA(ReceiverData);
    // console.log("ReceiverDataReceiverData", ReceiverData);
  }, [ReceiverData]);

  const FetchApi = () => {
    // let categoryParam = selectedItems
    //   ? "?category=Business"
    //   : "?category=Marketing";

    const endpoint = `${RECEIVER_DATA}`;

    console.log("Calling API with endpoint:", endpoint);
    console.log("Calling API with selectedCategory:", selectedCategory);
    if (selectedCategory == null) {
      dispatch(getPayload(endpoint));
    } else {
      dispatch({ type: RECEIVER_DATA, payload: singleObject });
    }
    // dispatch(getPayload(endpoint));
    // dispatch({type: RECEIVER_DATA,payload: singleObject});
  };

  const selectShowA = () => {
    let arr = Object.keys(showA);

    if (Object.keys(showA).length === active.length) {
      setActive([]);
    } else {
      setActive(arr);
    }
  };

  const selectShowB = () => {
    let arr = Object.keys(showB);

    if (Object.keys(showB).length === rightActive.length) {
      setRightActive([]);
    } else {
      setRightActive(arr);
    }
  };

  const handleClickLeft = (val) => {
    console.log(val, "val");
    let arr = [...active];
    let idx = arr.findIndex((ele) => ele === val);
    if (idx >= 0) {
      arr.splice(idx, 1);
    } else {
      arr.push(val);
    }
    setActive(arr);
  };

  const handleClickRight = (val) => {
    let arr = [...rightActive];
    let idx = arr.findIndex((ele) => ele === val);
    if (idx >= 0) {
      arr.splice(idx, 1);
    } else {
      arr.push(val);
    }
    setRightActive(arr);
  };

  const setDataRight = () => {
    if (!active.length) {
      return;
    }

    let leftData = { ...showA };

    let rightData = { ...showB };

    active.map((item) => {
      rightData[item] = leftData[item];
      delete leftData[item];
    });

    setShowA(leftData);

    setShowB(rightData);
  };
  console.log(showB);

  const setDataLeft = () => {
    if (!rightActive.length) {
      return;
    }

    let leftData = { ...showA };

    let rightData = { ...showB };

    rightActive.map((item) => {
      leftData[item] = rightData[item];
      delete rightData[item];
    });

    setShowA(leftData);

    setShowB(rightData);
  };

  const onSubmit = async () => {
    if (active.length === 0) {
      toast.error("Please Select Receivers from the left list");
      return;
    }

    if (Object.keys(showB).length === 0) {
      toast.error("Please Select Receivers from the right list");
      return;
    }

    await new Promise((resolve, reject) => {
      try {
        let arr = [];
        for (const iterator in showB) {
          if (ReceiverData[iterator]) {
            arr = [
              ...arr,
              ...(Array.isArray(showB[iterator]) ? showB[iterator] : []),
            ];
            navigate("/templatelist");
          }
        }
        resolve(arr);
      } catch (error) {
        reject(error);
      }
    }).then((data) => {
      dispatch(
        SubmitPost(SAVE_RECEIVER_LIST, {
          receivers: data,
          unique_id: unique,
        })
      );

      // dispatch(
      //   SubmitPost(SAVE_RECEIVER_LIST, {
      //     receivers: ["vs1996raj2@gmail.com"],
      //     unique_id: unique,
      //   })
      // );
    });
  };

  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput.split("").length > 1) {
      const filterreceiver = Object.keys(showA || {}).filter((item) =>
        item.toLowerCase().includes(searchInput.toLowerCase())
      );

      console.log(filterreceiver);
      setShowA(filterreceiver.reduce((a, v) => ({ ...a, [v]: v }), {}));
    }
  };

  useEffect(() => {
    setSelectedCategory(selectedItems);
  }, [selectedItems]);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-12">
          <h3 className="title_1 withImp position-relative text-center mb-4">
            Choose Receivers
            <Link to="/receiver" className="me-5">
              {/* <img
                className="img-fluid me-2"
                src="Images/import-accounts-icon-small.svg"
                alt="imageaccount"
              /> */}

              <button>Import Receivers</button>
              
            </Link>
          </h3>
        </div>

        <div className="dual-list list-left col-md-5">
          <div className="well text-right">
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="btn-group">
                  <input
                    type="checkbox"
                    className="checkBoxSize"
                    onClick={selectShowA}
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <input
                    type="text"
                    name="SearchDualList"
                    className="form-control"
                    placeholder="search"
                    onChange={(e) => searchItems(e.target.value)}
                  />

                  <span className="input-group-addon">
                    <i className="fas fa-search" />
                  </span>
                </div>
              </div>
            </div>
            <ul className="list-group">
              {Object.keys(showA || {}).length ? (
                Object.keys(showA || {}).map((show, index) => {
                  return (
                    <li
                      className={`list-group-item ${
                        active.includes(show) && "active"
                      }`}
                      key={index}
                      onClick={() => handleClickLeft(show)}
                    >
                      {" "}
                      {show}{" "}
                    </li>
                  );
                })
              ) : (
                <li>No Receivers</li>
              )}
            </ul>
          </div>
        </div>
        <div className="list-arrows col-md-1 text-center d-flex flex-column justify-content-center align-items-center">
          <button
            className="btn btn-default btn-sm move-right"
            onClick={setDataRight}
          >
            <i className="far fa-arrow-square-right" />
          </button>
          <button
            className="btn btn-default btn-sm move-left"
            onClick={setDataLeft}
          >
            <i className="far fa-arrow-square-left" />
          </button>
        </div>
        <div className="dual-list list-right col-md-5">
          <div className="well">
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="btn-group">
                  <input
                    type="checkbox"
                    className="checkBoxSize"
                    onClick={selectShowB}
                  />
                </div>
              </div>
              <div className="col">
                <div className="input-group">
                  <input
                    type="text"
                    name="SearchDualList"
                    className="form-control"
                    placeholder="search"
                  />
                  <span className="input-group-addon">
                    <i className="fas fa-search" />
                  </span>
                </div>
              </div>
            </div>
            <ul className="list-group">
              {Object.keys(showB || {}).map((show, index) => (
                <li
                  className={`list-group-item ${
                    rightActive.includes(show) && "active"
                  }`}
                  key={index}
                  onClick={() => handleClickRight(show)}
                >
                  {show}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <NavLink to={"/senderlisting"}>
            <button type="button" className="btn-alternate me-4 text-black">
              Back
            </button>
          </NavLink>

          <button
            type="button"
            className="btn-primary me-5 text-black "
            onClick={onSubmit}
          >
            Next
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ReceiverListing;
