import React from "react";
import "../Modal/modal.css";

const ForgotPasswordForm = ({
  emailData,
  onChange,
  onSubmit,
  forgotPasswordErrors,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <div class="inputData mt-4">
        <input
          type="text"
          name="email"
          id="email"
          // placeholder="Enter Email for Password Reset"
          value={emailData.email}
          onChange={onChange}
          required
        />
        <label htmlFor="email">Email</label>
        <div class="underline"></div>
      </div>
      {forgotPasswordErrors?.email && (
        <div className="error">{forgotPasswordErrors?.email}</div>
      )}
    </form>
  );
};

export default ForgotPasswordForm;
