import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { allApi } from "../../ApiWrapper";

const AgentSelect = ({ setAgentDetails, onNext }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [agentTypes, setAgentTypes] = useState([]);
  const [formValue, setFormValue] = useState({
    agent_type: "",
    llm_type: "",
  });

  useEffect(() => {
    async function fetchdata() {
      try {
        // const response = await apiClient.get("/agenttypes");
        const response = await allApi({
          url: `${process.env.REACT_APP_API_BACKEND_AGENT_URL}/agenttypes`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          formData: false,
        });
        setAgentTypes(response?.data);
      } catch (err) {
        toast.error(err);
        console.log(err);
      }
    }
    fetchdata();
  }, []);

  const handleAgentSubmit = async () => {
    try {
      if (!formValue.agent_type || !formValue.llm_type) {
        toast.warn("Please fill required fields");
        return;
      }
      setIsLoading(true);
      // const response = await apiClient.get(`/agentdetails/${formValue}`);
      const response = await allApi({
        url: `${process.env.REACT_APP_API_BACKEND_AGENT_URL}agentdetails/${formValue.llm_type}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        formData: false,
      });
      console.log(response?.data);
      setAgentDetails(response?.data[0], formValue.agent_type);
      toast.success("Success");
      setIsLoading(false);
      onNext();
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
      console.log(err);
    }
  };
  return (
    <>
      <div className="detail-container py-5">
        <h2>Select the Agent</h2>
        <div className="row justify-content-center">
          <div className="col-lg-6">

            <div className="inputDataSelect mb-5">
              <label className="pb-2" htmlFor="llm_type">LLM Factory</label>
              <select
                id="llm_type"
                value={formValue.llm_type}
                onChange={(e) =>
                  setFormValue({ ...formValue, llm_type: e.target.value })
                }
              >
                <option value="">Select LLM Factory</option>
                {agentTypes?.map((agent) => (
                  <option value={agent._id} key={agent?._id}>
                    {agent?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="inputDataSelect">
              <label className="pb-2" htmlFor="agent_type">Agent Type</label>
              <select
                id="agent_type"
                value={formValue.agent_type}
                onChange={(e) =>
                  setFormValue({ ...formValue, agent_type: e.target.value })
                }
              >
                <option value="">Select Agent Type</option>
                <option value="content">Content</option>
                <option value="Image">Image</option>
              </select>
            </div>

            <div className="d-flex justify-content-center pt-4">
              <button className="btn1 bt" onClick={handleAgentSubmit}>
                {isLoading ? "Loading..." : "Save & Next"}
              </button>
            </div>
            
          </div>
        </div>
        
      </div>
    </>
  );
};

export default AgentSelect;
