import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function RequestCard() {
  const [data, setData] = useState(null);

  const apiUrl = `${process.env.REACT_APP_GOOGLE_MAP_DATA_URL}/notification`;

  const token = useSelector((state) => state.all?.auth?.data?.token);

  useEffect(() => {
    if (!token) return;

    fetch(apiUrl, {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((responseData) => {
        setData(responseData.data);
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }, [token]);

  return (
    <>
      <div className="row">
        {data?.pendingData?.map((request) => (
          <>
            <div className="col-md-6 col-lg-4">
              <div className="requestBox position-relative">
                <button className="progressBtn">In Progress</button>
                <figure>
                  <img src="./images/requestIcon.svg" alt="" />
                </figure>
                <p>{request?.resp}</p>
              </div>
            </div>{" "}
          </>
        ))}
        {data?.completedData?.map((request) => (
          <>
            <div className="col-md-6 col-lg-4">
              <div className="requestBox position-relative">
                <button className="progressBtn grnBg">Completed</button>
                <figure>
                  <img src="./images/requestIcon.svg" alt="" />
                </figure>
                <p>{request?.resp}</p>
              </div>
            </div>{" "}
          </>
        ))}
      </div>
    </>
  );
}

export default RequestCard;
