import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import DOMPurify from "dompurify";
import { resetSelectedEmailData } from "../../../../Store/Action";

export const formatContent = (content) => {
  const segments = content?.split("\n")
    .filter((segment) => segment.trim() !== "");

  return segments.map((text, index) => {
    if (text.match(/\b(http[s]?:\/\/\S+(?:png|jpe?g|gif)\S*)\b/gi)) {
      // If the segment contains an image link, replace it with an <img> tag
      const imageUrl = text.match(
        /\b(http[s]?:\/\/\S+(?:png|jpe?g|gif)\S*)\b/gi
      )[0];
      return <img key={index} src={imageUrl} alt="Email Attachment" />;
    } else {
      // Otherwise, treat it as a regular paragraph
      return <p key={index}>{text}</p>;
    }
  });
};

function SideBar3() {
  // Get the selected email data from the Redux store
  const inbox = useSelector((state) => state.all.selectedEmailData);
  const dispatch = useDispatch();

  // Reset selected email data when the component unmounts
  useEffect(() => {
    return () => {
      dispatch(resetSelectedEmailData());
    };
  }, [dispatch]);

  // Display a message if no email is selected
  if (!inbox || !inbox.email_subject) {
    return (
      <div className="msg-detail-Box">
        <div className="demo1" data-simplebar="">
          <div className="msgOne">
            <div className="rightLink">
              <h6>Select an item to read. Nothing is selected.</h6>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render the selected email details
  return (
    <div className="msg-detail-Box">
      <h2 className="updateTitle d-none">{inbox?.email_subject}</h2>
      <div className="demo1" data-simplebar="">
        <div className="msgOne">
          <div className="rightLink">
            <ul className="backBtn">
              <li>
                <a href="#">
                  <img src="Images/reply.png" alt="reply" /> Reply
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="Images/replyAll.png" alt="reply all" /> Reply all
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="Images/forward.png" alt="forward" /> Forward
                </a>
              </li>
              <li className="d-none">
                <a href="#">
                  <img src="Images/delete2.png" alt="delete" />
                </a>
              </li>
            </ul>
            <div className="userTitle">
              <div className="user">
                <img src="Images/user.png" alt="user" />
              </div>
              <h3>{inbox?.email_from || ""}</h3>
              <p className="dateMsg">
                {new Date(inbox?.email_date).toLocaleString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                })}
              </p>
              <h2 className="subjectMsg">
                {inbox?.email_subject || "No Subject"}
              </h2>
            </div>
            {formatContent(inbox?.mail_body)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar3;
