import React from "react";

const CompletedRequestCard = ({ data }) => {
  return (
    <>
      <div className="noifyList d-flex">
        <div className="position-relative">
          <figure>
            <img src={"/images/notifyimage.jpeg"} alt="" />
          </figure>
        </div>
        <div className="ms-lg-4 ms-0 mt-4 mt-lg-0">
          <p>{data?.resp}</p>
          <div className="date">
            {new Date(data?.reqCompleted).toLocaleString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletedRequestCard;
