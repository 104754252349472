import React from "react";
import Search from "../Search/Search";
import Topheader from "./Topheader";
import { useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();
  return (
    <header>
      <Topheader />
      {location.pathname !== "/email" && <Search />}
      {/* {location.pathname !== "/dashboard" && <Search />} */}
    </header>
  );
}

export default Header;
